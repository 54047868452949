import type { EditableFormInstance } from '@ant-design/pro-table/es';
import { t } from '@lingui/macro';
import { Input, InputNumber, Tag } from 'antd';
import { round } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import ProductInput from '../../../../../../components/Inputs/ProductInput';
import WarehouseSelect from '../../../../../../components/Selects/WarehouseSelect';
import { TableInputProTable } from '../../../../../../components/TableInputProTable';
import numberFormat from '../../../../../../lib/numberFormat';
import { deductTax } from '../../../../../../lib/tax';
import stores from '../../../../../../stores/index.mobx';
type Props = {
	value?: InitialStockItem[];
};

type InitialStockItem = {
	warehouseId: string;
	productId: string;
	quantity: number;
	purchasePrice: number;
	purchaseAmount: number;
	salePrice: number;
	salePriceWithVat: number;
	saleAmount: number;
	isNew?: boolean;
};

export default function InitialStocksItemsInput({ value, ...props }: Props) {
	const {
		warehouses: { byId: storesById, available: availableWarehouses },
		products: { byId: productsById },
		taxRates,
	} = stores;

	const editableFormRef = useRef<EditableFormInstance>();
	const [initialValue, setInitialValue] = useState<Partial<InitialStockItem>>({
		isNew: true,
	});

	useEffect(() => {
		if (availableWarehouses?.length === 1) {
			setInitialValue({
				isNew: true,
				warehouseId: availableWarehouses[0]?.id,
			});
		}
	}, [availableWarehouses]);

	// useEffect(() => {
	// 	if (value?.length) {
	// 		setInitialValue({
	// 			isNew: true,
	// 			warehouseId: value[value.length - 1]?.warehouseId,
	// 		});
	// 	}
	// }, [value]);

	const onSelectWarehouse = useCallback((warehouseId) => {
		setInitialValue({
			warehouseId,
		});
	}, []);

	const columns = useMemo(() => {
		return [
			{
				title: t`Складиште`,
				width: 200,
				dataIndex: 'warehouseId',
				formItemProps: {
					rules: [
						{
							required: true,
							message: t`Складиште је обавезно`,
						},
					],
				},
				renderFormItem: ({ handleKeyDown }, { isEditable }) => {
					return isEditable ? (
						<WarehouseSelect
							popupMatchSelectWidth={false}
							onKeyDown={(e) => handleKeyDown(e, recordKey)}
							onSelect={onSelectWarehouse}
						/>
					) : (
						<Input />
					);
				},
				render: (value, record, index) => {
					return storesById[value]?.name;
				},
			},
			{
				title: t`Артикал`,
				dataIndex: 'productId',
				formItemProps: {
					rules: [
						{
							required: true,
							message: t`Артикал је обавезан`,
						},
					],
				},
				renderFormItem: (_, { isEditable, record, recordKey }) => {
					return isEditable ? (
						<ProductInput
							onChange={(value) => {
								setTimeout(() => {
									const salePriceWithVat = Number(
										productsById[value]?.getPriceForWarehouse?.(
											record.warehouseId
										) ||
											productsById[value]?.price ||
											0
									);
									const salePrice = deductTax(
										salePriceWithVat,
										taxRates.byLabel(productsById[value]?.taxRateLabel || '')
											?.rate || 0
									);
									const saleAmountWithVat = round(
										salePriceWithVat * Number(record.quantity || 0),
										4
									);
									editableFormRef.current?.setRowData?.(recordKey, {
										salePriceWithVat,
										salePrice,
										saleAmountWithVat,
									});
								}, 100);
							}}
						/>
					) : (
						<Input />
					);
				},
				render: (value, record, index) => {
					const product = productsById[value];
					return product?.parent ? (
						<>
							{product.parent.name}&nbsp;&nbsp;
							<Tag color="magenta" bordered={false}>
								{product.variantName}
							</Tag>
						</>
					) : (
						product?.name
					);
				},
			},
			{
				title: t`Количина`,
				dataIndex: 'quantity',
				width: 90,
				formItemProps: {
					initialValue: 0,
					rules: [
						{
							required: true,
							message: t`Количина је обавезна`,
						},
						{
							type: 'number',
							min: 0.001,
							message: t`Количина мора бити већа од 0`,
						},
					],
				},
				renderFormItem: (
					{ handlePressEnter },
					{ isEditable, record, recordKey }
				) => {
					return isEditable ? (
						<InputNumber
							onPressEnter={(e) => handlePressEnter(e, recordKey)}
							step={1}
							style={{ width: '100%' }}
							onFocus={(event) => {
								event.currentTarget.select();
							}}
							onChange={(value) => {
								setTimeout(() => {
									editableFormRef.current?.setRowData?.(recordKey, {
										purchaseAmount: round(
											Number(value) * (record.purchasePrice || 0),
											4
										),
										saleAmountWithVat: round(
											Number(value) * (record.salePriceWithVat || 0),
											4
										),
									});
								}, 100);
							}}
						/>
					) : (
						<Input />
					);
				},
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
			{
				title: t`Набавна цена`,
				dataIndex: 'purchasePrice',
				width: 120,
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: (
					{ handlePressEnter },
					{ isEditable, record, recordKey }
				) => {
					return isEditable ? (
						<InputNumber
							onPressEnter={(e) => handlePressEnter(e, recordKey)}
							step={1}
							style={{ width: '100%' }}
							onFocus={(event) => {
								event.currentTarget.select();
							}}
							onChange={(value) => {
								setTimeout(() => {
									editableFormRef.current?.setRowData?.(recordKey, {
										purchaseAmount: round(
											Number(value) * (record.quantity || 0),
											4
										),
									});
								}, 100);
							}}
						/>
					) : (
						<Input />
					);
				},
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
			{
				title: t`Набавна вредност`,
				dataIndex: 'purchaseAmount',
				width: 150,
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: (
					{ handlePressEnter },
					{ isEditable, record, recordKey }
				) => {
					return isEditable ? (
						<InputNumber
							onPressEnter={(e) => handlePressEnter(e, recordKey)}
							step={1}
							style={{ width: '100%' }}
							onFocus={(event) => {
								event.currentTarget.select();
							}}
							onChange={(value) => {
								setTimeout(() => {
									editableFormRef.current?.setRowData?.(recordKey, {
										purchasePrice: round(
											Number(value) / (record.quantity || 0),
											4
										),
									});
								}, 100);
							}}
						/>
					) : (
						<Input />
					);
				},
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
			{
				title: t`Продајна цена`,
				dataIndex: 'salePrice',
				width: 120,
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: (
					{ handlePressEnter },
					{ isEditable, record, recordKey }
				) => {
					return isEditable ? (
						<InputNumber
							onPressEnter={(e) => handlePressEnter(e, recordKey)}
							step={1}
							style={{ width: '100%' }}
							onFocus={(event) => {
								event.currentTarget.select();
							}}
							onChange={(value) => {
								setTimeout(() => {
									editableFormRef.current?.setRowData?.(recordKey, {
										salePriceWithVat: round(
											Number(value) *
												(record.quantity || 0) *
												(1 +
													(taxRates.byLabel(
														productsById[record.productId]?.taxRateLabel || ''
													)?.rate || 0) /
														100),
											4
										),
										saleAmountWithVat: round(
											Number(value) *
												(record.quantity || 0) *
												(1 +
													(taxRates.byLabel(
														productsById[record.productId]?.taxRateLabel || ''
													)?.rate || 0) /
														100),
											4
										),
									});
								}, 100);
							}}
						/>
					) : (
						<Input />
					);
				},
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
			{
				title: t`Продајна цена са ПДВ`,
				dataIndex: 'salePriceWithVat',
				width: 180,
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: (
					{ handlePressEnter },
					{ isEditable, record, recordKey }
				) => {
					return isEditable ? (
						<InputNumber
							onPressEnter={(e) => handlePressEnter(e, recordKey)}
							step={1}
							style={{ width: '100%' }}
							onFocus={(event) => {
								event.currentTarget.select();
							}}
							onChange={(value) => {
								setTimeout(() => {
									editableFormRef.current?.setRowData?.(recordKey, {
										saleAmountWithVat: round(
											Number(value) * (record.quantity || 0),
											4
										),
										salePrice: deductTax(
											Number(value),
											taxRates.byLabel(
												productsById[record.productId]?.taxRateLabel || ''
											)?.rate || 0
										),
									});
								}, 100);
							}}
						/>
					) : (
						<Input />
					);
				},
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
			{
				title: t`Прод. вредност са ПДВ`,
				dataIndex: 'saleAmountWithVat',
				width: 180,
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: (
					{ handlePressEnter },
					{ isEditable, record, recordKey }
				) => {
					return isEditable ? (
						<InputNumber
							onPressEnter={(e) => handlePressEnter(e, recordKey)}
							step={1}
							style={{ width: '100%' }}
							onFocus={(event) => {
								event.currentTarget.select();
							}}
							onChange={(value) => {
								setTimeout(() => {
									editableFormRef.current?.setRowData?.(recordKey, {
										salePriceWithVat: round(
											Number(value) / (record.quantity || 0),
											4
										),
										salePrice: deductTax(
											Number(value) / (record.quantity || 0),
											taxRates.byLabel(
												productsById[record.productId]?.taxRateLabel || ''
											)?.rate || 0
										),
									});
								}, 100);
							}}
						/>
					) : (
						<Input />
					);
				},
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
		];
	}, [onSelectWarehouse, productsById, storesById, taxRates]);

	return (
		<TableInputProTable<InitialStockItem>
			editableFormRef={editableFormRef}
			// shouldShowDuplicateButton
			shouldShowIndexColumn
			addButtonText={t`Додај артикал`}
			emptyText={t`Нема ставки`}
			iconPath="/images/icons/new/trolley.svg"
			columns={columns}
			value={value}
			initialValue={initialValue}
			{...props}
		/>
	);
}
