import type { EditableFormInstance } from '@ant-design/pro-table/es';
import { Trans, t } from '@lingui/macro';
import {
	Col,
	Divider,
	Input,
	InputNumber,
	Row,
	Table,
	Tag,
	Typography,
} from 'antd';
import { round } from 'lodash';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import styles from './PricechangeItems.module.less';
import ProductInput from '../../../../../../components/Inputs/ProductInput';
import WarehouseSelect from '../../../../../../components/Selects/WarehouseSelect';
import { TableInputProTable } from '../../../../../../components/TableInputProTable';
import { Title } from '../../../../../../components/Title';
import numberFormat from '../../../../../../lib/numberFormat';
import { deductTax } from '../../../../../../lib/tax';
import stores from '../../../../../../stores/index.mobx';
import { PricechangeItem } from '../../../../../../stores/Pricechange.mobx';
import TaxRateSelect from '../../../Calculation/Components/CalculationItems/TaxRateSelect';
type Props = {
	value?: PricechangeItem[];
};

function PricechangesItemsInput({ value, ...props }: Props) {
	const {
		pricechanges: {
			fetchQuantityAndPrice,
			single: pricechange,
			isFetchingQuantityAndPrice,
		},
		warehouses: { byId: storesById, available: availableWarehouses },
		products: { byId: productsById },
		taxRates,
	} = stores;

	const editableFormRef = useRef<EditableFormInstance>();
	const [initialValue, setInitialValue] = useState<Partial<PricechangeItem>>(
		{}
	);

	useEffect(() => {
		if (availableWarehouses?.length === 1) {
			setInitialValue({
				warehouseId: availableWarehouses[0]?.id,
			});
		}
	}, [availableWarehouses]);

	const onSelectWarehouse = useCallback((warehouseId) => {
		setInitialValue({
			warehouseId,
		});
	}, []);

	const columns = useMemo(() => {
		return [
			{
				title: t`Складиште`,
				width: 150,
				dataIndex: 'warehouseId',
				formItemProps: {
					rules: [
						{
							required: true,
							message: t`Складиште је обавезно`,
						},
					],
				},
				renderFormItem: (
					{ handleKeyDown },
					{ recordKey, isEditable, record }
				) => {
					return isEditable ? (
						<WarehouseSelect
							popupMatchSelectWidth={false}
							onKeyDown={(e) => handleKeyDown(e, recordKey)}
							onSelect={onSelectWarehouse}
							onChange={(value) => {
								setTimeout(async () => {
									const product = productsById[record.productId];
									if (!product) {
										return;
									}

									let quantity = 0;
									let purchasePrice = 0;
									let oldSalePriceWithVat = 0;

									if (value) {
										const quantityAndPrice = await flowResult(
											fetchQuantityAndPrice(product.id, value)
										);

										if (quantityAndPrice) {
											// quantity: totalQuantity,
											// averagePurchasePrice,
											// salePriceWithVat: lastSalePrice,
											quantity = quantityAndPrice.quantity;
											purchasePrice = quantityAndPrice.averagePurchasePrice;
											oldSalePriceWithVat = quantityAndPrice.salePriceWithVat;
										}
									}

									const productUnit = product?.unit?.name || `kom`;

									// const oldSalePriceWithVat = product.getPriceForWarehouse(
									// 	record.warehouseId
									// );

									editableFormRef.current?.setRowData?.(recordKey, {
										oldSalePriceWithVat,
										productUnit,
										quantity,
										purchasePrice,
									});
								}, 100);
							}}
						/>
					) : (
						<Input />
					);
				},
				render: (value, record, index) => {
					return storesById[value]?.name;
				},
			},
			{
				title: t`Артикал`,
				dataIndex: 'productId',
				formItemProps: {
					rules: [
						{
							required: true,
							message: t`Артикал је обавезан`,
						},
					],
				},
				renderFormItem: (
					{ handleKeyDown },
					{ isEditable, record, recordKey }
				) => {
					return isEditable ? (
						<ProductInput
							onKeyDown={(e) => handleKeyDown(e, recordKey)}
							onChange={(value) => {
								setTimeout(async () => {
									const product = productsById[value];
									if (!product) {
										return;
									}

									const taxRate =
										taxRates.byLabel(product?.taxRateLabel || '')?.rate || 0;

									let quantity = 0;
									let purchasePrice = 0;
									let oldSalePriceWithVat = 0;

									if (record.warehouseId) {
										const quantityAndPrice = await flowResult(
											fetchQuantityAndPrice(product.id, record.warehouseId)
										);

										if (quantityAndPrice) {
											// quantity: totalQuantity,
											// averagePurchasePrice,
											// salePriceWithVat: lastSalePrice,
											quantity = quantityAndPrice.quantity || 0;
											purchasePrice =
												quantityAndPrice.averagePurchasePrice || 0;
											oldSalePriceWithVat =
												quantityAndPrice.salePriceWithVat || 0;
										}
									}

									const productUnit = product?.unit?.name || `kom`;

									// const oldSalePriceWithVat = product.getPriceForWarehouse(
									// 	record.warehouseId
									// );

									editableFormRef.current?.setRowData?.(recordKey, {
										oldSalePriceWithVat,
										salePriceWithVat: null,
										priceDifference: null,
										amountDifference: null,
										productUnit,
										quantity,
										taxRate,
										purchasePrice,
									});
								}, 100);
							}}
						/>
					) : (
						<Input />
					);
				},
				render: (value, record, index) => {
					const product = productsById[value];
					return product?.parent ? (
						<>
							<Typography.Text type="secondary">{product.sku}</Typography.Text>
							<Divider type="vertical" />
							{product.parent.name}&nbsp;&nbsp;
							<Tag color="magenta" bordered={false}>
								{product.variantName}
							</Tag>
						</>
					) : (
						<>
							<Typography.Text type="secondary">{product?.sku}</Typography.Text>
							<Divider type="vertical" />
							{product?.name}
						</>
					);
				},
			},
			{
				title: t`Количина`,
				dataIndex: 'quantity',
				width: 90,
				align: 'right',
				formItemProps: {
					initialValue: 0,
					rules: [
						{
							required: true,
							message: t`Количина је обавезна`,
						},
						{
							type: 'number',
							min: 0,
							message: t`Количина мора бити већа или једнака 0`,
						},
					],
				},
				renderFormItem: (
					{ handlePressEnter, handleKeyDown },
					{ isEditable, record, recordKey }
				) => {
					return <Input disabled />;
				},
				render: (value: string) => {
					return numberFormat(value, false, 3, false);
				},
			},
			{
				title: t`ЈМ`,
				dataIndex: 'productUnit',
				width: 70,
				renderFormItem: () => <Input disabled />,
			},
			{
				title: t`Набавна цена`,
				dataIndex: 'purchasePrice',
				width: 120,
				align: 'right',
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: () => {
					return <Input disabled />;
				},
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
			{
				title: t`ПДВ %`,
				dataIndex: 'taxRate',
				align: 'right',
				width: 100,
				renderFormItem: () => {
					return <TaxRateSelect />;
				},
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
			{
				title: t`Стара цена`,
				dataIndex: 'oldSalePriceWithVat',
				width: 100,
				align: 'right',
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: () => {
					return <Input disabled />;
				},
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},

			{
				title: t`Нова цена`,
				dataIndex: 'salePriceWithVat',
				width: 100,
				align: 'right',
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: (
					{ handlePressEnter, handleKeyDown },
					{ isEditable, record, recordKey }
				) => {
					return isEditable ? (
						<InputNumber
							onKeyDown={(e) => handleKeyDown(e, recordKey)}
							onPressEnter={(e) => handlePressEnter(e, recordKey)}
							controls={false}
							className={styles.rightAlignNumberInput}
							style={{ width: '100%' }}
							onFocus={(event) => {
								event.currentTarget.select();
							}}
							onChange={(value) => {
								setTimeout(() => {
									const quantity = Number(record.quantity || 0);
									const oldSalePriceWithVat = Number(
										record.oldSalePriceWithVat || 0
									);
									const salePriceWithVat = Number(value || 0);

									const priceDifference = round(
										salePriceWithVat - oldSalePriceWithVat,
										4
									);
									const amountDifference = round(priceDifference * quantity, 4);

									editableFormRef.current?.setRowData?.(recordKey, {
										oldSalePriceWithVat,
										priceDifference,
										amountDifference,
									});
								}, 100);
							}}
						/>
					) : (
						<Input />
					);
				},
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
			{
				title: t`Разлика у цени`,
				dataIndex: 'priceDifference',
				width: 130,
				align: 'right',
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: () => (
					<InputNumber
						disabled
						controls={false}
						className={styles.rightAlignNumberInput}
						style={{ width: '100%' }}
						onFocus={(event) => {
							event.currentTarget.select();
						}}
					/>
				),
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
			{
				title: t`Раз. прод. вред.`,
				dataIndex: 'amountDifference',
				width: 130,
				align: 'right',
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: () => (
					<InputNumber
						disabled
						controls={false}
						className={styles.rightAlignNumberInput}
						style={{ width: '100%' }}
					/>
				),
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
		];
	}, [
		fetchQuantityAndPrice,
		onSelectWarehouse,
		productsById,
		storesById,
		taxRates,
	]);

	return (
		<>
			<TableInputProTable<PricechangeItem>
				editableFormRef={editableFormRef}
				// shouldShowDuplicateButton
				shouldShowIndexColumn
				addButtonText={t`Додај артикал`}
				emptyText={t`Нема ставки`}
				iconPath="/images/icons/new/price-change.svg"
				columns={columns}
				value={value}
				initialValue={initialValue}
				tableProps={{
					loading: isFetchingQuantityAndPrice,
					scroll: {
						x: 1740,
					},
				}}
				{...props}
			/>
		</>
	);
}

export default observer(PricechangesItemsInput);
