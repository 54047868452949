import { Trans, t } from '@lingui/macro';
import {
	Alert,
	Button,
	Checkbox,
	Col,
	DatePicker,
	Divider,
	Drawer,
	Form,
	Grid,
	Input,
	InputNumber,
	Modal,
	Row,
	Select,
	Space,
	Spin,
	Tooltip,
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import clamp from 'lodash/clamp';
import round from 'lodash/round';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import Deferred from 'promise-deferred';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLatest } from 'react-use';

import BuyerCostCenterInput from './Components/BuyerCostCenterInput';
import BuyerInput from './Components/BuyerInput';
import EmailModal from './Components/EmailModal';
import { PaymentInput } from './Components/PaymentInput';
import AdvanceFinalizeSpecificationDrawer from './Drawers/AdvanceFinalizeSpecification';
import AdvanceSpecificationDrawer from './Drawers/AdvanceSpecification';
import styles from './PaymentModal.module.less';
import enUS from '../../assets/locales/journal/en-US.json';
import srCyrlRS from '../../assets/locales/journal/sr-Cyrl-RS.json';
import srLatnRS from '../../assets/locales/journal/sr-Latn-RS.json';
import globalStyles from '../../assets/styles/global.module.less';
import { getApplicationName } from '../../constants/application';
import {
	InvoiceType,
	PaymentType,
	TAX_FREE_LABEL,
	TransactionType,
} from '../../constants/invoice';
import {
	ADVANCE_TYPE,
	INVOICE_TYPE_FROM_STRING,
	InvoiceTypeAPI,
	PAYMENT_TYPE_MAP,
	TRANSACTION_TYPE_FROM_STRING,
	TransactionTypeAPI,
	getInvoiceTypeText,
	getTransactionTypeText,
} from '../../constants/journal';
import numberFormat from '../../lib/numberFormat';
import { PaymentModalMode } from '../../lib/receipt';
import stores from '../../stores/index.mobx';
import { LocalSale } from '../../stores/LocalSale.mobx';
import { CreateInvoiceRequest, Receipt } from '../../stores/Receipt.mobx';
import PosTerminalModal from '../PosTerminalModal';
import { StaticComponents } from '../StaticComponents';
import { bignumber, evaluate } from 'mathjs';

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		lg: { span: 8 },
	},
	wrapperCol: {
		xs: { span: 24 },
		lg: { span: 16 },
	},
};

const normalInvoiceTypes = [
	InvoiceType.NORMAL,
	InvoiceType.PROFORMA,
	InvoiceType.TRAINING,
	InvoiceType.ADVANCE,
];

const copyInvociceTypes = [InvoiceType.COPY];

const transactionTypes = [TransactionType.SALE, TransactionType.REFUND];

const initialPayments = Object.values(PAYMENT_TYPE_MAP).reduce((prev, pt) => {
	prev[pt] = 0;
	return prev;
}, {});

export const PaymentModalInitialPayments = initialPayments;

const locales = {
	'en-US': enUS,
	'sr-Cyrl-RS': srCyrlRS,
	'sr-Latn-RS': srLatnRS,
};

type Props = {
	initialMode: PaymentModalMode | null;
	open: boolean;
	onClose: (completed?: boolean) => void;
	initialReceipt?: Receipt;
	sale?: LocalSale;
	payments?: {
		cash?: number;
		check?: number;
		card?: number;
		wiretransfer?: number;
		voucher?: number;
		mobilemoney?: number;
		other?: number;
	};
};

type Values = {
	invoiceType: InvoiceType;
	unknownAmountAdvance?: boolean;
	transactionType: TransactionType;
	buyerId?: string | null;
	buyerCostCenterId?: string | null;
	posTime?: Dayjs;
	referentDocumentNumber?: string;
	referentDocumentDT?: Dayjs;
	discount?: number;
	change?: number;
	payments: {
		cash?: number;
		check?: number;
		card?: number;
		wiretransfer?: number;
		voucher?: number;
		mobilemoney?: number;
		other?: number;
	};
	additionalText?: string;
};

export type PaymentModalValues = Values;

let advanceSpecificationDeferred;
let emailDeferred;
let posTerminalDeferred;

function PaymentModal({
	open,
	onClose,
	initialReceipt,
	sale,
	payments,
	initialMode,
}: Props) {
	const INVOICE_TYPE_TEXT = getInvoiceTypeText();
	const TRANSACTION_TYPE_TEXT = getTransactionTypeText();
	const APPLICATION_NAME = getApplicationName();
	const {
		receipts,
		exchangeRates,
		stores: { currentStore: store },
		devices: { thermalPrinters, posTerminals },
		company: { taxFreeMode, availablePaymentMethods, tin },
	} = stores;

	const [mode, setMode] = useState<PaymentModalMode | null>(initialMode);
	const [receipt, setReceipt] = useState<Receipt | null>(initialReceipt);

	useEffect(() => {
		setMode(initialMode);
	}, [initialMode]);

	useEffect(() => {
		setReceipt(initialReceipt);
	}, [initialReceipt]);

	const [form] = Form.useForm();

	const isInputFocused = useRef(false);

	const screens = Grid.useBreakpoint();

	const Component = useMemo(() => (screens.md ? Modal : Drawer), [screens.md]);

	const [printDestinationA4, setPrintDestinationA4] = useState(
		(!window.electron || thermalPrinters.length === 0) && store?.printMethod?.a4
	);
	const [printDestinationThermal, setPrintDestinationThermal] = useState(
		window.electron && thermalPrinters.length > 0 && store?.printMethod?.thermal
	);
	const [printDestinationEmail, setPrintDestinationEmail] = useState(false);

	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingReferentDocument, setIsLoadingReferentDocument] =
		useState(false);
	const [, setIsReferentDocumentNotFound] = useState(false);
	const [isLastReceipt, setIsLastReceipt] = useState(false);

	const [
		advanceSpecificationDrawerVisible,
		setAdvanceSpecificationDrawerVisible,
	] = useState(false);
	const [
		advanceFinalizeSpecificationDrawerVisible,
		setAdvanceFinalizeSpecificationDrawerVisible,
	] = useState(false);
	const [advanceSpecificationLabels, setAdvanceSpecificationLabels] = useState<
		string[]
	>([]);
	const [
		advanceFinalizeSpecificationLabels,
		setAdvanceFinalizeSpecificationLabels,
	] = useState<Record<string, number>>({});
	const [
		advanceFinalizeSpecificationItems,
		setAdvanceFinalizeSpecificationItems,
	] = useState<CreateInvoiceRequest['items']>([]);

	const [posTerminalModalVisible, setPosTerminalModalVisible] = useState(false);
	const [emailModalVisible, setEmailModalVisible] = useState(false);

	const [referentDocument, setReferentDocument] = useState<Receipt | null>(
		null
	);

	const [isReferentDocumentAdvanceRefund, setIsReferentDocumentAdvanceRefund] =
		useState(false);

	const [values, setValues] = useState<Values>({
		invoiceType: InvoiceType.NORMAL,
		transactionType: TransactionType.SALE,
		payments: { ...initialPayments },
	});
	const latestValues = useLatest(values);

	const [showHidden, setShowHidden] = useState(false);

	const lastAdvanceSale = useMemo(() => {
		if (
			mode === PaymentModalMode.closeAdvance ||
			mode === PaymentModalMode.copy
		) {
			const last = receipt.connectedReceipts.findLast((cr) => {
				return (
					cr.invoiceType === InvoiceTypeAPI.ADVANCE &&
					cr.transactionType === TransactionTypeAPI.SALE &&
					!cr.void &&
					!cr.voids
				);
			});

			return new Receipt(last, null);
		}
	}, [mode, receipt?.connectedReceipts]);

	const lastAdvanceReceipt = useMemo(() => {
		if (
			(mode === PaymentModalMode.closeAdvance ||
				mode === PaymentModalMode.addAdvancePayment ||
				mode === PaymentModalMode.refund) &&
			receipt
		) {
			const last = receipt?.connectedReceipts?.findLast((cr) => {
				return (
					cr.invoiceType === InvoiceTypeAPI.ADVANCE && !cr.void && !cr.voids
				);
			});

			return new Receipt(last, null);
		}
	}, [mode, receipt]);

	const firstAdvance = useMemo(() => {
		return receipt?.connectedReceipts?.find(
			(cr) =>
				cr.invoiceType === InvoiceTypeAPI.ADVANCE &&
				cr.transactionType === TransactionTypeAPI.SALE &&
				!cr.void &&
				!cr.voids
		);
	}, [receipt?.connectedReceipts]);

	const isOldAdvance = useMemo(() => {
		return (
			lastAdvanceReceipt?.transactionType === TransactionTypeAPI.REFUND &&
			!firstAdvance
		);
	}, [firstAdvance, lastAdvanceReceipt]);

	useEffect(() => {
		if (!open) {
			return;
		}

		setReferentDocument(null);
		setIsReferentDocumentAdvanceRefund(false);
		setIsLastReceipt(false);

		const newValues: Values = {
			invoiceType: InvoiceType.NORMAL,
			transactionType: TransactionType.SALE,
			payments: { ...initialPayments },
			unknownAmountAdvance: store.defaultUnknownAmountAdvance || false,
			...(mode === PaymentModalMode.copy && {
				invoiceType: InvoiceType.COPY,
				transactionType: TRANSACTION_TYPE_FROM_STRING[receipt.transactionType],
				referentDocumentNumber: receipt.invoiceNumber,
				referentDocumentDT: receipt.sdcTime,
				payments: {
					...initialPayments,
					...receipt.payment.reduce((prev, curr) => {
						prev[curr.paymentType] = curr.amount;
						return prev;
					}, {}),
				},
				additionalText: receipt.additionalText,
				buyerId: receipt.buyerId,
				buyerCostCenterId: receipt.buyerCostCenterId,
				posTime: receipt.posTime,
			}),
			...(mode === PaymentModalMode.refund && {
				invoiceType: INVOICE_TYPE_FROM_STRING[receipt.invoiceType],
				transactionType: TransactionType.REFUND,
				referentDocumentNumber: receipt.invoiceNumber,
				referentDocumentDT: receipt.sdcTime,
				buyerId: receipt.buyerId,
				buyerCostCenterId: receipt.buyerCostCenterId,
				unknownAmountAdvance: receipt.unknownAmountAdvance,
			}),
			...(mode === PaymentModalMode.addAdvancePayment && {
				invoiceType: InvoiceType.ADVANCE,
				transactionType: TransactionType.SALE,
				referentDocumentNumber: receipt.invoiceNumber,
				referentDocumentDT: receipt.sdcTime,
				buyerId: receipt.buyerId,
				buyerCostCenterId: receipt.buyerCostCenterId,
				unknownAmountAdvance: receipt.unknownAmountAdvance,
			}),
			...(mode === PaymentModalMode.closeAdvance && {
				invoiceType: InvoiceType.ADVANCE,
				transactionType: TransactionType.REFUND,
				referentDocumentNumber: lastAdvanceSale.invoiceNumber,
				referentDocumentDT: lastAdvanceSale.sdcTime,
				buyerId: receipt.buyerId,
				buyerCostCenterId: receipt.buyerCostCenterId,
				unknownAmountAdvance: receipt.unknownAmountAdvance,
			}),
			...(mode === PaymentModalMode.finalizeProforma && {
				invoiceType: InvoiceType.NORMAL,
				transactionType: TransactionType.SALE,
				referentDocumentNumber: receipt.invoiceNumber,
				referentDocumentDT: receipt.sdcTime,
				buyerId: receipt.buyerId,
				buyerCostCenterId: receipt.buyerCostCenterId,
			}),
			...(mode === PaymentModalMode.void && {
				invoiceType: INVOICE_TYPE_FROM_STRING[receipt.invoiceType],
				transactionType: TransactionType.REFUND,
				referentDocumentNumber: receipt.invoiceNumber,
				referentDocumentDT: receipt.sdcTime,
				buyerId: `${tin}`.length === 13 ? `11:${tin}` : `10:${tin}`,
				buyerCostCenterId: receipt.buyerCostCenterId,
				posTime: receipt.posTime,
				payments: {
					...initialPayments,
					...receipt.payment.reduce((prev, curr) => {
						prev[curr.paymentType] = curr.amount;
						return prev;
					}, {}),
				},
			}),
		};

		if (mode === PaymentModalMode.normal) {
			newValues.payments = { ...initialPayments, ...payments };
		}

		if (
			(mode === PaymentModalMode.refund ||
				mode === PaymentModalMode.addAdvancePayment) &&
			newValues.invoiceType === InvoiceType.ADVANCE &&
			lastAdvanceReceipt
		) {
			newValues.referentDocumentDT = lastAdvanceReceipt.sdcTime;
			newValues.referentDocumentNumber = lastAdvanceReceipt.invoiceNumber;
		}

		if (mode === PaymentModalMode.closeAdvance && isOldAdvance) {
			newValues.referentDocumentDT = receipt.sdcTime;
			newValues.referentDocumentNumber = receipt.invoiceNumber;
		}

		setValues(newValues);
		form.setFieldsValue(newValues);
	}, [
		form,
		isOldAdvance,
		lastAdvanceReceipt,
		lastAdvanceSale?.invoiceNumber,
		lastAdvanceSale?.sdcTime,
		mode,
		open,
		payments,
		receipt,
		store?.defaultUnknownAmountAdvance,
		tin,
	]);

	useEffect(() => {
		if (mode === PaymentModalMode.void && open) {
			setIsLoading(true);
			flowResult(receipts.getLastReceipt(receipt?.storeId))
				.then((lastReceipt) => {
					if (lastReceipt.id === receipt?.id) {
						setIsLastReceipt(true);
					} else {
						setIsLastReceipt(false);
					}
				})
				.catch((e) => {
					setIsLastReceipt(false);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	}, [mode, open, receipt, receipts, sale]);

	useEffect(() => {
		if (mode !== PaymentModalMode.normal || !open) {
			return;
		}

		const [buyerCostCenterIdType] = (values.buyerCostCenterId || '').split(':');
		if (
			values.buyerCostCenterId &&
			taxFreeMode === 'exempt' &&
			['20', '21', '30', '31', '32', '33'].includes(buyerCostCenterIdType)
		) {
			sale.setTaxFree(true);
		}
	}, [mode, open, sale, taxFreeMode, values.buyerCostCenterId]);

	const unknownAmountAdvance = useMemo(() => {
		return (
			(values.unknownAmountAdvance &&
				values.invoiceType === InvoiceType.ADVANCE) ||
			lastAdvanceSale?.unknownAmountAdvance
		);
	}, [
		lastAdvanceSale?.unknownAmountAdvance,
		values.invoiceType,
		values.unknownAmountAdvance,
	]);

	const paymentsSum = useMemo(() => {
		return Object.values(values.payments || {}).reduce(
			(sum, value) => round(sum + Number(value || 0), 2),
			0
		);
	}, [values.payments]);

	const advanceAmount = useMemo(() => {
		if (
			mode === PaymentModalMode.addAdvancePayment ||
			mode === PaymentModalMode.closeAdvance ||
			mode === PaymentModalMode.copy ||
			(mode === PaymentModalMode.refund &&
				values.invoiceType === InvoiceType.ADVANCE)
		) {
			if (isOldAdvance) {
				return lastAdvanceReceipt.paymentTotal;
			}

			let amount = round(
				receipt.connectedReceipts
					.filter(
						(cr) =>
							cr.invoiceType === InvoiceTypeAPI.ADVANCE && !cr.void && !cr.voids
					)
					.reduce((acc, curr) => {
						const paymentTotal =
							curr.transactionType === TransactionTypeAPI.SALE
								? curr.paymentTotal
								: -curr.paymentTotal;

						return acc + paymentTotal - curr.paymentChange;
					}, 0),
				2
			);

			if (
				amount === 0 &&
				lastAdvanceReceipt?.transactionType === TransactionTypeAPI.REFUND
			) {
				amount = round(amount + lastAdvanceReceipt.paymentTotal, 2);
			}
			return amount;
		}

		return 0;
	}, [
		isOldAdvance,
		lastAdvanceReceipt?.paymentTotal,
		lastAdvanceReceipt?.transactionType,
		mode,
		receipt?.connectedReceipts,
		values.invoiceType,
	]);

	const totalAmount = useMemo(() => {
		if (mode === PaymentModalMode.normal) {
			return sale?.total || 0;
		}

		if (
			mode === PaymentModalMode.addAdvancePayment ||
			mode === PaymentModalMode.closeAdvance
		) {
			if (isOldAdvance) {
				return lastAdvanceReceipt?.totalAmount || 0;
			}

			if (firstAdvance.unknownAmountAdvance) {
				return advanceAmount;
			}

			return firstAdvance?.advanceItems?.reduce((prev, curr) => {
				return round(
					evaluate('prev + unitPrice * quantity', {
						prev: bignumber(prev),
						unitPrice: bignumber(curr.unitPrice),
						quantity: bignumber(curr.quantity),
					}).toNumber(),
					2
				);
			}, 0);
		}

		if (
			mode === PaymentModalMode.refund &&
			values.invoiceType === InvoiceType.ADVANCE
		) {
			return advanceAmount;
		}

		return receipt?.totalAmount || 0;
	}, [
		advanceAmount,
		firstAdvance?.advanceItems,
		firstAdvance?.unknownAmountAdvance,
		isOldAdvance,
		lastAdvanceReceipt?.totalAmount,
		mode,
		receipt?.totalAmount,
		sale?.total,
		values.invoiceType,
	]);

	const discountedAmount = useMemo(() => {
		if (mode === PaymentModalMode.normal) {
			return sale?.itemsAsArray.reduce((prev, curr) => {
				return round(
					evaluate(
						'prev + (finalPrice - (finalPrice * discount / 100)) * quantity',
						{
							prev: bignumber(prev),
							finalPrice: bignumber(curr.finalPrice),
							discount: bignumber(values.discount || 0),
							quantity: bignumber(round(curr.quantity, 3)),
						}
					).toNumber(),
					2
				);
			}, 0);
		}

		return totalAmount;
	}, [mode, sale?.itemsAsArray, totalAmount, values.discount]);

	const remainingAmountWithoutPayments = useMemo(() => {
		if (
			mode === PaymentModalMode.addAdvancePayment ||
			mode === PaymentModalMode.closeAdvance
		) {
			return clamp(
				round(
					evaluate('totalAmount - advanceAmount', {
						totalAmount: bignumber(totalAmount),
						advanceAmount: bignumber(advanceAmount),
					}).toNumber(),
					2
				),
				0,
				Infinity
			);
		}
		return clamp(round(discountedAmount, 2), 0, Infinity);
	}, [advanceAmount, discountedAmount, mode, totalAmount]);
	const remainingAmount = useMemo(() => {
		return clamp(
			round(
				evaluate('remainingAmountWithoutPayments - paymentsSum', {
					remainingAmountWithoutPayments: bignumber(
						remainingAmountWithoutPayments
					),
					paymentsSum: bignumber(paymentsSum),
				}).toNumber(),
				2
			),
			0,
			Infinity
		);
	}, [paymentsSum, remainingAmountWithoutPayments]);

	const changeAmount = useMemo(() => {
		if (
			unknownAmountAdvance ||
			(values.transactionType === TransactionType.REFUND &&
				mode !== PaymentModalMode.closeAdvance)
		) {
			return 0;
		}
		return clamp(
			round(
				evaluate('paymentsSum + advanceAmount - discountedAmount', {
					paymentsSum: bignumber(paymentsSum),
					advanceAmount: bignumber(advanceAmount),
					discountedAmount: bignumber(discountedAmount),
				}).toNumber(),
				2
			),
			0,
			Infinity
		);
	}, [
		advanceAmount,
		discountedAmount,
		mode,
		paymentsSum,
		unknownAmountAdvance,
		values?.transactionType,
	]);

	const paymentMethods = useMemo(() => {
		if (!store) {
			return [];
		}

		return availablePaymentMethods === 'all' || mode === PaymentModalMode.copy
			? store.paymentMethodsOrder
			: store.paymentMethodsOrder.filter((pm) => {
					return [
						PaymentType.CASH,
						PaymentType.VOUCHER,
						PaymentType.WIRE_TRANSFER,
						PaymentType.OTHER,
						-1,
					].includes(pm);
			  });
	}, [store, availablePaymentMethods, mode]);

	const visiblePaymentMethods = useMemo(() => {
		const hideIndex = paymentMethods.indexOf(-1);
		return paymentMethods.filter((pm, index) => {
			return index < hideIndex;
		});
	}, [paymentMethods]);

	const hiddenPaymentMethods = useMemo(() => {
		const hideIndex = paymentMethods.indexOf(-1);
		return paymentMethods.filter((pm, index) => {
			return index > hideIndex;
		});
	}, [paymentMethods]);

	useEffect(() => {
		if (values.posTime && sale) {
			sale.setDate(values.posTime);
		}
	}, [values.posTime, sale]);

	const fetchReferentDocument = useCallback(async () => {
		if (
			values.referentDocumentNumber &&
			!values.referentDocumentNumber.toLowerCase().startsWith('xxxxxxxx-')
		) {
			setIsLoadingReferentDocument(true);
			setIsReferentDocumentNotFound(false);

			try {
				const refDoc: Receipt = await flowResult(
					receipts.getByInvoiceNumber(values.referentDocumentNumber)
				);

				if (refDoc) {
					const newValues = {
						...values,
						referentDocumentDT: refDoc.sdcTime,
						buyerCostCenterId: refDoc.buyerCostCenterId,
						buyerId: refDoc.buyerId,
					};
					form.setFieldsValue(newValues);
					setValues(newValues);

					if (
						refDoc.invoiceType === InvoiceTypeAPI.ADVANCE &&
						refDoc.transactionType === TransactionTypeAPI.REFUND &&
						values.invoiceType === InvoiceType.NORMAL &&
						values.transactionType === TransactionType.SALE
					) {
						setIsReferentDocumentAdvanceRefund(true);
					}

					setReferentDocument(refDoc);
				}
			} catch (e) {
				StaticComponents.notification.error({
					message: t`Грешка приликом учитавања референтног документа`,
				});
				setIsReferentDocumentNotFound(true);
			} finally {
				setIsLoadingReferentDocument(false);
			}
		}
	}, [form, receipts, values]);

	const totalsByLabel = useMemo(() => {
		if (mode === PaymentModalMode.normal) {
			sale.date; // force update
			return sale?.itemsAsArray.reduce((prev, curr) => {
				const label = sale.vatExempt
					? TAX_FREE_LABEL
					: curr.product.taxRateLabel;
				prev[label] = round(
					evaluate(
						'prev + (finalPrice - (finalPrice * discount / 100)) * quantity',
						{
							prev: bignumber(prev[label] || 0),
							finalPrice: bignumber(curr.finalPrice),
							discount: bignumber(values.discount || 0),
							quantity: bignumber(curr.quantity),
						}
					).toNumber(),
					2
				);
				return prev;
			}, {});
		}

		if (unknownAmountAdvance) {
			return receipt?.connectedReceipts
				.filter(
					(cr) =>
						cr.invoiceType === InvoiceTypeAPI.ADVANCE && !cr.void && !cr.voids
				)
				.reduce((prev, curr) => {
					curr.receiptItems.forEach((item) => {
						const label = item.taxLabels[0];
						prev[label] = round(
							evaluate('prev + unitPrice * quantity', {
								prev: bignumber(prev[label] || 0),
								unitPrice: bignumber(
									curr.transactionType === TransactionTypeAPI.SALE
										? item.unitPrice
										: -item.unitPrice
								),
								quantity: bignumber(item.quantity),
							}).toNumber(),
							2
						);
					});
					return prev;
				}, {});
		}

		return receipt?.advanceItems?.reduce((prev, curr) => {
			const label = curr.taxLabels[0];
			prev[label] = round(
				evaluate('prev + unitPrice * quantity', {
					prev: bignumber(prev[label] || 0),
					unitPrice: bignumber(curr.unitPrice),
					quantity: bignumber(curr.quantity),
				}).toNumber(),
				2
			);
			return prev;
		}, {});
	}, [
		mode,
		receipt?.advanceItems,
		receipt?.connectedReceipts,
		sale?.itemsAsArray,
		sale?.vatExempt,
		unknownAmountAdvance,
		values.discount,
		sale?.date,
	]);

	const waitForPosTerminal = useCallback(async () => {
		if (
			posTerminals.length &&
			values.payments.card > 0 &&
			values.transactionType === TransactionType.SALE
		) {
			setPosTerminalModalVisible(true);
			posTerminalDeferred = new Deferred();
			const posTerminalResponse = await posTerminalDeferred.promise;
			return Promise.resolve(posTerminalResponse);
		}

		return Promise.resolve();
	}, [posTerminals.length, values.payments.card, values.transactionType]);

	const getAdvancePaymentsByLabel = useCallback(async () => {
		if (unknownAmountAdvance) {
			const labels = Object.keys(totalsByLabel);
			if (labels.length > 1) {
				setAdvanceSpecificationDrawerVisible(true);
				setAdvanceSpecificationLabels(labels);
				advanceSpecificationDeferred = new Deferred();
				const labelsResponse = await advanceSpecificationDeferred.promise;
				return labelsResponse.map((label) => ({
					finalPrice: label.amount,
					taxRateLabel: label.label,
					quantity: 1,
					product: {
						id: null,
						ean: null,
						name: `${ADVANCE_TYPE[label.label]}: ${
							locales[store?.language || 'sr-Cyrl-RS'].advance
						}`,
						sku: null,
					},
					isAdvance: true,
				}));
			} else {
				return [
					{
						finalPrice: round(
							evaluate('paymentsSum - changeAmount', {
								paymentsSum: bignumber(paymentsSum),
								changeAmount: bignumber(changeAmount),
							}).toNumber(),
							2
						),
						quantity: 1,
						taxRateLabel: labels[0],
						product: {
							id: null,
							ean: null,
							name: `${ADVANCE_TYPE[labels[0]]}: ${
								locales[store?.language || 'sr-Cyrl-RS'].advance
							}`,
							sku: null,
						},
						isAdvance: true,
					},
				];
			}
		} else {
			let advanceItemsSum;

			if (mode === PaymentModalMode.normal) {
				advanceItemsSum = discountedAmount;
			} else {
				advanceItemsSum =
					values.invoiceType === InvoiceType.ADVANCE
						? receipt?.advanceItems?.reduce((prev, curr) => {
								return round(
									evaluate('prev + unitPrice * quantity', {
										prev: bignumber(prev),
										unitPrice: bignumber(curr.unitPrice),
										quantity: bignumber(curr.quantity),
									}).toNumber(),
									2
								);
						  }, 0)
						: 0;
			}
			return Object.entries(totalsByLabel).map(([label, total]) => {
				return {
					finalPrice: round(
						evaluate('total * (paymentsSum - changeAmount) / advanceItemsSum', {
							total: bignumber(Number(total)),
							changeAmount: bignumber(changeAmount),
							paymentsSum: bignumber(paymentsSum),
							advanceItemsSum: bignumber(
								values.invoiceType === InvoiceType.ADVANCE
									? advanceItemsSum
									: discountedAmount
							),
						}).toNumber(),
						2
					),
					taxRateLabel: label,
					quantity: 1,
					product: {
						id: null,
						ean: null,
						name: `${ADVANCE_TYPE[label]}: ${
							locales[store?.language || 'sr-Cyrl-RS'].advance
						}`,
						sku: null,
					},
					isAdvance: true,
				};
			});
		}
	}, [
		changeAmount,
		discountedAmount,
		mode,
		paymentsSum,
		receipt?.advanceItems,
		store?.language,
		totalsByLabel,
		unknownAmountAdvance,
		values.invoiceType,
	]);

	const refundReceipt = useCallback(
		async (email = undefined, items?, advanceItems?) => {
			return flowResult(
				receipts.createInvoiceV2(
					{
						...values,
						items:
							items ||
							receipt.receiptItems.map((item) => ({
								product: {
									id: item.productId,
									price: item.unitPrice,
									ean: item.gtin,
									name: item.name,
									unit: {
										saleUnit: {
											label: item.unit,
											isPieceUnitOfMeasure: item.isPieceUnitOfMeasure,
										},
									},
									sku: item.sku,
								},
								quantity: item.quantity,
								taxRateLabel: item.taxLabels[0],
								finalPrice: item.unitPrice,
								// discount: item.discount,
							})),
						payments: {
							...values.payments,
							cash: round(
								evaluate('cash - change', {
									cash: bignumber(values.payments.cash),
									change: bignumber(values.change || 0),
								}).toNumber(),
								2
							),
						},
						change: 0,
						advanceItems,
					},
					{
						thermal: printDestinationThermal,
						a4: printDestinationA4,
						email, //printDestinationEmail,
					}
				)
			);
		},
		[
			printDestinationA4,
			printDestinationThermal,
			receipt?.receiptItems,
			receipts,
			values,
		]
	);

	const handleModeVoid = useCallback(
		async (email) => {
			await refundReceipt(email);

			onClose();
		},
		[onClose, refundReceipt]
	);

	const handleModeRefund = useCallback(
		async (email) => {
			if (paymentsSum === 0 && discountedAmount > 0) {
				return StaticComponents.notification.error({
					message: t`Грешка`,
					description: t`Није унет ни један начин плаћања`,
				});
			}

			if (values.invoiceType !== InvoiceType.ADVANCE) {
				if (paymentsSum < discountedAmount) {
					return StaticComponents.notification.error({
						message: t`Грешка`,
						description: t`Збир свих начина плаћања је мањи од износа рефундације`,
					});
				}
			}

			if (paymentsSum > discountedAmount) {
				return StaticComponents.notification.error({
					message: t`Грешка`,
					description: t`Збир свих начина плаћања је већи од износа рефундације`,
				});
			}

			const items =
				values.invoiceType === InvoiceType.ADVANCE
					? await getAdvancePaymentsByLabel()
					: receipt.receiptItems.map((item) => ({
							product: {
								id: item.productId,
								price: item.unitPrice,
								ean: item.gtin,
								name: item.name,
								unit: {
									saleUnit: {
										label: item.unit,
										isPieceUnitOfMeasure: item.isPieceUnitOfMeasure,
									},
								},
								sku: item.sku,
							},
							quantity: item.quantity,
							taxRateLabel: item.taxLabels[0],
							finalPrice: item.unitPrice,
							// discount: item.discount,
					  }));
			const { invoice } = await refundReceipt(email, items);
			if (values.payments.cash > 0) {
				await flowResult(
					receipts.createInvoiceV2(
						{
							...values,
							invoiceType: InvoiceType.COPY,
							referentDocumentNumber: invoice.invoiceNumber,
							referentDocumentDT: dayjs(invoice.sdcDateTime),
							items,
						},
						{
							thermal: printDestinationThermal,
							a4: printDestinationA4,
							email,
						},
						true
					)
				);
			}

			onClose();
		},
		[
			getAdvancePaymentsByLabel,
			onClose,
			printDestinationA4,
			printDestinationThermal,
			receipt?.receiptItems,
			receipts,
			refundReceipt,
			values,
		]
	);

	const handleModeCopy = useCallback(
		async (email) => {
			let advancePayments: number | undefined;
			let advanceSale: Partial<Receipt>;
			if (
				receipt.invoiceType === InvoiceTypeAPI.NORMAL &&
				receipt.transactionType === TransactionTypeAPI.SALE
			) {
				if (firstAdvance) {
					advancePayments = receipt.advancePaymentsSum;
					advanceSale = lastAdvanceSale;
				} else if (
					receipt.connectedReceipts[0].invoiceType === InvoiceTypeAPI.ADVANCE &&
					receipt.connectedReceipts[0].transactionType ===
						TransactionTypeAPI.REFUND
				) {
					advancePayments = receipt.connectedReceipts[0].paymentTotal;
					advanceSale = {
						invoiceNumber: receipt.connectedReceipts[0].referentDocumentNumber,
						sdcTime: receipt.connectedReceipts[0].referentDocumentDT,
					};
				}
			}

			await flowResult(
				receipts.createInvoiceV2(
					{
						...values,
						payments: {
							...values.payments,
							cash: evaluate('cash - change', {
								cash: bignumber(values.payments.cash),
								change: bignumber(changeAmount),
							}).toNumber(),
						},
						change: changeAmount,
						advancePayments,
						items: receipt.receiptItems.map((item) => ({
							product: {
								id: item.productId,
								price: item.unitPrice,
								ean: item.gtin,
								name: item.name,
								unit: {
									saleUnit: {
										label: item.unit,
										isPieceUnitOfMeasure: item.isPieceUnitOfMeasure,
									},
								},
								sku: item.sku,
							},
							quantity: item.quantity,
							taxRateLabel: item.taxLabels[0],
							finalPrice: item.unitPrice,
							discount: item.discount,
						})),
					},
					{
						thermal: printDestinationThermal,
						a4: printDestinationA4,
						email,
					},
					false,
					advanceSale
				)
			);

			onClose();
		},
		[
			changeAmount,
			firstAdvance,
			lastAdvanceSale,
			onClose,
			printDestinationA4,
			printDestinationThermal,
			receipt?.advancePaymentsSum,
			receipt?.connectedReceipts,
			receipt?.invoiceType,
			receipt?.receiptItems,
			receipt?.transactionType,
			receipts,
			values,
		]
	);

	const handleModeFinalizeProforma = useCallback(
		async (email) => {
			if (paymentsSum === 0) {
				return StaticComponents.notification.error({
					message: t`Грешка`,
					description: t`Није унет ни један начин плаћања`,
				});
			}

			if (changeAmount > values.payments.cash) {
				return StaticComponents.notification.error({
					message: t`Грешка`,
					description: t`Износ повраћаја мора бити мањи или једнак износу уплате у готовини`,
				});
			}

			const posTerminalResponse = await waitForPosTerminal();

			await flowResult(
				receipts.createInvoiceV2(
					{
						...values,
						change: changeAmount,
						items: receipt.receiptItems.map((item) => ({
							product: {
								id: item.productId,
								price: item.unitPrice,
								ean: item.gtin,
								name: item.name,
								unit: {
									saleUnit: {
										label: item.unit,
										isPieceUnitOfMeasure: item.isPieceUnitOfMeasure,
									},
								},
								sku: item.sku,
							},
							quantity: item.quantity,
							taxRateLabel: item.taxLabels[0],
							finalPrice: item.unitPrice,
							discount: item.discount,
						})),
					},
					{
						thermal: printDestinationThermal,
						a4: printDestinationA4,
						email,
					},
					false,
					undefined,
					posTerminalResponse
				)
			);

			onClose();
		},
		[
			changeAmount,
			onClose,
			paymentsSum,
			printDestinationA4,
			printDestinationThermal,
			receipt?.receiptItems,
			receipts,
			values,
			waitForPosTerminal,
		]
	);

	const handleModeAddAdvance = useCallback(
		async (email) => {
			if (paymentsSum === 0) {
				return StaticComponents.notification.error({
					message: t`Грешка`,
					description: t`Није унет ни један начин плаћања`,
				});
			}

			if (changeAmount > values.payments.cash) {
				return StaticComponents.notification.error({
					message: t`Грешка`,
					description: t`Износ повраћаја мора бити мањи или једнак износу уплате у готовини`,
				});
			}

			const posTerminalResponse = await waitForPosTerminal();

			const advanceItems = receipt.advanceItems.map((item) => ({
				product: {
					id: item.productId,
					price: item.unitPrice,
					ean: item.gtin,
					name: item.name,
					unit: {
						saleUnit: {
							label: item.unit,
							isPieceUnitOfMeasure: item.isPieceUnitOfMeasure,
						},
					},
					sku: item.sku,
				},
				quantity: item.quantity,
				taxRateLabel: item.taxLabels[0],
				finalPrice: item.unitPrice,
				discount: item.discount,
			}));

			const items = await getAdvancePaymentsByLabel();

			await flowResult(
				receipts.createInvoiceV2(
					{
						...values,
						payments: {
							...values.payments,
							cash: evaluate('cash - change', {
								cash: bignumber(values.payments.cash),
								change: bignumber(changeAmount),
							}).toNumber(),
						},
						change: changeAmount,
						items,
						advanceItems,
					},
					{
						thermal: printDestinationThermal,
						a4: printDestinationA4,
						email,
					},
					false,
					undefined,
					posTerminalResponse
				)
			);
			onClose(true);
		},
		[
			changeAmount,
			getAdvancePaymentsByLabel,
			onClose,
			paymentsSum,
			printDestinationA4,
			printDestinationThermal,
			receipt?.advanceItems,
			receipts,
			values,
			waitForPosTerminal,
		]
	);

	const handleModeCloseAdvance = useCallback(
		async (email) => {
			if (paymentsSum === 0 && remainingAmount > 0) {
				return StaticComponents.notification.error({
					message: t`Грешка`,
					description: t`Није унет ни један начин плаћања`,
				});
			}

			if (changeAmount > values.payments.cash) {
				return StaticComponents.notification.error({
					message: t`Грешка`,
					description: t`Износ повраћаја мора бити мањи или једнак износу уплате у готовини`,
				});
			}

			const posTerminalResponse = await waitForPosTerminal();

			const isRefundedAdvance =
				(lastAdvanceReceipt.invoiceType === InvoiceTypeAPI.ADVANCE &&
					lastAdvanceReceipt.transactionType === TransactionTypeAPI.REFUND &&
					advanceAmount - lastAdvanceReceipt.paymentTotal === 0) ||
				isOldAdvance;

			const advancePaymentsByMethod: Record<string, number> = isOldAdvance
				? receipt.payment.reduce((prev, curr) => {
						prev[curr.paymentType] = curr.amount;
						return prev;
				  }, {})
				: receipt?.connectedReceipts
						.filter(
							(cr) =>
								!cr.void &&
								!cr.voids &&
								cr.invoiceType === InvoiceTypeAPI.ADVANCE
						)
						.reduce((prev, curr) => {
							curr.payment.forEach((payment) => {
								if (typeof prev[payment.paymentType] === 'undefined') {
									prev[payment.paymentType] = 0;
								}

								prev[payment.paymentType] = round(
									evaluate('prev + amount', {
										prev: bignumber(prev[payment.paymentType]),
										amount: bignumber(
											curr.transactionType === TransactionTypeAPI.SALE
												? payment.amount
												: -payment.amount
										),
									}).toNumber(),
									2
								);
							});
							return prev;
						}, {});

			const advanceChangeAmount = receipt?.connectedReceipts
				.filter((cr) => !cr.void && !cr.voids)
				.reduce((prev, curr) => {
					prev = round(
						evaluate('prev - paymentChange', {
							prev: bignumber(prev),
							paymentChange: bignumber(curr.paymentChange),
						}).toNumber(),
						2
					);
					return prev;
				}, 0);

			const advanceItems = (
				isOldAdvance ? lastAdvanceReceipt : lastAdvanceSale
			).receiptItems.map((item) => {
				const amount = round(
					receipt.connectedReceipts
						.filter(
							(cr) =>
								cr.invoiceType === InvoiceTypeAPI.ADVANCE &&
								!cr.void &&
								!cr.voids
						)
						.reduce((prev, curr) => {
							return round(
								evaluate('prev + amount * multiplier', {
									prev: bignumber(prev),
									amount: bignumber(
										curr.receiptItems.find(
											(ri) => ri.taxLabels[0] === item.taxLabels[0]
										)?.totalAmount || 0
									),
									multiplier:
										curr.transactionType === TransactionTypeAPI.SALE ? 1 : -1,
								}).toNumber(),
								2
							);
						}, 0),
					2
				);
				return {
					product: {
						id: item.productId,
						price: amount,
						name: item.name,
					},
					quantity: item.quantity,
					taxRateLabel: item.taxLabels[0],
					finalPrice: amount,
				};
			});

			if (!isOldAdvance) {
				Object.entries(advancePaymentsByMethod).forEach(
					([paymentType, amount]) => {
						if (amount < 0) {
							let toSubtract = -amount;
							advancePaymentsByMethod[paymentType] = 0;
							Object.entries(advancePaymentsByMethod).forEach(
								([innerPaymentType, innerAmount]) => {
									if (innerPaymentType !== paymentType) {
										if (innerAmount < toSubtract) {
											toSubtract = evaluate('toSubtract - innerAmount', {
												toSubtract: bignumber(toSubtract),
												innerAmount: bignumber(innerAmount),
											}).toNumber();
											advancePaymentsByMethod[innerPaymentType] = 0;
										} else {
											advancePaymentsByMethod[innerPaymentType] = evaluate(
												'innerAmount - toSubtract',
												{
													innerAmount: bignumber(
														advancePaymentsByMethod[innerPaymentType]
													),
													toSubtract: bignumber(toSubtract),
												}
											).toNumber();
											toSubtract = 0;
										}

										if (toSubtract === 0) {
											return;
										}
									}
								}
							);
						}
					}
				);

				advancePaymentsByMethod.cash = round(
					evaluate('cash + change', {
						cash: bignumber(advancePaymentsByMethod.cash || 0),
						change: bignumber(advanceChangeAmount),
					}).toNumber(),
					2
				);
			}

			let items = (
				isOldAdvance ? lastAdvanceReceipt : lastAdvanceSale
			).advanceItems.map((item) => ({
				product: {
					id: item.productId,
					price: item.unitPrice,
					ean: item.gtin,
					name: item.name,
					unit: {
						saleUnit: {
							label: item.unit,
							isPieceUnitOfMeasure: item.isPieceUnitOfMeasure,
						},
					},
					sku: item.sku,
				},
				quantity: item.quantity,
				taxRateLabel: item.taxLabels[0],
				finalPrice: item.unitPrice,
				discount: item.discount,
			}));

			if (unknownAmountAdvance) {
				if (
					Object.keys(totalsByLabel).length === items.length &&
					(paymentsSum === 0 || items.length === 1)
				) {
					items = items.map((item) => ({
						...item,
						finalPrice: round(
							evaluate('total + paymentsSum', {
								total: bignumber(totalsByLabel[item.taxRateLabel]),
								paymentsSum: bignumber(paymentsSum),
							}).toNumber(),
							2
						),
					}));
				} else {
					setAdvanceFinalizeSpecificationLabels(totalsByLabel);
					setAdvanceFinalizeSpecificationItems(items);
					setAdvanceFinalizeSpecificationDrawerVisible(true);
					advanceSpecificationDeferred = new Deferred();
					items = (await advanceSpecificationDeferred.promise).filter(
						(item) => item.totalAmount > 0
					);
				}
			}

			let invoice;

			if (!isRefundedAdvance) {
				const refundResponse = await flowResult(
					receipts.createInvoiceV2(
						{
							...values,
							transactionType: TransactionType.REFUND,
							payments: advancePaymentsByMethod,
							change: changeAmount,
							items: advanceItems,
							// advanceItems: items,
						},
						{
							thermal: printDestinationThermal,
							a4: printDestinationA4,
							email,
						},
						false
					)
				);
				invoice = refundResponse.invoice;
			} else {
				invoice = lastAdvanceReceipt;
			}
			await flowResult(
				receipts.createInvoiceV2(
					{
						...values,
						transactionType: TransactionType.SALE,
						invoiceType: InvoiceType.NORMAL,
						referentDocumentDT: dayjs(invoice.sdcDateTime),
						referentDocumentNumber: invoice.invoiceNumber,
						payments: {
							...values.payments,
							cash: evaluate('cash - change', {
								cash: bignumber(values.payments.cash),
								change: bignumber(changeAmount),
							}).toNumber(),
						},
						advancePayments: advanceAmount,
						change: changeAmount,
						items,
					},
					{
						thermal: printDestinationThermal,
						a4: printDestinationA4,
						email,
					},
					false,
					isOldAdvance
						? {
								invoiceNumber: lastAdvanceReceipt.referentDocumentNumber,
								sdcTime: lastAdvanceReceipt.referentDocumentDT,
						  }
						: lastAdvanceSale,
					posTerminalResponse
				)
			);
			onClose(true);
		},
		[
			advanceAmount,
			changeAmount,
			isOldAdvance,
			lastAdvanceReceipt,
			lastAdvanceSale,
			onClose,
			paymentsSum,
			printDestinationA4,
			printDestinationThermal,
			receipt?.connectedReceipts,
			receipt?.payment,
			receipts,
			remainingAmount,
			totalsByLabel,
			unknownAmountAdvance,
			values,
			waitForPosTerminal,
		]
	);

	const handleModeNormal = useCallback(
		async (email) => {
			if (paymentsSum === 0) {
				return StaticComponents.notification.error({
					message: t`Грешка`,
					description: t`Није унет ни један начин плаћања`,
				});
			}

			if (changeAmount > values.payments.cash) {
				return StaticComponents.notification.error({
					message: t`Грешка`,
					description: t`Износ повраћаја мора бити мањи или једнак износу уплате у готовини`,
				});
			}

			if (values.invoiceType !== InvoiceType.ADVANCE) {
				if (paymentsSum < discountedAmount) {
					return StaticComponents.notification.error({
						message: t`Грешка`,
						description: t`Збир свих начина плаћања је мањи од износа рачуна`,
					});
				}
			}

			const mappedItems = sale.itemsAsArray.map((item) => ({
				...item,
				product: item.product,
				variant: item.variant,
				taxRateLabel: sale.vatExempt
					? TAX_FREE_LABEL
					: item.product.taxRateLabel,
				finalPrice: round(
					evaluate('finalPrice - (finalPrice * discount) / 100', {
						finalPrice: bignumber(item.finalPrice),
						discount: bignumber(values.discount || 0),
					}).toNumber(),
					2
				),
				discount: round(
					evaluate(
						'priceWithoutDiscount - (finalPrice - (finalPrice * discount) / 100)',
						{
							priceWithoutDiscount: bignumber(item.priceWithoutDiscount),
							finalPrice: bignumber(item.finalPrice),
							discount: bignumber(values.discount || 0),
						}
					).toNumber(),
					2
				),
			}));

			if (
				values.invoiceType === InvoiceType.ADVANCE &&
				values.transactionType === TransactionType.REFUND &&
				values.referentDocumentNumber
					.toLowerCase()
					.startsWith('xxxxxxxx-xxxxxxxx-')
			) {
				const items = await getAdvancePaymentsByLabel();
				await refundReceipt(email, items, mappedItems);
			} else {
				const posTerminalResponse = await waitForPosTerminal();

				const items =
					values.invoiceType === InvoiceType.ADVANCE &&
					values.transactionType === TransactionType.SALE
						? await getAdvancePaymentsByLabel()
						: mappedItems;

				const advanceItems =
					values.invoiceType === InvoiceType.ADVANCE &&
					values.transactionType === TransactionType.SALE
						? mappedItems
						: undefined;
				await flowResult(
					receipts.createInvoiceV2(
						{
							...values,
							payments: {
								...values.payments,
								cash: evaluate('cash - change', {
									cash: bignumber(values.payments.cash),
									change: bignumber(changeAmount),
								}).toNumber(),
							},
							change: changeAmount,
							items,
							advanceItems,
						},
						{
							thermal: printDestinationThermal,
							a4: printDestinationA4,
							email,
						},
						false,
						undefined,
						posTerminalResponse
					)
				);
			}
			onClose(true);
		},
		[
			changeAmount,
			discountedAmount,
			getAdvancePaymentsByLabel,
			onClose,
			paymentsSum,
			printDestinationA4,
			printDestinationThermal,
			receipts,
			refundReceipt,
			sale?.itemsAsArray,
			sale?.vatExempt,
			values,
			waitForPosTerminal,
		]
	);

	const onConfirm = useCallback(async () => {
		let email;
		if (printDestinationEmail) {
			setEmailModalVisible(true);
			emailDeferred = new Deferred();
			email = await emailDeferred.promise;
		}
		setIsLoading(true);

		try {
			switch (mode) {
				case PaymentModalMode.normal:
					await handleModeNormal(email);
					break;
				case PaymentModalMode.copy:
					await handleModeCopy(email);
					break;
				case PaymentModalMode.refund:
					await handleModeRefund(email);
					break;
				case PaymentModalMode.void:
					await handleModeVoid(email);
					break;
				case PaymentModalMode.addAdvancePayment:
					await handleModeAddAdvance(email);
					break;
				case PaymentModalMode.closeAdvance:
					await handleModeCloseAdvance(email);
					break;
				case PaymentModalMode.finalizeProforma:
					await handleModeFinalizeProforma(email);
					break;
			}
		} catch (e) {
			//
		}
		setIsLoading(false);
	}, [
		handleModeAddAdvance,
		handleModeCloseAdvance,
		handleModeCopy,
		handleModeFinalizeProforma,
		handleModeNormal,
		handleModeRefund,
		handleModeVoid,
		mode,
		printDestinationEmail,
	]);

	const onCancel = useCallback(() => {
		onClose();
	}, [onClose]);

	const [title, confirm] = useMemo(() => {
		if (mode === PaymentModalMode.copy) {
			return [t`Копија рачуна`, t`Направи копију`];
		}
		if (mode === PaymentModalMode.void) {
			return [t`Поништавање рачуна`, t`Потврди поништавање`];
		}
		if (mode === PaymentModalMode.addAdvancePayment) {
			return [t`Додавање авансне уплате`, t`Додај уплату`];
		}

		if (mode === PaymentModalMode.closeAdvance) {
			return [t`Затварање аванса`, t`Затвори аванс`];
		}

		return values.transactionType === TransactionType.SALE
			? [t`Плаћање`, t`Потврди плаћање`]
			: [t`Рефундација`, t`Потврди рефундацију`];
	}, [mode, values.transactionType]);

	const hasForeignCurrency = useMemo(() => {
		if (mode === PaymentModalMode.normal) {
			return sale.hasForeignCurrency;
		}

		return false;
	}, [mode, sale?.hasForeignCurrency]);

	const onChange = useCallback(
		(_changedValues, allValues) => {
			setValues({ ...latestValues.current, ...allValues });
		},
		[latestValues]
	);

	useEffect(() => {
		setReceipt(initialReceipt);
		setMode(initialMode);
		if (referentDocument) {
			if (
				referentDocument.invoiceType === InvoiceTypeAPI.ADVANCE &&
				referentDocument.transactionType === TransactionTypeAPI.SALE &&
				values.invoiceType === InvoiceType.ADVANCE &&
				values.transactionType === TransactionType.REFUND
			) {
				setMode(PaymentModalMode.refund);
				setReceipt(referentDocument);
			}
			if (
				referentDocument.invoiceType === InvoiceTypeAPI.ADVANCE &&
				referentDocument.transactionType === TransactionTypeAPI.REFUND &&
				values.invoiceType === InvoiceType.ADVANCE &&
				values.transactionType === TransactionType.SALE
			) {
				setMode(PaymentModalMode.closeAdvance);
				setReceipt(referentDocument);
			}
			if (
				referentDocument.invoiceType === InvoiceTypeAPI.ADVANCE &&
				referentDocument.transactionType === TransactionTypeAPI.SALE &&
				values.invoiceType === InvoiceType.ADVANCE &&
				values.transactionType === TransactionType.SALE
			) {
				setMode(PaymentModalMode.addAdvancePayment);
				setReceipt(referentDocument);
			}
		}
	}, [
		initialMode,
		initialReceipt,
		mode,
		referentDocument,
		values.invoiceType,
		values.transactionType,
	]);

	return (
		<Component
			centered
			width={1100}
			title={title}
			open={open}
			onCancel={onCancel}
			cancelText={t`Одустани`}
			destroyOnClose
			footer={
				<>
					<Row>
						<Col flex="none" className={styles.printModes}>
							{window.electron &&
								thermalPrinters.length > 0 &&
								store?.printMethod?.thermal && (
									<Checkbox
										checked={printDestinationThermal}
										onChange={(e) => {
											setPrintDestinationThermal(!printDestinationThermal);
										}}
										disabled={isLoading || isLoadingReferentDocument}
									>
										{screens.lg ? t`Штампа на термалном штампачу` : t`Термални`}
									</Checkbox>
								)}
							{store?.printMethod?.a4 && (
								<Checkbox
									checked={printDestinationA4}
									onChange={(e) => {
										setPrintDestinationA4(!printDestinationA4);
									}}
									disabled={isLoading || isLoadingReferentDocument}
								>
									{screens.lg ? t`Штампа на А4 штампачу` : t`А4`}
								</Checkbox>
							)}
							{store?.printMethod?.email && (
								<Checkbox
									checked={printDestinationEmail}
									onChange={(e) => {
										setPrintDestinationEmail(!printDestinationEmail);
									}}
									disabled={isLoading || isLoadingReferentDocument}
								>
									{screens.lg &&
									!(
										store?.printMethod?.thermal &&
										store?.printMethod?.a4 &&
										store?.printMethod?.email
									)
										? t`Слање путем електронске поште`
										: t`Ел. пошта`}
								</Checkbox>
							)}
						</Col>
						<Col flex="auto" />
						<Col flex="none">
							<Space>
								<Button
									onClick={onCancel}
									disabled={isLoading || isLoadingReferentDocument}
								>
									<Trans>Откажи</Trans>
								</Button>
								<Button
									onClick={form.submit}
									key="close"
									type="primary"
									disabled={isLoading || isLoadingReferentDocument}
								>
									{confirm}
								</Button>
							</Space>
						</Col>
					</Row>
				</>
			}
		>
			<Spin spinning={isLoading || isLoadingReferentDocument}>
				{[PaymentModalMode.normal, PaymentModalMode.addAdvancePayment].includes(
					mode
				) &&
					values.invoiceType === InvoiceType.ADVANCE &&
					values.posTime &&
					dayjs().startOf('day').diff(values.posTime.startOf('day'), 'days') >
						1 && (
						<Form.Item>
							<Alert
								type="warning"
								message={
									<Trans>
										Према прописима Пореске управе, авансни рачун се може издати
										најкасније сутрадан од извршене уплате. Бади ће вам
										дозволити да издате рачун са ранијим датумом уплате, али
										такав рачун издајете на сопствену одговорност.
									</Trans>
								}
							/>
						</Form.Item>
					)}
				{mode === PaymentModalMode.refund &&
					values.invoiceType === InvoiceType.ADVANCE && (
						<Form.Item>
							<Alert
								type="warning"
								message={
									<Trans>
										Рефундацију аванса користите само у случају повраћаја новца,
										односно уколико се купац предомислио или је авансни рачун
										грешком издат. Уколико желите да затворите аванс, користите
										опцију <strong>Затвори аванс</strong>
									</Trans>
								}
							/>
						</Form.Item>
					)}
				{mode === PaymentModalMode.void &&
					values.transactionType === TransactionType.REFUND &&
					values.buyerId && (
						<Form.Item>
							<Alert
								type="warning"
								message={
									<Trans>
										Уколико поништавате рачун за купца који је обвезник пореза
										на добит правних лица или пореза на приходе од самосталне
										делатности, у обавези сте да поседујете доказ да сте
										обавестили купца о томе да сте му издали погрешан рачун и да
										сте исти поништили.
									</Trans>
								}
							/>
						</Form.Item>
					)}
				{mode === PaymentModalMode.void &&
					receipt.transactionType === TransactionTypeAPI.SALE &&
					!isLastReceipt &&
					!receipt?.buyerId && (
						<Form.Item>
							<Alert
								type="error"
								message={
									<Trans>
										Уколико је приликом продаје на мало издат фискални рачун у
										којем је требало навести и ИД купца, обвезник фискализације
										може непосредно након извршеног промета да поништи тако
										издати рачун и да изда нови. Ако се такав рачун не поништи
										непосредно након извршеног промета, по основу његовог
										накнадног поништавања није дозвољено генерисање новог рачуна
										у коме ће бити наведен ИД купца, односно уколико се изда
										такав рачун, исти се сматра неисправним у смислу члана 5.
										Став 2, а у вези са чланом 15. Став 1. Тачка 2) и ст. 2, 3.
										И 4. Закона о фискализацији.
									</Trans>
								}
							/>
						</Form.Item>
					)}

				{mode === PaymentModalMode.normal &&
					values.transactionType === TransactionType.REFUND && (
						<Form.Item>
							<Alert
								type="warning"
								message={
									<Trans>
										Ручну рефундацију рачуна користите у случају да је рачун
										фискализован пре почетака коришћења апликације или по старом
										моделу фискализације, у супротном користите опцију
										рефундације на детаљима рачуна како би апликација аутоматски
										повезала рачуне.
									</Trans>
								}
							/>
						</Form.Item>
					)}
				{hasForeignCurrency &&
					!exchangeRates.groupedByDate[
						(values.posTime ? dayjs(values.posTime) : dayjs()).format(
							'YYYY-MM-DD'
						)
					] && (
						<>
							{exchangeRates.getByDate(
								values.posTime ? dayjs(values.posTime) : dayjs()
							) && (
								<Form.Item>
									<Alert
										type="warning"
										message={
											values.posTime
												? t`Постоје артикли чија је цена у страној валути, али курсна листа за одабрани дан није доступна. Народна банка Србије курсну листу објављује радним данима у 8:00, а ${APPLICATION_NAME} курсну листу преузима најкасније 5 минута од њеног објављивања. Примењена је курсна листа дана ${exchangeRates
														.getByDate(dayjs(values.posTime))[1]
														.format('LL')}`
												: `Постоје артикли чија је цена у страној валути, али курсна листа за данашњи дан није доступна. Народна банка Србије курсну листу објављује радним данима у 8:00, а ${APPLICATION_NAME} курсну листу преузима најкасније 5 минута од њеног објављивања. Примењена је курсна листа дана ${exchangeRates
														.getByDate(dayjs())[1]
														.format('LL')}`
										}
									/>
								</Form.Item>
							)}
						</>
					)}
				<Form
					{...formItemLayout}
					form={form}
					layout={screens.lg ? 'horizontal' : 'vertical'}
					onValuesChange={onChange}
					validateTrigger={['onSubmit']}
					className={styles.formStyle}
					onFinish={onConfirm}
				>
					<Row gutter={24}>
						<Col span={screens.md ? 12 : 24}>
							<Form.Item
								label={t`Врста рачуна`}
								name="invoiceType"
								className={
									values.invoiceType === InvoiceType.ADVANCE &&
									values.transactionType === TransactionType.SALE &&
									globalStyles.condensedFormField
								}
							>
								<Select
									disabled={
										mode !== PaymentModalMode.normal && mode === initialMode
									}
									options={
										mode === PaymentModalMode.copy
											? copyInvociceTypes.map((type) => ({
													value: type,
													label: INVOICE_TYPE_TEXT[type],
											  }))
											: normalInvoiceTypes.map((type) => ({
													value: type,
													label: INVOICE_TYPE_TEXT[type],
											  }))
									}
								></Select>
							</Form.Item>
							{values.invoiceType === InvoiceType.ADVANCE && (
								<Form.Item
									name="unknownAmountAdvance"
									valuePropName="checked"
									colon={false}
									label={screens.lg ? ' ' : undefined}
								>
									<Checkbox
										disabled={
											mode !== PaymentModalMode.normal && mode === initialMode
										}
									>
										<Trans>Коначан износ је непознат</Trans>
									</Checkbox>
								</Form.Item>
							)}
							<Form.Item label={t`Врста трансакције`} name="transactionType">
								<Select
									disabled={
										mode !== PaymentModalMode.normal && mode === initialMode
									}
									options={transactionTypes.map((type) => ({
										value: type,
										label: TRANSACTION_TYPE_TEXT[type],
									}))}
								/>
							</Form.Item>
							{values.invoiceType === InvoiceType.ADVANCE &&
								values.transactionType === TransactionType.SALE && (
									<>
										<Form.Item
											label={t`Датум уплате`}
											name="posTime"
											rules={[
												{
													validator(rule, value) {
														if (value) {
															if (value.isSameOrBefore(dayjs())) {
																return Promise.resolve();
															}

															return Promise.reject(
																t`Датум уплате не може бити у будућности`
															);
														}

														return Promise.resolve();
													},
												},
											]}
										>
											<DatePicker
												showTime
												disabled={
													mode === PaymentModalMode.copy && mode === initialMode
												}
												style={{ width: '100%' }}
												format="L HH:mm:ss"
											/>
										</Form.Item>
									</>
								)}
							<Divider />
							<Form.Item
								rules={[
									...(mode !== PaymentModalMode.closeAdvance &&
									values.transactionType === TransactionType.REFUND &&
									(values.invoiceType !== InvoiceType.ADVANCE ||
										(values.invoiceType === InvoiceType.ADVANCE &&
											!(values.referentDocumentNumber || '')
												.toLowerCase()
												.startsWith('xxxxxxxx-xxxxxxxx-')))
										? [
												{
													required: true,
													message: t`Идентификација купца је обавезна код рефундације`,
												},
										  ]
										: []),
									{
										message: t`Морате унети број документа`,
										validator: async (rule, value) => {
											if (!value) {
												return Promise.resolve();
											}
											const [type, ...id] = value.split(':');
											if (type !== '' && id.join(':') === '') {
												return Promise.reject();
											}
											return Promise.resolve();
										},
									},
								]}
								extra={
									values.transactionType === TransactionType.REFUND &&
									values.invoiceType === InvoiceType.ADVANCE &&
									(values.referentDocumentNumber || '')
										.toLowerCase()
										.startsWith('xxxxxxxx-xxxxxxxx-')
										? t`Уколико вршите рефундацију у циљу затварања аванса, идентификација купца није обавезна`
										: null
								}
								label={t`ИД купца`}
								name="buyerId"
							>
								<BuyerInput
									disabled={
										[PaymentModalMode.copy, PaymentModalMode.void].includes(
											mode
										) && mode === initialMode
									}
								/>
							</Form.Item>
							{values.buyerId && (
								<Form.Item
									label={t`Опционо поље купца`}
									name="buyerCostCenterId"
									rules={[
										{
											message: t`Морате унети број документа`,
											validator: async (rule, value) => {
												if (!value) {
													return Promise.resolve();
												}
												const [type, ...id] = value.split(':');
												if (type !== '' && id.join(':') === '') {
													return Promise.reject();
												}
												return Promise.resolve();
											},
										},
									]}
								>
									<BuyerCostCenterInput
										disabled={
											[PaymentModalMode.copy, PaymentModalMode.void].includes(
												mode
											) && mode === initialMode
										}
									/>
								</Form.Item>
							)}
							<Divider />
							<Form.Item
								name="referentDocumentNumber"
								label={t`Број реф. документа`}
								rules={[
									...(values.transactionType === TransactionType.REFUND ||
									values.referentDocumentDT
										? [{ required: true }]
										: []),
									{
										validator(rule, value) {
											if (value) {
												// check if format is XXXXXXXX-XXXXXXXX-1
												if (/^[0-9A-Z]{8}-[0-9A-Z]{8}-\d+$/i.test(value)) {
													return Promise.resolve();
												}

												return Promise.reject(
													t`Број референтног документа није у исправном формату`
												);
											}

											return Promise.resolve();
										},
									},
								]}
							>
								<Input
									disabled={
										mode !== PaymentModalMode.normal && mode === initialMode
									}
									onBlur={fetchReferentDocument}
								/>
							</Form.Item>
							<Form.Item
								name="referentDocumentDT"
								label={t`Датум реф. докум.`}
								rules={[
									...(values.transactionType === TransactionType.REFUND ||
									values.referentDocumentNumber
										? [{ required: true }]
										: []),
									{
										validator(rule, value) {
											if (value) {
												if (value.isSameOrBefore(dayjs())) {
													return Promise.resolve();
												}

												return Promise.reject(
													t`Датум референтног документа не може бити у будућности`
												);
											}

											return Promise.resolve();
										},
									},
								]}
							>
								<DatePicker
									disabled={
										mode !== PaymentModalMode.normal && mode === initialMode
									}
									format="L HH:mm:ss"
									showTime
									style={{ width: '100%' }}
								/>
							</Form.Item>
							<Divider />
							<Form.Item name="additionalText" label={t`Додатни текст`}>
								<Input.TextArea />
							</Form.Item>
						</Col>
						{!screens.md && <Divider />}
						<Col span={screens.md ? 12 : 24}>
							{visiblePaymentMethods.map((method, index) => (
								<PaymentInput
									method={method}
									showHidden={true}
									mode={mode}
									isInputFocused={isInputFocused}
									values={values}
									unknownAmountAdvance={unknownAmountAdvance}
									index={index}
									paymentsSum={paymentsSum}
									discountedAmount={discountedAmount}
									advanceAmount={advanceAmount}
									setValues={setValues}
									isOldAdvance={isOldAdvance}
									remainingAmount={remainingAmountWithoutPayments}
									totalAmount={totalAmount}
								/>
							))}
							{!showHidden && hiddenPaymentMethods.length > 0 && (
								<Row>
									<Col span={screens.lg ? 8 : 0}></Col>
									<Col span={screens.lg ? 16 : 24}>
										<Button
											type="link"
											onClick={() => setShowHidden(true)}
											size="small"
											block
										>
											<Trans>Прикажи све начине плаћања</Trans>
										</Button>
									</Col>
								</Row>
							)}
							{hiddenPaymentMethods.map((method, index) => (
								<PaymentInput
									method={method}
									showHidden={showHidden}
									mode={mode}
									isInputFocused={isInputFocused}
									values={values}
									unknownAmountAdvance={unknownAmountAdvance}
									index={index + visiblePaymentMethods.length}
									paymentsSum={paymentsSum}
									discountedAmount={discountedAmount}
									advanceAmount={advanceAmount}
									setValues={setValues}
									isOldAdvance={isOldAdvance}
									remainingAmount={remainingAmountWithoutPayments}
									totalAmount={totalAmount}
								/>
							))}

							<Divider />

							{!unknownAmountAdvance && (
								<>
									<Form.Item label={t`Укупно`}>
										<Input
											value={`${numberFormat(totalAmount, false, 2, true)}`}
											readOnly
											disabled={[
												PaymentModalMode.copy,
												PaymentModalMode.void,
											].includes(mode)}
										/>
									</Form.Item>
								</>
							)}

							{mode === PaymentModalMode.normal &&
								values.transactionType === TransactionType.SALE &&
								!unknownAmountAdvance && (
									<>
										<Form.Item
											label={
												<>
													<Trans>Попуст</Trans>
													&nbsp;
													<Tooltip
														title={t`Попуст се обрачунава на појединачним ставкама`}
													>
														<i className="fi fi-rr-info" />
													</Tooltip>
												</>
											}
											name="discount"
											initialValue={0}
											rules={[
												{
													type: 'number',
													validator: async (rule, value) => {
														if (value < 0 || value > 100) {
															return Promise.reject(
																t`Попуст мора бити између 0 и 100%`
															);
														}
														return Promise.resolve();
													},
												},
											]}
										>
											<InputNumber
												type="number"
												suffix="%"
												min={0}
												max={100}
												style={{ width: '100%' }}
												onWheel={(e) => {
													const element = e.currentTarget;
													const hasFocus = element.matches(':focus');
													element.blur();
													setTimeout(() => {
														if (hasFocus) {
															element.focus();
														}
													}, 0);
												}}
											/>
										</Form.Item>
									</>
								)}
							{([
								PaymentModalMode.closeAdvance,
								PaymentModalMode.addAdvancePayment,
							].includes(mode) ||
								isReferentDocumentAdvanceRefund) && (
								<Form.Item label={t`Плаћено авансом`}>
									<Input
										value={`${numberFormat(advanceAmount, false, 2, true)}`}
										readOnly
										disabled={[
											PaymentModalMode.copy,
											PaymentModalMode.void,
										].includes(mode)}
									/>
								</Form.Item>
							)}
							{mode === PaymentModalMode.normal &&
								values.transactionType !== TransactionType.REFUND &&
								!unknownAmountAdvance && (
									<Form.Item label={t`Укупно за уплату`}>
										<Input
											value={`${numberFormat(
												discountedAmount,
												false,
												2,
												true
											)}`}
											readOnly
											disabled={[
												PaymentModalMode.copy,
												PaymentModalMode.void,
											].includes(mode)}
										/>
									</Form.Item>
								)}

							{!unknownAmountAdvance && (
								<Form.Item label={t`Преостали износ`}>
									<Input
										value={`${numberFormat(remainingAmount, false, 2, true)}`}
										readOnly
										disabled={[
											PaymentModalMode.copy,
											PaymentModalMode.void,
										].includes(mode)}
									/>
								</Form.Item>
							)}
							{((!unknownAmountAdvance &&
								values.transactionType !== TransactionType.REFUND) ||
								(!unknownAmountAdvance &&
									mode === PaymentModalMode.closeAdvance)) && (
								<Form.Item label={t`Повраћај`}>
									<Input
										readOnly
										disabled={[
											PaymentModalMode.copy,
											PaymentModalMode.void,
										].includes(mode)}
										value={numberFormat(changeAmount, false, 2, true)}
									/>
								</Form.Item>
							)}
						</Col>
					</Row>
				</Form>
			</Spin>
			<AdvanceSpecificationDrawer
				visible={advanceSpecificationDrawerVisible}
				labels={advanceSpecificationLabels}
				sum={paymentsSum}
				onCancel={() => {
					setAdvanceSpecificationDrawerVisible(false);
					advanceSpecificationDeferred.reject();
				}}
				onSave={(labels) => {
					setAdvanceSpecificationDrawerVisible(false);
					advanceSpecificationDeferred.resolve(labels);
				}}
			/>
			<AdvanceFinalizeSpecificationDrawer
				visible={advanceFinalizeSpecificationDrawerVisible}
				labels={advanceFinalizeSpecificationLabels}
				items={advanceFinalizeSpecificationItems}
				sum={paymentsSum + advanceAmount}
				onCancel={() => {
					setAdvanceFinalizeSpecificationDrawerVisible(false);
					advanceSpecificationDeferred.reject();
				}}
				onSave={(labels) => {
					setAdvanceFinalizeSpecificationDrawerVisible(false);
					advanceSpecificationDeferred.resolve(labels);
				}}
			/>
			<EmailModal
				visible={emailModalVisible}
				onCancel={() => {
					setEmailModalVisible(false);
					emailDeferred.reject();
				}}
				onOk={(email) => {
					setEmailModalVisible(false);
					emailDeferred.resolve(email);
				}}
			/>
			<PosTerminalModal
				visible={posTerminalModalVisible}
				onCancel={() => {
					setPosTerminalModalVisible(false);
					posTerminalDeferred.reject();
				}}
				amount={round(values.payments.card, 2)}
				onOk={(response) => {
					try {
						setPosTerminalModalVisible(false);
						posTerminalDeferred.resolve(response);
					} catch (e) {
						console.log(e);
					}
				}}
			/>
		</Component>
	);
}

export default observer(PaymentModal);
