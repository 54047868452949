import { t, Trans } from '@lingui/macro';
import {
	Affix,
	Alert,
	Button,
	Checkbox,
	Col,
	Drawer,
	Form,
	Input,
	InputNumber,
	List,
	notification,
	Row,
	Select,
	Space,
	Tag,
} from 'antd';
import keyBy from 'lodash/keyBy';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import styles from './ConnectProductsDrawer.module.less';
import { useDrawer } from '../../../../../../components/Page';
import pageStyles from '../../../../../../components/Page/Page.module.less';
import { StaticComponents } from '../../../../../../components/StaticComponents';
import { v2Client } from '../../../../../../store/client';
import stores from '../../../../../../stores/index.mobx';
interface Props {
	visible: boolean;
	onClose: () => void;
	items: any[];
	formatItem: (item: any) => any;
	categoryProperty: string;
	enabledProperty: string;
	platformName: string;
}

function ConnectProductDrawer({
	visible,
	onClose,
	items,
	formatItem,
	categoryProperty,
	enabledProperty,
	platformName,
}: Props) {
	const [form] = Form.useForm();

	const [searchTerm, setSearchTerm] = useState('');
	const [filter, setFilter] = useState<string[]>(['not_connected']);

	const transformedItems = useMemo(() => items.map(formatItem), [items]);

	const formItems = useRef(keyBy(transformedItems, 'externalId'));

	useEffect(() => {
		formItems.current = keyBy(transformedItems, 'externalId');
	}, [transformedItems]);

	const onValuesChange = useCallback((changedValues) => {
		formItems.current = {
			...formItems.current,
			...Object.fromEntries(
				Object.entries(changedValues).map(
					([k, v]: [string, Record<string, any>]) => [
						k,
						{
							...formItems.current[k],
							...v,
							metadata: {
								...(formItems.current[k]?.metadata || {}),
								...v.metadata,
							},
						},
					]
				)
			),
		};
	}, []);

	const searchListener = useCallback(
		(event, item, index) => {
			if (event === 'select') {
				form.setFieldValue([index, 'productId'], item.id);
				form.setFieldValue(
					[index, 'productName'],
					item.parent ? `${item.parent.name} ${item.name}` : item.name
				);

				onValuesChange({
					[index]: {
						productId: item.id,
						productName: item.parent
							? `${item.parent.name} ${item.name}`
							: item.name,
					},
				});
			}
		},
		[form]
	);

	const [, openSearchDrawer, , , , ProductSearchDrawer] = useDrawer(
		'product-search',
		searchListener,
		false
	);

	const [isSaving, setIsSaving] = useState(false);

	const [id] = useDrawer('marketplace-application-settings');

	const haveNotConnectedItems = useMemo(
		() => items.find((item) => !item.product),
		[items]
	);

	const handleFormSubmit = useCallback(
		async (values) => {
			setIsSaving(true);

			try {
				await v2Client.post(
					`/marketplace-applications/${id}/functions/save-mappings`,
					Object.values(formItems.current)
				);

				StaticComponents.notification.success({
					message: t`Артикли су успешно повезани`,
				});

				stores.products.fetchAll();
				onClose();
			} catch (error) {
				StaticComponents.notification.error({
					message: 'Greška',
					description: t`Дошло је до грешке приликом чувања повезаних артикала.`,
				});
			} finally {
				setIsSaving(false);
			}
		},
		[formItems]
	);

	const onSearchTermChange = useCallback((event) => {
		setSearchTerm(event.target.value);
	}, []);

	const onFilterChange = useCallback((value) => {
		setFilter(value);
	}, []);

	const filteredItems = useMemo(
		() =>
			transformedItems
				.filter((item) => {
					if (searchTerm) {
						return (
							(item.name || '')
								.toLowerCase()
								.includes(searchTerm.toLowerCase()) ||
							(item.description || '')
								.toLowerCase()
								.includes(searchTerm.toLowerCase())
						);
					}

					return true;
				})
				.filter((item) => {
					if (filter.includes('not_connected') && !item.product) {
						return true;
					}

					if (
						filter.includes('connected') &&
						item.productId &&
						item.productId === item.product?.product?.id
					) {
						return true;
					}
					return false;
				}),
		[transformedItems, filter, searchTerm]
	);

	const renderItem = (item) => (
		<List.Item key={item.externalId}>
			<List.Item.Meta
				title={
					<>
						{item.name}
						<br />
						{form.getFieldValue([item.externalId, 'productId']) &&
							form.getFieldValue([item.externalId, 'productId']) !==
								item.product?.product?.id && (
								<Tag bordered={false} color="orange">
									<Trans>Нацрт</Trans>
								</Tag>
							)}
						{form.getFieldValue([item.externalId, 'productId']) &&
							form.getFieldValue([item.externalId, 'productId']) ===
								item.product?.product?.id && (
								<Tag bordered={false} color="green">
									<Trans>Повезан</Trans>
								</Tag>
							)}
						{!form.getFieldValue([item.externalId, 'productId']) &&
							!item.product && (
								<Tag bordered={false}>
									<Trans>Није повезан</Trans>
								</Tag>
							)}
					</>
				}
				description={item.description}
				avatar={<img src={item.imageUrl} width={100} />}
			/>
			<div className={styles.connectedProductWrapper}>
				<Row gutter={8}>
					<Col span={24}>
						<Form.Item
							name={[item.externalId, 'externalId']}
							hidden
							initialValue={item.externalId}
						>
							<Input readOnly />
						</Form.Item>
						<Form.Item
							name={[item.externalId, enabledProperty]}
							hidden
							initialValue={item[enabledProperty]}
							valuePropName="checked"
						>
							<Checkbox />
						</Form.Item>
						<Form.Item
							name={[item.externalId, 'metadata', categoryProperty]}
							hidden
							initialValue={item.metadata?.[categoryProperty]}
						>
							<Input readOnly />
						</Form.Item>
						<Form.Item
							name={[item.externalId, 'description']}
							hidden
							initialValue={item.product?.description || item.description}
						>
							<Input readOnly />
						</Form.Item>
						<Form.Item
							name={[item.externalId, 'name']}
							hidden
							initialValue={item.product?.name || item.name}
						>
							<Input readOnly />
						</Form.Item>

						<Form.Item
							name={[item.externalId, 'productId']}
							hidden
							initialValue={item.product?.productId}
						>
							<Input readOnly />
						</Form.Item>
						<Form.Item
							label={t`Артикал`}
							name={[item.externalId, 'productName']}
							initialValue={
								item.product?.product?.parent
									? `${item.product?.product?.parent?.name} ${item.product?.product?.name}`
									: item.product?.product?.name
							}
						>
							<Input
								readOnly
								suffix={
									form.getFieldValue([item.externalId, 'productId']) ? (
										<i
											className="fi fi-rr-cross"
											onClick={() => {
												form.setFieldValue(
													[item.externalId, 'productId'],
													null
												);
												form.setFieldValue(
													[item.externalId, 'productName'],
													null
												);
											}}
										/>
									) : (
										<i className="fi fi-rr-search"></i>
									)
								}
								placeholder={t`Одаберите артикал`}
								onClick={() => openSearchDrawer(item.externalId)}
							/>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							label={t`Продајна количина`}
							name={[item.externalId, 'saleQuantity']}
							initialValue={
								typeof item.product?.saleQuantity === 'number'
									? item.product?.saleQuantity
									: 1
							}
						>
							<InputNumber style={{ width: '100%' }} />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							label={t`Цена`}
							name={[item.externalId, 'salePrice']}
							initialValue={item.product?.salePrice || item.price}
						>
							<InputNumber style={{ width: '100%' }} />
						</Form.Item>
					</Col>
				</Row>
			</div>
		</List.Item>
	);

	return (
		<Drawer
			title={t`Повезивање артикала`}
			width={720}
			visible={visible}
			onClose={onClose}
			destroyOnClose={true}
			footerStyle={{ textAlign: 'right' }}
			footer={
				<>
					<Space className={pageStyles.leftButton}>
						<Button key="close" onClick={onClose}>
							<Trans>Затвори</Trans>
						</Button>
					</Space>
					<Button
						type="primary"
						onClick={() => form.submit()}
						disabled={isSaving}
						loading={isSaving}
					>
						<Trans>Сачувај</Trans>
					</Button>
				</>
			}
			className="affixDrawerContainer"
		>
			<Form
				form={form}
				layout="vertical"
				onFinish={handleFormSubmit}
				onValuesChange={onValuesChange}
			>
				<Form.Item>
					<Alert
						type="info"
						message={
							<>
								{haveNotConnectedItems && (
									<Trans>
										Пронађени су артикли на платформи {platformName} који нису
										повезани са артиклима у апликацији. За сваки артикал са
										списка, потребно је одабрати одговарајући артикал из
										апликације.
									</Trans>
								)}
								{!haveNotConnectedItems && (
									<Trans>
										Сви артикли са платформе {platformName} су повезани са
										артиклима у апликацији. Можете променити повезане артикле
										одабиром одговарајућег артикла из апликације.
									</Trans>
								)}
								<br />
								<br />
								<Trans>
									<strong>Продајна количина</strong> је количина која се продаје
									на платформи {platformName}. Уколико је количина на платформи{' '}
									{platformName}
									иста као и у апликацији, унесите <strong>1</strong>.
								</Trans>
								<br />
								<Trans>
									<strong>Цена</strong> је цена која се приказује на платформи{' '}
									{platformName}, и поље се може оставити празно уколико је цена
									иста као и у апликацији.
								</Trans>
							</>
						}
					/>
				</Form.Item>
				<Affix
					offsetTop={16}
					target={() =>
						document.querySelector(
							'.affixDrawerContainer .ant-drawer-body'
						) as HTMLElement
					}
				>
					<Form.Item className={styles.filter}>
						<Row gutter={[8, 8]}>
							<Col sm={12} xs={24}>
								<Input.Search
									placeholder={t`Унеси термин за претрагу`}
									value={searchTerm}
									onChange={onSearchTermChange}
								/>
							</Col>
							<Col sm={12} xs={24}>
								<Select
									mode="multiple"
									value={filter}
									onChange={onFilterChange}
								>
									<Select.Option value="not_connected">
										<Trans>Није повезан</Trans>
									</Select.Option>
									<Select.Option value="connected">
										<Trans>Повезан</Trans>
									</Select.Option>
								</Select>
							</Col>
						</Row>
					</Form.Item>
				</Affix>
				<List
					dataSource={filteredItems}
					itemLayout="vertical"
					renderItem={renderItem}
					pagination={{
						position: 'bottom',
						size: 'small',
					}}
				/>
			</Form>
			<ProductSearchDrawer />
		</Drawer>
	);
}

export default ConnectProductDrawer;
