import { t } from '@lingui/macro';
import { message } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import round from 'lodash/round';
import { action, autorun, computed, observable } from 'mobx';
import { v4 as uuid } from 'uuid';

import { CreateStore } from './Crud.mobx';
import { Discounts } from './Discount.mobx';
import stores from './index.mobx';
import { Product } from './Product.mobx';
import { DayjsTransformer } from './transformers/Dayjs';
import { ReferenceTransformer } from './transformers/Reference';
import { StaticComponents } from '../components/StaticComponents';
import { InvoiceTypeAPI } from '../constants/journal';
import { bignumber, evaluate } from 'mathjs';

export type LocalSalePayment = {
	paymentType:
		| 'card'
		| 'check'
		| 'cash'
		| 'mobilemoney'
		| 'wiretransfer'
		| 'other';
	amount: number;
};

const { Store, Entity } = CreateStore({
	name: 'localSale',
	paginated: false,
	persistFields: ['all', 'active', 'saleCount'],
	clientVersion: 'local',
	persistDelay: 0,
});

class LocalSaleItem extends Entity {
	@observable key?: string;
	@observable productId?: string;
	@ReferenceTransformer('product', 'productId') product?: Product;
	@observable variantId?: string;
	@ReferenceTransformer('product', 'variantId') variant?: Product;
	@observable quantity = 0;
	@observable price = 0;
	@observable discount = 0;

	constructor(data, parent) {
		super(parent);
		this.init({ ...data, key: data.key || data.productId });
	}

	*update(data) {
		yield this.replace(data);
		return this;
	}

	finalPriceOnDate(date: Dayjs, discounted = true) {
		const { taxRates, exchangeRates } = stores;
		let price = this.price;

		if (this.variant?.['isResolving'] || this.product?.['isResolving']) {
			return 0;
		}

		const currencyId = this.variant
			? this.variant?.currencyId
			: this.product?.currencyId;

		if (this.getParent().vatExempt) {
			const taxRateLabel = this.variant
				? this.variant?.taxRateLabel
				: this.product?.taxRateLabel;
			const vatRate = taxRates.byLabel(taxRateLabel).rate;
			price = round(
				evaluate('price / (1 + vatRate / 100)', {
					price: bignumber(this.price),
					vatRate: bignumber(vatRate),
				}).toNumber(),
				2
			);
		}

		if (!currencyId || currencyId === 'RSD') {
			return this.discount
				? round(
						evaluate('price * (1 - discount / 100)', {
							price: bignumber(price),
							discount: bignumber(this.discount),
						}).toNumber(),
						2
				  )
				: price;
		}

		const data = exchangeRates.getByDate(date);
		const exchangeRate = data?.[0]?.[currencyId]?.rate || 0;
		return this.discount && discounted
			? round(
					evaluate('price * exchangeRate * (1 - discount / 100)', {
						price: bignumber(price),
						exchangeRate: bignumber(exchangeRate),
						discount: bignumber(this.discount),
					}).toNumber(),
					2
			  )
			: round(
					evaluate('price * exchangeRate', {
						price: bignumber(price),
						exchangeRate: bignumber(exchangeRate),
					}).toNumber(),
					2
			  );
	}

	@computed
	get priceWithoutDiscount() {
		const { taxRates, exchangeRates } = stores;
		let price = this.price;

		const currencyId = this.variant
			? this.variant?.currencyId
			: this.product?.currencyId;

		if (this.getParent().vatExempt) {
			const taxRateLabel = this.variant
				? this.variant?.taxRateLabel
				: this.product?.taxRateLabel;
			const vatRate = taxRates.byLabel(taxRateLabel).rate;
			price = round(
				evaluate('price / (1 + vatRate / 100)', {
					price: bignumber(this.price),
					vatRate: bignumber(vatRate),
				}).toNumber(),
				2
			);
		}

		if (!currencyId || currencyId === 'RSD') {
			return price;
		}

		const lastRates = exchangeRates.lastRates;
		const exchangeRate = lastRates[currencyId].rate;
		return round(
			evaluate('price * exchangeRate', {
				price: bignumber(price),
				exchangeRate: bignumber(exchangeRate),
			}).toNumber(),
			2
		);
	}

	@computed
	get finalPrice() {
		return this.finalPriceOnDate(this.getParent().date || dayjs());
	}

	@computed
	get finalPriceWithoutDiscount() {
		return this.finalPriceOnDate(this.getParent().date || dayjs(), false);
	}
}

class LocalSale extends Entity {
	@observable items: Record<string, LocalSaleItem> = {};
	@observable activeProductId: string;
	@observable uniqueId: string;
	@DayjsTransformer date?: Dayjs;
	@observable vatExempt = false;
	@observable payment: LocalSalePayment[] = [];
	@observable invoiceType: InvoiceTypeAPI;
	@observable currentSaleChannelId?: string = null;

	constructor(data, parent) {
		super(parent);
		this.init(data);

		autorun(() => {
			if (stores.application.isInitialized) {
				let hasRemovedItems = false;
				this.itemsAsArray.forEach((item) => {
					if (
						!item.product?.['isResolving'] &&
						(!item.product || (item.product.hasVariants && !item.variant))
					) {
						this.removeItem(item.key);
						hasRemovedItems = true;
					}
				});

				if (hasRemovedItems) {
					StaticComponents.notification.warning({
						message: t`Обрисани артикли`,
						description: t`Неки артикли који су били на рачуну у припреми су обрисани. Артикли су уклоњени са рачуна у припреми.`,
					});
				}
			}
		});
	}

	@computed
	get itemsAsArray() {
		return Object.values(this.items);
	}

	@computed
	get activeSaleItem() {
		return this.items[this.activeProductId];
	}

	@computed
	get total() {
		return this.itemsAsArray.reduce(
			(total, item) =>
				round(
					evaluate('total + quantity * finalPrice', {
						total: bignumber(total),
						quantity: bignumber(round(item.quantity, 3)),
						finalPrice: bignumber(item.finalPrice),
					}).toNumber(),
					2
				),
			0
		);
	}

	@computed
	get hasForeignCurrency() {
		return this.itemsAsArray.some((item) => {
			const currencyId = item.variant
				? item.variant?.currencyId
				: item.product?.currencyId;
			return currencyId !== 'RSD' && currencyId;
		});
	}

	@action.bound
	setCurrentSaleChannelId(id: string) {
		this.currentSaleChannelId = id;
	}

	@action.bound
	setPayment(payment: LocalSalePayment[]) {
		this.payment = payment;
	}

	@action.bound
	setInvoiceType(invoiceType: InvoiceTypeAPI) {
		this.invoiceType = invoiceType;
	}

	@action.bound
	setDate(date: Dayjs) {
		this.date = date;
	}

	@action.bound
	setTaxFree(vatExempt: boolean) {
		this.vatExempt = vatExempt;
	}

	@action.bound
	addItem(product: Product, quantity = 0, overridePrice = false) {
		let discount = 0;

		(stores.discounts as Discounts).activeDiscounts.forEach(
			(activeDiscount) => {
				if (activeDiscount.rules) {
					activeDiscount.rules.forEach((rule) => {
						if (
							rule.type === 'all' ||
							(rule.type === 'products' && rule?.value.includes(product.id)) ||
							(rule.type === 'categories' &&
								rule.value?.find((categoryId) =>
									(product.parent ? product.parent : product).categories.find(
										(category) => category.id === categoryId
									)
								))
						) {
							discount = Math.max(discount, activeDiscount.percentage);
						}
					});
				}
			}
		);

		if (quantity === 0) {
			if (product.quantityFromScale && stores.devices.scales.length > 0) {
				return StaticComponents.notification.error({
					message: t`Грешка`,
					description: t`Са ваге је очитана тежина 0. Проверите да ли је артикал постављен на вагу.`,
				});
			}
			return StaticComponents.notification.error({
				message: t`Грешка`,
				description: t`Количина не може бити 0`,
			});
		}

		const id = `${product.id}${product.multiplePerReceipt ? `:${uuid()}` : ''}`;

		const productPrice = this.currentSaleChannelId
			? product.priceBySaleChannel(this.currentSaleChannelId)
			: product.currentStorePrice || 0;

		if (product.parentId) {
			// variant
			const parent = product.parent;

			if (this.items[id]) {
				const current = this.items[id];
				current.replace({
					quantity: round(
						evaluate('cquantity + quantity', {
							cquantity: bignumber(current.quantity),
							quantity: bignumber(quantity),
						}).toNumber(),
						3
					),
					discount: this.items[id].discount,
				});
			} else {
				this.items[id] = new LocalSaleItem(
					{
						id: product.id,
						key: id,
						price: overridePrice || productPrice,
						productId: parent.id,
						variantId: product.id,
						quantity,
						discount,
					},
					this
				);
			}
		} else {
			if (this.items[id]) {
				const current = this.items[id];
				current.replace({
					quantity: round(
						evaluate('cquantity + quantity', {
							cquantity: bignumber(current.quantity),
							quantity: bignumber(quantity),
						}).toNumber(),
						3
					),
					discount: this.items[id].discount,
				});
			} else {
				this.items[id] = new LocalSaleItem(
					{
						id: product.id,
						key: id,
						price: overridePrice || productPrice,
						productId: product.id,
						quantity,
						discount,
					},
					this
				);
			}
		}

		this.activeProductId = id;
		return true;
	}

	@action.bound
	removeItem(id: string) {
		const currentIndex = this.itemsAsArray.findIndex(
			(value) => value.id === this.activeProductId
		);

		if (currentIndex === this.itemsAsArray.length - 1) {
			this.selectPreviousItem();
		} else {
			this.selectNextItem();
		}

		if (this.items[id]) {
			delete this.items[id];
		}
	}

	@action.bound selectPreviousItem() {
		const currentIndex = this.itemsAsArray.findIndex(
			(value) => value.key === this.activeProductId
		);

		if (currentIndex === 0) {
			this.activeProductId =
				this.itemsAsArray[this.itemsAsArray.length - 1].key;
		} else {
			this.activeProductId = this.itemsAsArray[currentIndex - 1].key;
		}
	}

	@action.bound selectNextItem() {
		const currentIndex = this.itemsAsArray.findIndex(
			(value) => value.key === this.activeProductId
		);

		if (currentIndex === this.itemsAsArray.length - 1) {
			this.activeProductId = this.itemsAsArray[0].key;
		} else {
			this.activeProductId = this.itemsAsArray[currentIndex + 1].key;
		}
	}

	@action.bound updateQuantity(id: string, quantity: number) {
		if (this.items[id]) {
			const product = this.items[id];
			product.replace({
				quantity,
			});
		}
		this.activeProductId = id;
	}

	replace(data) {
		data.items = Object.fromEntries(
			Object.entries(data.items).map(([key, item]) => [
				key,
				new LocalSaleItem(item, this),
			])
		);
		super.replace(data);
	}
}

class LocalSales extends Store<LocalSale> {
	@observable active?: string;
	@observable saleCount = 0;

	constructor() {
		super(LocalSale);
	}

	@action.bound
	createSale() {
		this.saleCount += 1;
		const sale = new LocalSale(
			{
				items: {},
				id: `${this.saleCount}`,
				uniqueId: uuid(),
				activeProductId: null,
			},
			this
		);
		this.all.push(sale);
		this.active = `${this.saleCount}`;
		return sale;
	}

	@action.bound
	setActive(id: string) {
		this.active = id;
	}

	@action.bound
	removeSale(sale: LocalSale, forceCreate = false) {
		this.all = this.all.filter((s) => s.id !== sale.id);

		if (this.all.length === 0 || forceCreate) {
			// if (!otherSale || otherSale.itemsAsArray.length > 0) {
			return this.createSale();
		}

		if (!this.all.find((s) => s.id === `${this.active}`)) {
			this.active = this.all[this.all.length - 1].id;
		}
	}

	@computed
	get byUniqueId() {
		return this.available.reduce((acc, sale) => {
			acc[sale.uniqueId] = sale;
			return acc;
		}, {});
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated) {
			if (!this.all.length) {
				this.createSale();
			}

			for (const sale of this.all) {
				if (!sale.date) {
					sale.date = null;
				}
				if (sale.vatExempt) {
					sale.vatExempt = false;
				}
			}
		}
	}
}

export { LocalSales, LocalSale, LocalSaleItem };
