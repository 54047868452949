import { Trans, t } from '@lingui/macro';
import {
	Button,
	Col,
	ColProps,
	Empty,
	Form,
	FormRule,
	Grid,
	List,
	Drawer,
	Row,
	Space,
	Table,
	TableColumnType,
	TableProps,
} from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import set from 'lodash/set';
import {
	useCallback,
	createContext,
	useMemo,
	cloneElement,
	ReactNode,
	useEffect,
	useImperativeHandle,
	forwardRef,
	useState,
	isValidElement,
	Fragment,
	useRef,
} from 'react';
import { useLatest } from 'react-use';

import styles from './TableInput.module.less';
import { Title } from '../Title';

interface Column<T> {
	title: string;
	dataIndex: NamePath;
	editable?: boolean;
	width?: number | void;
	component?:
		| ReactNode
		| ((
				record: T,
				index: number,
				handleEdit: (index: number, values: Record<string, any>) => void
		  ) => ReactNode);
	colProps?: ColProps;
	rules?: FormRule[];
	render?: (text: string, record: T, index: number) => ReactNode;
	valuePropName?: string;
}

interface FormField {
	label?: string;
	key?: string | string[];
	rules?: any[]; // TODO use antd rules
	component: ReactNode;
	span?: number;
	xs?: number;
	sm?: number;
	md?: number;
	lg?: number;
	xl?: number;
	xxl?: number;
	initialValue?: unknown;
	valuePropName?: string;
	rerenderOnChange?: boolean;
	hidden?: boolean;
}

export interface FormRow {
	key: string;
	label?: string;
	fields: FormField[];
}

export interface TableInputProps<T> {
	presetItems?: boolean;
	value?: any[T];
	disabled?: boolean;
	onChange?: (value: any[T]) => void;
	isResponsive?: boolean;
	columns: Column<T>[];
	addButtonText: string;
	emptyText: string;
	iconPath: string;
	shouldShowDuplicateButton?: boolean;
	newRowValue?: T;
	tableProps?: TableProps<object>;
	id?: string;
	insertNewRow?: boolean;
	disallowLastRowDelete?: boolean;
	editInDrawer?: boolean;
	editDrawerTitle?: string | ((item: T) => string);
	formFields?:
		| FormRow[]
		| ((
				record?: any,
				form?: any,
				setFields?: (fields: any) => any
		  ) => FormRow[])
		| ReactNode;
	editDrawerWidth?: number;
	showActions?: (record) => boolean;
}

const EditableContext = createContext(null);

interface EditDrawerProps {
	editDrawerTitle: string | ((item: any) => string);
	editDrawerItem: any;
	editDrawerVisible: boolean;
	editDrawerForm: any;
	handleCloseEditDrawer: () => void;
	width?: number;
	handleEditDrawerFinish: (values: any) => void;
	fields?:
		| FormRow[]
		| ((
				record?: any,
				form?: any,
				setFields?: (fields: any) => any
		  ) => FormRow[])
		| ReactNode;
}

const EditDrawer = ({
	editDrawerTitle,
	editDrawerItem,
	editDrawerVisible,
	editDrawerForm,
	handleCloseEditDrawer,
	width,
	handleEditDrawerFinish,
	fields,
}: EditDrawerProps) => {
	const focused = useRef(false);
	const [formFields, setFormFields] = useState(null);

	const setFields = useCallback(
		(changedFields = null) => {
			if (isValidElement(fields)) {
				setFormFields(fields);
				return;
			}

			const derivedFormFields =
				typeof fields === 'function'
					? (fields as any)(editDrawerItem, editDrawerForm, setFields)
					: fields || [];
			setFormFields(derivedFormFields);
		},
		[editDrawerItem, editDrawerForm, fields]
	);

	useEffect(() => {
		if (editDrawerItem) {
			editDrawerForm.setFieldsValue(editDrawerItem);
		}
		setFields();
	}, [editDrawerForm, setFields, editDrawerItem]);
	return (
		<Drawer
			title={
				typeof editDrawerTitle === 'function'
					? editDrawerTitle(editDrawerItem)
					: editDrawerTitle
			}
			width={width}
			visible={editDrawerVisible}
			destroyOnClose
			onClose={handleCloseEditDrawer}
			footerStyle={{ textAlign: 'right' }}
			footer={
				<Space>
					<Button key="cancel" onClick={handleCloseEditDrawer}>
						<Trans>Одустани</Trans>
					</Button>
					<Button key="save" type="primary" onClick={editDrawerForm.submit}>
						<Trans>Сачувај</Trans>
					</Button>
				</Space>
			}
		>
			<Form
				form={editDrawerForm}
				onFinish={handleEditDrawerFinish}
				preserve={false}
				layout="vertical"
				onFieldsChange={setFields}
			>
				{isValidElement(formFields)
					? cloneElement(formFields, {
							editDrawerForm,
					  })
					: (formFields || []).map((row, rowIndex) => (
							<Fragment key={row.key}>
								{row.label && <Title>{row.label}</Title>}
								<Row gutter={8}>
									{row.fields.map((field, fieldIndex) => (
										<Col
											key={field.key}
											span={field.span}
											xs={field.xs}
											sm={field.sm}
											md={field.md}
											lg={field.lg}
											xl={field.xl}
											xxl={field.xxl}
											style={field.hidden && { display: 'none' }}
										>
											<Form.Item {...field} name={field.key}>
												{cloneElement(
													typeof field.component === 'function'
														? field.component(editDrawerItem)
														: field.component,
													{
														ref: (ref) => {
															setTimeout(() => {
																if (
																	ref &&
																	ref.focus &&
																	rowIndex === 0 &&
																	fieldIndex === 0 &&
																	!focused.current
																) {
																	ref.focus();
																	focused.current = true;
																}
															}, 100);
														},
													}
												)}
											</Form.Item>
										</Col>
									))}
								</Row>
							</Fragment>
					  ))}
				<input type="submit" style={{ display: 'none' }} />
			</Form>
		</Drawer>
	);
};

function BaseTableInput<T>(
	{
		presetItems = false,
		value,
		isResponsive = false,
		onChange,
		columns: columnsProp,
		addButtonText,
		shouldShowDuplicateButton = false,
		iconPath,
		emptyText,
		newRowValue,
		tableProps,
		id,
		insertNewRow = false,
		disallowLastRowDelete = false,
		editInDrawer = false,
		editDrawerTitle,
		formFields: fields,
		editDrawerWidth,
		showActions,
		disabled,
	}: TableInputProps<T>,
	ref
) {
	const screens = Grid.useBreakpoint();
	const tableRef = useRef(null);

	const currentValue = useLatest(value);

	const [editDrawerVisible, setEditDrawerVisible] = useState(false);
	const [editDrawerItem, setEditDrawerItem] = useState(null);
	const [editDrawerItemIndex, setEditDrawerItemIndex] = useState(null);
	const [editDrawerForm] = Form.useForm();

	const handleEditObject = useCallback(
		(index: number, fields: Record<string, string | number>) => {
			const newValue = (currentValue.current || []).slice();

			Object.entries(fields).forEach(([key, value]) => {
				set(newValue, `${index}.${key}`, value);
			});

			onChange(newValue);
		},
		[currentValue, onChange]
	);

	const handleDelete = useCallback(
		(index) => {
			const newValue = currentValue.current.slice();
			newValue.splice(index, 1);

			onChange(newValue);
		},
		[currentValue, onChange]
	);

	const handleAdd = useCallback(() => {
		const newValue = {};
		Object.entries(newRowValue || []).forEach(([key, value]) => {
			set(newValue, key, value);
		});
		if (editInDrawer) {
			setEditDrawerItemIndex(null);
			setEditDrawerItem(newValue);
			setEditDrawerVisible(true);
			return;
		}

		const changedValue = [...(currentValue.current || []), newValue];
		onChange(changedValue);
		setTimeout(() => {
			tableRef.current?.scrollTo({
				top: (changedValue.length - 1) * 50,
			});
		}, 100);
	}, [newRowValue, editInDrawer, currentValue, onChange]);

	const handleDuplicate = useCallback(
		(index) => {
			const newValue = currentValue.current.slice();
			newValue.splice(index, 0, currentValue.current[index]);
			onChange(newValue);
		},
		[currentValue, onChange]
	);

	const handleEditInDrawer = useCallback(
		(index) => {
			setEditDrawerItemIndex(index);
			setEditDrawerItem(currentValue.current[index]);
			setEditDrawerVisible(true);
		},
		[currentValue]
	);

	const getFieldValue = useCallback(
		(key: NamePath) => {
			if (!editDrawerItem) return undefined;
			return editDrawerForm.getFieldValue(key);
		},
		[editDrawerItem, editDrawerForm]
	);

	const getForm = useCallback(() => editDrawerForm, [editDrawerForm]);

	useImperativeHandle(ref, () => ({
		handleEditObject,
		handleDelete,
		handleAdd,
		handleDuplicate,
		handleEditInDrawer,
		getFieldValue,
		getForm,
	}));

	useEffect(() => {
		if (insertNewRow) handleAdd();
	}, [insertNewRow]);

	const EditableRow = useCallback(({ index, rowComponent, ...props }: any) => {
		const ComponentToUse = rowComponent || 'tr';
		return <ComponentToUse {...props} />;
	}, []);

	const EditableCell = useCallback((props: any) => {
		const {
			editable,
			children,
			record,
			dataIndex,
			rules,
			index,
			component,
			cellComponent,
			valuePropName,
			doNotWrap,
			value,
			...restProps
		} = props;
		const ComponentToUse = cellComponent || 'td';
		const childNode = editable ? (
			<Form.Item
				style={{ margin: 0 }}
				name={[...id.split('_'), index, dataIndex]}
				rules={rules}
				valuePropName={valuePropName}
				label={props.label}
			>
				{cloneElement(
					typeof component === 'function'
						? component(record, index, handleEditObject)
						: component,
					{ index }
				)}
			</Form.Item>
		) : (
			<div className={doNotWrap ? '' : styles.nonEditableCell}>{children}</div>
		);

		return (
			<ComponentToUse
				{...restProps}
				className={`${restProps.className} ${styles.editableCell}`}
			>
				{childNode}
			</ComponentToUse>
		);
	}, []);

	const components = useMemo(
		() => ({
			body: {
				row: EditableRow,
				cell: EditableCell,
			},
		}),
		[]
	);

	const columns = useMemo(() => {
		return (
			!presetItems
				? [
						...columnsProp,
						{
							align: 'right',
							width:
								(shouldShowDuplicateButton ? 80 : 40) + (editInDrawer ? 40 : 0),
							fixed: 'right',
							doNotWrap: true,
							render: (text: string, record: T, index: number) => {
								if (showActions && !showActions(record)) return null;
								return !screens.md && isResponsive ? (
									<Space.Compact>
										{editInDrawer && (
											<Button
												icon={<i className="fi fi-rr-pencil"></i>}
												onClick={() => handleEditInDrawer(index)}
											>
												<Trans>Измени</Trans>
											</Button>
										)}
										{shouldShowDuplicateButton && (
											<Button
												icon={<i className="fi fi-rr-duplicate"></i>}
												onClick={() => handleDuplicate(index)}
											>
												<Trans>Дуплирај</Trans>
											</Button>
										)}
										{!disallowLastRowDelete ||
										currentValue.current.length > 1 ? (
											<Button
												onClick={() => handleDelete(index)}
												icon={<i className="fi fi-rr-trash"></i>}
											>
												<Trans>Обриши</Trans>
											</Button>
										) : null}
									</Space.Compact>
								) : (
									<Space.Compact>
										{editInDrawer && (
											<Button
												icon={<i className="fi fi-rr-pencil"></i>}
												onClick={() => handleEditInDrawer(index)}
											></Button>
										)}
										{shouldShowDuplicateButton && (
											<Button
												icon={<i className="fi fi-rr-duplicate"></i>}
												onClick={() => handleDuplicate(index)}
											></Button>
										)}
										{!disallowLastRowDelete ||
										currentValue.current.length > 1 ? (
											<Button
												onClick={() => handleDelete(index)}
												icon={<i className="fi fi-rr-trash"></i>}
											></Button>
										) : null}
									</Space.Compact>
								);
							},
							colProps: {
								span: 24,
								className: styles.buttons,
							},
						},
				  ]
				: [
						...columnsProp,
						{
							width: editInDrawer ? 24 : 0,
							fixed: 'right',
							doNotWrap: true,
							render: (text: string, record: T, index: number) => {
								return !screens.md && isResponsive ? (
									<Space>
										{editInDrawer && (
											<Button
												icon={<i className="fi fi-rr-pencil"></i>}
												onClick={() => handleEditInDrawer(index)}
											>
												<Trans>Измени</Trans>
											</Button>
										)}
									</Space>
								) : (
									<Button.Group style={{ marginTop: 5 }}>
										{editInDrawer && (
											<Button
												icon={<i className="fi fi-rr-pencil"></i>}
												onClick={() => handleEditInDrawer(index)}
											></Button>
										)}
									</Button.Group>
								);
							},
							colProps: {
								span: 24,
								className: styles.buttons,
							},
						},
				  ]
		).map((column: Column<T>) => {
			return {
				...column,
				onCell: (record: T, index: number) => ({
					record,
					index,
					...column,
				}),
			};
		});
	}, [screens, columnsProp, presetItems, editInDrawer, isResponsive]);

	const handleCloseEditDrawer = useCallback(() => {
		setEditDrawerItemIndex(null);
		setEditDrawerItem(null);
		setEditDrawerVisible(false);
		editDrawerForm.resetFields();
	}, []);

	const handleEditDrawerFinish = useCallback(
		(values: any) => {
			const updatedItem = {
				...editDrawerItem,
				...(values || []),
			};
			const updatedItems = [...(value || [])];
			if (editDrawerItemIndex === null) {
				updatedItems.push(updatedItem);
			} else {
				updatedItems.splice(editDrawerItemIndex, 1, updatedItem);
			}
			onChange(updatedItems);
			handleCloseEditDrawer();
		},
		[editDrawerItem, editDrawerItemIndex]
	);

	if ((value || []).length === 0) {
		return (
			<div className={styles.container}>
				<Empty
					image={<img src={iconPath} alt="" />}
					imageStyle={{
						height: 64,
					}}
					description={emptyText}
				>
					{!presetItems ? (
						<Button type="link" onClick={handleAdd}>
							{addButtonText}
						</Button>
					) : null}
				</Empty>
				{editInDrawer && (
					<EditDrawer
						editDrawerVisible={editDrawerVisible}
						editDrawerTitle={editDrawerTitle}
						editDrawerItem={editDrawerItem}
						editDrawerForm={editDrawerForm}
						handleCloseEditDrawer={handleCloseEditDrawer}
						width={editDrawerWidth}
						fields={fields}
						handleEditDrawerFinish={handleEditDrawerFinish}
					/>
				)}
			</div>
		);
	}

	return (
		<>
			{isResponsive && (
				<div
					className={styles.container}
					style={{ display: !screens.md && isResponsive ? 'block' : 'none' }}
				>
					<List
						footer={
							!presetItems ? (
								<div className={styles.footer}>
									<Button onClick={handleAdd}>{addButtonText}</Button>
								</div>
							) : null
						}
						renderItem={(record: T, index: number) => {
							return (
								<EditableRow
									rowComponent="div"
									index={index}
									className={styles.item}
								>
									<Row gutter={[8, 16]}>
										{columns.map((column) => {
											return (
												<Col {...column.colProps}>
													<EditableCell
														{...column}
														label={column.title}
														cellComponent="div"
														record={record}
														index={index}
													>
														{column.render
															? column.render(
																	record[column.dataIndex],
																	record,
																	index
															  )
															: record[column.dataIndex]}
													</EditableCell>
												</Col>
											);
										})}
									</Row>
								</EditableRow>
							);
						}}
						dataSource={value}
					/>
				</div>
			)}
			<div
				className={styles.container}
				style={{
					display:
						(screens.md && isResponsive) || !isResponsive ? 'block' : 'none',
				}}
			>
				<Table
					components={components}
					pagination={false}
					dataSource={value}
					size="small"
					footer={
						!presetItems
							? () => <Button onClick={handleAdd}>{addButtonText}</Button>
							: undefined
					}
					columns={columns as TableColumnType<object>[]}
					className={styles.table}
					onRow={(record: object, index: number): any => ({
						index,
					})}
					ref={tableRef}
					{...tableProps}
				/>

				{editInDrawer && (
					<EditDrawer
						editDrawerVisible={editDrawerVisible}
						editDrawerTitle={editDrawerTitle}
						editDrawerItem={editDrawerItem}
						editDrawerForm={editDrawerForm}
						handleCloseEditDrawer={handleCloseEditDrawer}
						width={editDrawerWidth}
						fields={fields}
						handleEditDrawerFinish={handleEditDrawerFinish}
					/>
				)}
			</div>
		</>
	);
}

export const TableInput = forwardRef(BaseTableInput);
