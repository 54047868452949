import camelCase from 'lodash/camelCase';

import { ApiKeys } from './ApiKey.mobx';
import { Application } from './Application.mobx';
import { Calculations } from './Calculation.mobx';
import { Categories } from './Category.mobx';
import { Company } from './Company.mobx';
import { Currencies } from './Currency.mobx';
import { DailyReceipts } from './DailyReceipts.mobx';
import { Devices } from './Device.mobx';
import { Discounts } from './Discount.mobx';
import { ExchangeRates } from './ExchangeRate.mobx';
import { InitialStocks } from './InitialStock.mobx';
import { Invoices } from './Invoice.mobx';
import { Kpos } from './Kpo.mobx';
import { LocalSales } from './LocalSale.mobx';
import { MarketplaceApplications } from './MarketplaceApplication.mobx';
import { MarketplaceCategories } from './MarketplaceCategory.mobx';
import { migrate } from './migrate';
import { MyMarketplaceApplications } from './MyMarketplaceApplication.mobx';
import { Partners } from './Partner.mobx';
import { Pricechanges } from './Pricechange.mobx';
import { Products } from './Product.mobx';
import { Receipts } from './Receipt.mobx';
import { Report } from './Report.mobx';
import { Roles } from './Role.mobx';
import { SaleChannels } from './SaleChannel.mobx';
import { Sdc } from './SDC.mobx';
import { Session } from './Session.mobx';
import { Socket } from './Socket.mobx';
import { Stocks } from './Stock.mobx';
import { StockTurnovers } from './StockTurnover.mobx';
import { Stores } from './Store.mobx';
import { TaxRates } from './TaxRate.mobx';
import { Units } from './Unit.mobx';
import { Users } from './User.mobx';
import { Warehouses } from './Warehouse.mobx';
import { Webhooks } from './Webhook.mobx';
import { MojBadi } from './MojBadi.mobx';
import { LocalApi } from './LocalApi.mobx';
const AllStores = {
	ApiKeys,
	Application,
	Categories,
	Company,
	Currencies,
	DailyReceipts,
	Devices,
	Discounts,
	Partners,
	ExchangeRates,
	LocalSales,
	Products,
	Receipts,
	Report,
	Roles,
	Sdc,
	Socket,
	Stores,
	TaxRates,
	Units,
	Users,
	Warehouses,
	Webhooks,
	Invoices,
	MarketplaceCategories,
	MarketplaceApplications,
	MyMarketplaceApplications,
	Kpos,
	SaleChannels,
	StockTurnovers,
	InitialStocks,
	Calculations,
	Pricechanges,
	Stocks,
	MojBadi,
	LocalApi,
};

const AllStoresWithSession = {
	...AllStores,
	Session,
};

const instances = {};

const session = new Session();

let total = 0;
let current = 0;

export async function hydrate() {
	instances['application'].setIsInitialized(false);
	instances['application'].setLoadingProgress(0);
	if (session.currentCompanyId) {
		for (const store in instances) {
			if (instances[store].hydrate) {
				await instances[store].hydrate(
					session.currentCompanyId,
					instances[store]
				);
			}
		}
		if (instances['users'].token) {
			instances['users'].setToken(instances['users'].token);
			instances['users'].setAuthenticatedUserId(
				instances['users'].authenticatedUserId
			);
			await instances['users'].checkUser();

			await afterAuth(true, session.currentCompanyId);
		} else {
			await afterAuth(false, undefined);
		}
	}
	const converted = await migrate();
	if (!converted) {
		instances['application'].setIsInitialized(true);
	}
}

export function instantiate() {
	for (const store in AllStores) {
		instances[camelCase(store)] = new AllStores[store]();
	}

	instances['session'] = session;
}

export function setCurrentCompanyId(companyId: string) {
	session.setCurrentCompanyId(companyId);
}

export async function afterAuth(authenticated: boolean, companyId: string) {
	const instancesWithAfterAuth = Object.values(instances).filter(
		(instance) => (instance as any).afterAuth
	);
	total = instancesWithAfterAuth.length;
	current = 0;
	instances['application'].setLoadingProgress(0);

	await Promise.all(
		instancesWithAfterAuth.map(async (instance) => {
			try {
				await (instance as any).afterAuth(authenticated, companyId);
			} catch (e) {
				//
			}
			current++;
			instances['application'].setLoadingProgress(
				Math.round((current / total) * 100)
			);
		})
	);
}

export async function init() {
	instantiate();
	await session.init();
	hydrate();
}

init();

type CamelCase<S extends string> = S extends `${infer P1}${infer P2}`
	? `${Lowercase<P1>}${P2}`
	: S;

type KeysToCamelCase<T> = {
	[K in keyof T as CamelCase<string & K>]: T[K] extends abstract new (
		...args: any
	) => any
		? InstanceType<T[K]>
		: never;
};

export default instances as KeysToCamelCase<typeof AllStoresWithSession>;
