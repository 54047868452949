import { t, Trans } from '@lingui/macro';
import { Button, Empty, Modal, Upload } from 'antd';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useRef, useState } from 'react';

import styles from './PhotosEdit.module.less';
import { v2Client } from '../../store/client';
import DisabledContext from 'antd/es/config-provider/DisabledContext';

interface Props {
	onChange?: (files: any) => void;
	value?: any;
	resource?: string;
}

function PhotosEdit({ onChange, value, resource = 'productImage' }: Props) {
	const disabled = useContext(DisabledContext);
	const uploadRef = useRef(null);

	const [fileList, setFileList] = useState([]);
	const [preview, setPreview] = useState({
		visible: false,
		image: '',
	});

	const handleChange = ({ fileList: newFileList }) => {
		setFileList(newFileList);
		onChange(newFileList);
	};

	const handlePreview = (file) => {
		setPreview({
			visible: true,
			image: file.response.urls['1024x1024'],
		});
	};

	const handleCancel = () => {
		setPreview({
			visible: false,
			image: '',
		});
	};

	useEffect(() => {
		if (value) {
			setFileList(
				value.map((file) =>
					file.uid
						? file
						: {
								uid: file.id,
								name: file.filename,
								status: 'done',
								url: file.urls['1024x1024'],
								response: file,
						  }
				)
			);
		}
	}, [value]);

	return (
		<>
			<Upload
				ref={uploadRef}
				// key={uuid()}
				listType="picture-card"
				className={`${styles.container} ${
					fileList.length === 0 ? styles.empty : ''
				}`}
				headers={{
					'x-access-token': v2Client.defaults.headers.common['x-access-token'],
				}}
				action={`${v2Client.defaults.baseURL}/files/${resource}`}
				multiple
				onChange={handleChange}
				onPreview={handlePreview}
				fileList={fileList}
				disabled={disabled}
			>
				<Upload.Dragger
					showUploadList={false}
					headers={{
						'x-access-token':
							v2Client.defaults.headers.common['x-access-token'],
					}}
					action={`${v2Client.defaults.baseURL}/files/${resource}`}
					multiple
					onChange={handleChange}
					className={`${styles.uploadButton}`}
					openFileDialogOnClick={false}
					disabled={disabled}
				>
					{fileList.length === 0 && (
						<Empty
							image="/images/icons/new/photo-camera.svg"
							imageStyle={{
								height: 64,
							}}
							className={styles.empty}
							description={
								<Trans>
									Нема фотографија, превуци фотографије или
									<br />
									кликни овде да додаш фотографије.
								</Trans>
							}
						>
							<Button type="link">
								<Trans>Додај фотографије</Trans>
							</Button>
						</Empty>
					)}
					{fileList.length > 0 && (
						<div className={styles.uploadButtonInner}>
							<img src="/images/icons/new/cloud-computing.svg" alt="" />
							<div>
								<Trans>Отпреми</Trans>
							</div>
						</div>
					)}
				</Upload.Dragger>
			</Upload>
			<Modal
				centered
				open={preview.visible}
				title={t`Фотографија`}
				footer={null}
				onCancel={handleCancel}
			>
				{preview.image !== '' && (
					<img alt="" style={{ width: '100%' }} src={preview.image} />
				)}
			</Modal>
		</>
	);
}

export default observer(PhotosEdit);
