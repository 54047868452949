import { observable, action, flow, runInAction } from 'mobx';

import { CreateStore } from './Crud.mobx';
import stores from './index.mobx';
import { BankAccount } from './shared/BankAccount';
import type { File } from './shared/File.mobx';
import { ArrayTypeTransformer } from './transformers/ArrayType';
import { AVAILABLE_FEATURES } from '../constants/application';
const { Entity } = CreateStore({
	type: 'entity',
	name: 'company',
	paginated: false,
	persistFields: ['*'],
	persistDelay: 0,
});

type TaxExemption = {
	category: string;
	code: string;
	decisionNumber: string;
};

class Company extends Entity {
	@observable name?: string;
	@observable phone?: string;
	@observable address?: string;
	@observable city?: string;
	@observable zip?: string;
	@observable email?: string;
	@observable website?: string;
	@observable tin?: string;
	@observable companyRegistrationNumber?: string;
	@observable active?: boolean = true;
	@observable vat?: boolean;
	@observable defaultTaxRateLabel?: string;
	@observable logoFileId?: string;
	@observable logoFile?: File;
	@observable package:
		| 'lite'
		| 'pos'
		| 'posplus'
		| 'erp'
		| 'basic'
		| 'standard'
		| 'professional'
		| 'premium' = 'basic';
	@observable allowCurrencyConversion?: boolean = false;
	@observable preferredCurrencies?: string[] = [];
	@observable taxFreeMode: 'refund' | 'exempt' | null = 'refund';
	@observable availablePaymentMethods: 'all' | 'reduced' | null = 'all';
	@observable invoiceNumberingPrefix?: string = 'FA/';
	@observable advanceInvoiceNumberingPrefix?: string = 'AR/';
	@observable creditNoteNumberingPrefix?: string = 'KO/';
	@observable debitNoteNumberingPrefix?: string = 'KZ/';
	@observable invoiceNumbering?: string = '#/YYYY';
	@observable invoiceNumberingStart?: number = 1;
	@observable invoicePrintSignature?: boolean = false;
	@observable searchProductsByDescription = false;
	@observable gln?: string;
	@observable identityId?: string;
	@observable defaultTaxExemption?: TaxExemption[] = [];
	@observable taxPeriod?: 1 | 3;
	@observable classification?: string;
	@observable flatRateTax?: boolean;
	@observable defaultDueDate?: number;
	@observable hasKpoEntries?: boolean;
	@observable printAd?: boolean;
	@observable productListType?: 'parents' | 'variants' = 'parents';

	@ArrayTypeTransformer(BankAccount)
	@observable.deep
	bankAccounts?: BankAccount[] = [];

	@observable isCreating = false;

	constructor() {
		super();
		this.init();
	}

	@action.bound
	hasFeature(feature: string) {
		if (!AVAILABLE_FEATURES[this.package]) {
			return false;
		}
		return AVAILABLE_FEATURES[this.package].includes(feature);
	}

	@flow.bound
	*fetch(companyId: string) {
		this.isFetching = true;
		try {
			const { data } = yield this.getClient().get(`/companies/${companyId}`);
			this.replace(data);

			this.isFetching = false;
		} catch (error) {
			console.error(error);
			this.isFetching = false;
		}
	}

	@flow.bound
	*create({
		tin,
		adminFirstName,
		adminLastName,
		adminEmail,
		adminPassword,
		vat,
		selectedPackage,
		name,
		address,
		companyRegistrationNumber,
		availablePaymentMethods,
	}) {
		try {
			this.isCreating = true;
			yield this.getClient().post('/companies', {
				tin,
				adminFirstName,
				adminLastName,
				adminEmail,
				adminPassword,
				vat,
				package: selectedPackage,
				name,
				address,
				companyRegistrationNumber,
				availablePaymentMethods,
			});
			stores.application.setIsRegistrationFinished(true);
			yield stores.users.login(adminEmail, adminPassword);
		} catch (error) {
			throw error;
		} finally {
			stores.products.fetchAll();
			this.isCreating = false;
		}
	}

	async afterAuth(authenticated: boolean, companyId: string) {
		if (authenticated) {
			await this.fetch(companyId);
		} else {
			runInAction(() => {
				this.isCreating = false;
				this.isUpdating = false;
				this.isFetching = false;
			});
		}
	}
}

export { Company };
