import { t } from '@lingui/macro';
import { Modal, InputNumber, Form, DatePicker } from 'antd';
import dayjs from 'dayjs';
import round from 'lodash/round';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import stores from '../../../../../../stores/index.mobx';

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 24 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 24 },
	},
};

interface Props {
	visible: boolean;
	close: () => void;
	id?: string;
}

function PayModal({ visible, close, id }: Props) {
	const { invoices } = stores;

	const invoice = useMemo(() => (id ? invoices.getById(id) : null), [id]);

	const [value, setValue] = useState(
		round((invoice?.amount || 0) - (invoice?.paidAmount || 0), 2)
	);
	const [form] = Form.useForm();
	const isFocused = useRef(false);

	useEffect(() => {
		if (!visible) {
			isFocused.current = false;
		}
	}, [visible]);

	const submit = useCallback(() => {
		form.validateFields().then(async (values) => {
			const { amount, date } = values;
			if (amount) {
				await invoice.pay(round(amount, 2), date);
			}
			close();
		});
	}, [form, invoice, close]);

	return (
		<Modal
			centered
			width={300}
			title={t`Унос уплате`}
			open={visible}
			destroyOnClose
			onOk={() => {
				submit();
			}}
			onCancel={() => {
				close();
			}}
		>
			{visible && (
				<>
					<Form
						{...formItemLayout}
						form={form}
						layout="vertical"
						preserve={false}
						initialValues={{
							date: dayjs(),
							amount: round(
								(invoice?.payableAmount || 0) - (invoice?.paidAmount || 0),
								2
							),
						}}
						onValuesChange={(changedValues, allValues) => {
							setValue(allValues.amount);
						}}
					>
						<Form.Item
							label={t`Уплаћен износ`}
							required
							initialValue={round(
								invoice.payableAmount - invoice.paidAmount,
								2
							)}
							name={'amount'}
							rules={[
								{
									type: 'number',
									max: round(invoice.payableAmount - invoice.paidAmount, 2),
									message: t`Уплаћени износ може бити максимално преостали износ за плаћање (${round(
										invoice.payableAmount - invoice.paidAmount,
										2
									)})`,
								},
								{
									required: true,
									message: t`Обавезан је унос износа`,
								},
							]}
						>
							<InputNumber
								style={{ width: '100%' }}
								ref={(input) => {
									if (!isFocused.current && input && visible) {
										isFocused.current = true;
										setTimeout(() => {
											(input as any)?.select?.();
										});
									}
								}}
								onPressEnter={() => {
									submit();
								}}
							/>
						</Form.Item>
						<Form.Item label={t`Датум плаћања`} name={'date'}>
							<DatePicker style={{ width: '100%' }} format="L" />
						</Form.Item>
					</Form>
				</>
			)}
		</Modal>
	);
}

export default observer(PayModal);
