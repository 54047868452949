import { t, Trans } from '@lingui/macro';
import { Button, Empty, Table } from 'antd';
import update from 'immutability-helper';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import VariantEditDrawer from './Components/VariantEditDrawer';
import styles from './VariantsEdit.module.less';
import numberFormat from '../../../../../../lib/numberFormat';
import { Product } from '../../../../../../stores/Product.mobx';

const type = 'DraggableBodyRow';

interface Variant {
	id?: string;
	variantName?: string;
	sku?: string;
	manufacturerSku?: string;
	ean?: string;
	price?: number;
	trackStock?: boolean;
	baseUnitId?: string;
	quantityPerBaseUnit?: number;
	saleUnitId?: string;
	weight?: number;
	dimensions?: string;
}

interface Props {
	product?: Product;
	value?: Variant[];
	onChange?: (any) => void;
	productType: string;
}

const DraggableBodyRow = ({
	index,
	moveRow,
	className,
	style,
	...restProps
}) => {
	const ref = useRef();
	const [{ isOver, dropClassName }, drop] = useDrop({
		accept: type,
		collect: (monitor) => {
			const { index: dragIndex } = monitor.getItem() || {};
			// if (dragIndex === index) {
			// 	return {};
			// }
			return {
				isOver: monitor.isOver(),
				dropClassName: ` ${
					dragIndex < index
						? styles['drop-over-downward']
						: styles['drop-over-upward']
				}`,
			};
		},
		drop: (item) => {
			moveRow(item.index, index);
		},
	});
	const [, drag] = useDrag({
		type,
		item: { index },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});
	drop(drag(ref));

	return (
		<tr
			ref={ref}
			className={`${className} ${isOver ? dropClassName : ''}`}
			style={{ cursor: 'move', ...style }}
			{...restProps}
		/>
	);
};

export default function VariantsEdit({
	value = [],
	onChange = () => null,
	productType,
}: Props) {
	const [variants, setVariants] = useState(value);
	const [
		[editVariantVisible, editVariantEntity, editVariantIndex],
		setEditVariant,
	] = useState([false, null, null]);

	useEffect(() => {
		setVariants(value);
	}, [value]);

	const addVariant = useCallback(() => {
		setEditVariant([true, null, null]);
	}, []);

	function deleteVariant(index) {
		const newVariants = [...variants];
		newVariants.splice(index, 1);
		setVariants(newVariants);
		onChange(newVariants);
	}

	const components = {
		body: {
			row: DraggableBodyRow,
		},
	};

	const moveRow = useCallback(
		(dragIndex, hoverIndex) => {
			const dragRow = variants[dragIndex];
			const newVariants = update(variants, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, dragRow],
				],
			});
			setVariants(newVariants);

			onChange(newVariants);
		},
		[variants]
	);

	const save = useCallback(
		(variant) => {
			const newVariants = [...variants];
			if (editVariantIndex !== null) {
				newVariants[editVariantIndex] = {
					...newVariants[editVariantIndex],
					...variant,
				};
			} else {
				newVariants.push({
					...variant,
					sku: variant.sku ? variant.sku : '',
				});
			}
			setVariants(newVariants);
			onChange(newVariants);
			setEditVariant([false, null, null]);
		},
		[editVariantIndex, variants]
	);

	if (variants.length === 0) {
		return (
			<div className={styles.container}>
				<Empty
					image={
						<img src="/images/icons/new/color-palette.svg" alt="Varijante" />
					}
					imageStyle={{
						height: 64,
					}}
					description={t`Нема варијанти`}
				>
					<Button type="link" onClick={addVariant}>
						<Trans>Додај варијанту</Trans>
					</Button>
				</Empty>
				<VariantEditDrawer
					visible={editVariantVisible}
					entity={editVariantEntity}
					save={save}
					productType={productType}
					close={() => setEditVariant([false, null, null])}
				/>
			</div>
		);
	}

	return (
		<DndProvider backend={HTML5Backend}>
			<div className={styles.container}>
				<Table
					pagination={false}
					size="small"
					rowKey="id"
					dataSource={variants}
					footer={() => (
						<Button onClick={addVariant}>
							<Trans>Додај варијанту</Trans>
						</Button>
					)}
					components={components}
					onRow={(record, index) => ({
						index,
						moveRow,
					})}
					scroll={{ x: 690 }}
				>
					<Table.Column
						title=""
						width={46}
						fixed="left"
						render={() => (
							<i
								className="fi fi-rr-menu-burger"
								style={{ cursor: 'grab', color: '#999' }}
							/>
						)}
					/>
					<Table.Column
						title={t`Назив варијанте`}
						dataIndex="variantName"
						key="variantName"
					/>
					<Table.Column
						title={t`Шифра`}
						dataIndex="sku"
						key="sku"
						width={100}
					/>
					{productType === 'product' && (
						<Table.Column
							title={t`Бар код (ГТИН)`}
							dataIndex="ean"
							key="ean"
							width={150}
						/>
					)}
					<Table.Column
						title={t`Цена`}
						dataIndex="price"
						key="price"
						width={180}
						render={(text, record) =>
							`${numberFormat(
								text,
								true,
								2,
								true,
								(record as Product).currencyId || 'RSD'
							)}`
						}
					/>
					<Table.Column
						width={80}
						fixed="right"
						align="right"
						render={(text, record, index) => (
							<Button.Group>
								<Button
									icon={<i className="fi fi-rr-pencil"></i>}
									onClick={() => {
										setEditVariant([true, record, index]);
									}}
								/>
								<Button
									icon={<i className="fi fi-rr-trash"></i>}
									onClick={() => deleteVariant(index)}
								/>
							</Button.Group>
						)}
					/>
				</Table>
			</div>
			<VariantEditDrawer
				visible={editVariantVisible}
				entity={editVariantEntity}
				save={save}
				productType={productType}
				close={() => setEditVariant([false, null, null])}
			/>
		</DndProvider>
	);
}
