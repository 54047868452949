import { Trans, t } from '@lingui/macro';
import {
	Button,
	Checkbox,
	Col,
	Form,
	FormInstance,
	Input,
	notification,
	Row,
	Spin,
	Select,
} from 'antd';
import gs1 from 'gs1-key';
import { useCallback, useState } from 'react';

import BankAccountsInput from '../../../../../components/BankAccountsInput';
import { StaticComponents } from '../../../../../components/StaticComponents';
import { Title } from '../../../../../components/Title';
import { getBudgetUserType } from '../../../../../constants/documentInvoice';
import { v2Client } from '../../../../../store/client';
import ContactsInput from '../ContactsInput';
import LocationsInput from '../LocationsInput';
import { NameInput } from '../NameInput';

interface Props {
	form?: FormInstance;
}

export function PartnerForm({ form }: Props) {
	const BUDGET_USER_TYPE = getBudgetUserType();

	const [loading, setLoading] = useState(false);
	const tin = Form.useWatch('tin', form);
	const mb = Form.useWatch('companyRegistrationNumber', form);
	const name = Form.useWatch('name', form);

	const address = Form.useWatch('address', form);
	const city = Form.useWatch('city', form);
	const country = Form.useWatch('country', form);

	const loadCompanyInfo = useCallback(async () => {
		if (tin || mb) {
			setLoading(true);
		}

		let data;

		try {
			if (tin) {
				const { data: responseData } = await v2Client.get(
					`/partners/info/by-tin/${tin}`
				);
				data = responseData;
			} else if (mb) {
				const { data: responseData } = await v2Client.get(
					`/partners/info/by-mb/${mb}`
				);
				data = responseData;
			}
		} catch (error) {
			StaticComponents.notification.error({
				message: t`Грешка`,
				description: t`Неуспешно преузимање информација о партнеру са јавно доступних сервиса. Покушајте поново или ручно унесите податке.`,
			});
		}
		if (data) {
			if (typeof data.vat === 'undefined') {
				StaticComponents.notification.warning({
					message: t`Упозорење`,
					description: t`Неуспело преузимање информације о ПДВ статусу обвезника.`,
				});
			}
			form.setFieldsValue({
				name: data.name,
				address: data.address,
				city: data.place,
				country: t`Република Србија`,
				companyRegistrationNumber: data.mb,
				tin: data.pib,
				bankAccounts: data.bank.map((bank, index) => ({
					bankId: bank.account.split('-')[0],
					number: bank.account.replace(/-/g, ''),
					primary: index === 0,
				})),
				vat: data.vat,
				zip: data.zip,
				budgetUserType: data.budgetUserType,
			});
		}

		setLoading(false);
	}, [tin, mb, name]);

	return (
		<Spin spinning={loading}>
			<Title>
				<Trans>Основне информације</Trans>
			</Title>
			<Row gutter={8}>
				<Col span={24}>
					<Form.Item
						label={t`Назив`}
						name="name"
						rules={[{ required: true, message: t`Назив је обавезан` }]}
						extra={t`Уносом дела назива, ПИБ-а или матичног броја, биће вам понуђени подаци о партнеру са јавно доступних сервиса. Уколико партнер не може бити пронађен, подаци морају бити ручно унети.`}
					>
						<NameInput form={form} setLoading={setLoading} />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={8}>
				<Col xs={24} sm={12} md={6}>
					<Form.Item label={t`ПИБ`} name="tin">
						<Input onBlur={loadCompanyInfo} />
					</Form.Item>
				</Col>
				<Col xs={24} sm={12} md={6}>
					<Form.Item label={t`Матични број`} name="companyRegistrationNumber">
						<Input onBlur={loadCompanyInfo} />
					</Form.Item>
				</Col>
				<Col xs={24} sm={12} md={6}>
					<Form.Item label={t`ЈБКЈС`} name="jbkjs">
						<Input />
					</Form.Item>
				</Col>
				<Col xs={24} sm={12} md={6}>
					<Form.Item label={t`Шифра партнера`} name="code">
						<Input />
					</Form.Item>
				</Col>
				{form.getFieldValue('jbkjs') && (
					<Col span={24}>
						<Form.Item
							name="budgetUserType"
							label={t`Тип корисника јавних средстава`}
						>
							<Select>
								{Object.entries(BUDGET_USER_TYPE).map(([key, value]) => (
									<Select.Option key={key} value={key}>
										{key} - {value}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Col>
				)}
				<Col span={24}>
					<Form.Item name="vat" valuePropName="checked">
						<Checkbox>
							<Trans>У систему ПДВ-а</Trans>
						</Checkbox>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={8}>
				<Col span={24}>
					<Form.Item
						label={
							<>
								<Trans>Адреса</Trans>&nbsp;&nbsp;
								{address && city && (
									<Button
										type="link"
										size="small"
										href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
											`${address || ''} ${city || ''} ${country || ''}`
										)}`}
										target="_blank"
									>
										<Trans>Прикажи на мапи</Trans>
									</Button>
								)}
							</>
						}
						name="address"
					>
						<Input />
					</Form.Item>
				</Col>
				<Col xs={24} sm={8}>
					<Form.Item label={t`Поштански број`} name="zip">
						<Input />
					</Form.Item>
				</Col>
				<Col xs={24} sm={8}>
					<Form.Item label={t`Град`} name="city">
						<Input />
					</Form.Item>
				</Col>
				<Col xs={24} sm={8}>
					<Form.Item label={t`Држава`} name="country">
						<Input />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={8}>
				<Col xs={24} sm={12}>
					<Form.Item
						label={t`Адреса електронске поште`}
						name="email"
						rules={[
							{
								type: 'email',
								message: t`Адреса електронске поште није исправна`,
							},
						]}
					>
						<Input />
					</Form.Item>
				</Col>
				<Col xs={24} sm={12}>
					<Form.Item label={t`Веб сајт`} name="website">
						<Input />
					</Form.Item>
				</Col>
			</Row>
			<Title>
				<Trans>Контакти</Trans>
			</Title>
			<Row gutter={8}>
				<Col span={24}>
					<Form.Item name="phones">
						<ContactsInput />
					</Form.Item>
				</Col>
			</Row>
			<Title>
				<Trans>Текући рачуни</Trans>
			</Title>
			<Row gutter={8}>
				<Col span={24}>
					<Form.Item name="bankAccounts">
						<BankAccountsInput />
					</Form.Item>
				</Col>
			</Row>
			<Title>
				<Trans>Идентификација</Trans>
			</Title>
			<Row gutter={8}>
				<Col xs={24} sm={8}>
					<Form.Item label={t`Шифра објекта седишта`} name="internalCode">
						<Input />
					</Form.Item>
				</Col>
				<Col xs={24} sm={10}>
					<Form.Item
						label={t`Интерни број за рутирање седишта`}
						name="internalRoutingNumber"
					>
						<Input />
					</Form.Item>
				</Col>
				<Col xs={24} sm={6}>
					<Form.Item
						label={t`ГЛН седишта`}
						name="gln"
						rules={[
							{
								validator: (_, value) => {
									if (!value || value.length === 0) {
										return Promise.resolve();
									}

									if (!gs1.validate(value)) {
										return Promise.reject();
									}
									return Promise.resolve();
								},
								message: t`ГЛН није исправан`,
							},
						]}
					>
						<Input />
					</Form.Item>
				</Col>
			</Row>
			<Title>
				<Trans>Издвојене локације</Trans>
			</Title>
			<Row gutter={8}>
				<Col span={24}>
					<Form.Item name="locations">
						<LocationsInput />
					</Form.Item>
				</Col>
			</Row>
			<Title>
				<Trans>Остало</Trans>
			</Title>
			<Row gutter={8}>
				<Col span={24}>
					<Form.Item label={t`Напомена`} name="notes">
						<Input.TextArea />
					</Form.Item>
				</Col>
			</Row>
		</Spin>
	);
}
