import lowerFirst from 'lodash/lowerFirst';
import pluralize from 'pluralize';

import Stores from '../index.mobx';

import { Transform } from '.';

export class LoadingReference {}

export function proxify(obj) {
	return new Proxy(obj, {
		get: function (target, prop) {
			if (prop === 'isResolving') {
				return true;
			}
			return proxify(obj);
		},
	});
}

export function ReferenceTransformer(
	storeName?: string,
	idProperty?: string
): (target: any, propertyName: string) => void;
export function ReferenceTransformer(target: any, propertyName: string): void;
export function ReferenceTransformer(
	storeName?: string | any,
	idProperty?: string
) {
	if (typeof storeName !== 'string') {
		const type = Reflect.getMetadata('design:type', storeName, idProperty);
		return Decorator(lowerFirst(type.name))(storeName, idProperty);
	}

	return Decorator(storeName, idProperty);
}

function Decorator(storeName?: string | any, idProperty?: string) {
	const pluralStoreName = pluralize(storeName);
	return function (target: any, propertyName: string): void {
		return Transform(function (value) {
			if (Array.isArray(value)) {
				return value
					.map((id) => {
						if (Stores[pluralStoreName]?.byId?.[id]) {
							if (Stores[pluralStoreName]?.byId?.[id].deletedAt) {
								return null;
							}
							return Stores[pluralStoreName].byId[id];
						}

						return proxify(new LoadingReference());
					})
					.filter((v) => v);
			}
			idProperty = idProperty || `${propertyName}Id`;

			if (!this[idProperty]) {
				return null;
			}

			if (Stores[pluralStoreName]?.byId?.[this[idProperty]]) {
				if (Stores[pluralStoreName]?.byId?.[this[idProperty]].deletedAt) {
					return null;
				}
				return Stores[pluralStoreName].byId[this[idProperty]];
			}
			if (Stores[pluralStoreName].isFetching) {
				return proxify(new LoadingReference());
			}

			return null;
		})(target, propertyName);
	};
}
