import { t } from '@lingui/macro';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import round from 'lodash/round';
import sortBy from 'lodash/sortBy';
import { action, computed, flow, observable } from 'mobx';

import { Category } from './Category.mobx';
import { CreateStore } from './Crud.mobx';
import stores from './index.mobx';
import type { File } from './shared/File.mobx';
import { ArrayTypeTransformer } from './transformers/ArrayType';
import { ReferenceTransformer } from './transformers/Reference';
import { Unit } from './Unit.mobx';
import { StaticComponents } from '../components/StaticComponents';
import UpdatedProductsComponent from '../components/UpdatedProducts';

const { Store, Entity } = CreateStore({
	name: 'product',
	paginated: false,
	persistFields: ['all'],
	searchFields: [
		'sku',
		'name',
		'ean',
		'descriptionPlain',
		'variants.sku',
		'variants.variantName',
		'variants.ean',
		'manufacturerSku',
		'variants.manufacturerSku',
	],
	// renameFields: {
	// 	categories: 'categoryIds',
	// },
});

export class ProductSaleChannel extends Entity {
	@observable saleChannelId: string;
	@observable storeId: string;
	@observable name: string;
	@observable description: string;
	@observable salePrice: number;
	@observable saleQuantity: number;
	@observable externalId: string;
	@observable metadata: any;
	@observable enabled: boolean;
	@observable trackStock: boolean;
	@observable minimumStock: number;
	@observable status?: string;
	@observable productId?: string;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	@flow.bound
	setAvailability = flow(function* (enabled: boolean) {
		this.isUpdating = true;
		const oldEnabled = this.enabled;
		this.enabled = enabled;
		try {
			yield this.getParent()
				.getParent()
				.getClient()
				.patch(
					`/products/${this.getParent().id}/availability/${
						this.saleChannelId
					}/${enabled ? 'true' : 'false'}`,
					{}
				);
		} catch (error) {
			this.enabled = oldEnabled;
			const saleChannel = stores.saleChannels.byId[this.saleChannelId];
			StaticComponents.notification.error({
				message: t`Грешка`,
				description: t`Грешка приликом ажурирања доступности на каналу продаје ${saleChannel.name}`,
			});
		} finally {
			this.isUpdating = false;
		}
	});
}

class Product extends Entity {
	@observable productType?: string;
	@observable name?: string;
	@observable subtitle?: string;
	@observable taxRateLabel?: string;
	@observable description?: string;
	@observable descriptionPlain?: string;
	@observable parentId?: string;
	@observable images?: File[];
	@observable coverImage?: File;
	@observable variantName?: string;
	@observable sku?: number;
	@observable manufacturerSku?: string;
	@observable ean?: string;
	@observable price?: number;
	@observable prices?: Record<string, number>;
	@observable currencyId?: string;
	@observable baseUnitId?: string;
	@observable quantityPerBaseUnit?: number;
	@observable saleUnitId?: string;
	@observable quantityFromScale = false;
	@observable hasVariants? = false;
	@observable multiplePerReceipt? = false;
	@observable weight?: number;
	@observable dimensions?: string;

	@ArrayTypeTransformer(() => ProductSaleChannel)
	@observable
	saleChannels?: ProductSaleChannel[] = [];

	@ReferenceTransformer('product', 'parentId') parent?: Product;
	@ReferenceTransformer('unit', 'baseUnitId') baseUnit?: Unit;
	@ReferenceTransformer('unit', 'saleUnitId') saleUnit?: Unit;
	@ReferenceTransformer('category') categories?: Category[] = [];

	@ArrayTypeTransformer(() => Product)
	@observable
	variants?: Product[] = [];

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	getPriceForWarehouse(warehouseId: string) {
		return this.prices?.[warehouseId] || this.price;
	}

	getPriceForStore(storeId?: string) {
		const store = stores.stores.byId[storeId];
		return this.getPriceForWarehouse(store?.warehouseId);
	}

	@computed
	get saleChannelIds() {
		return this.saleChannels.map((sc) => sc.saleChannelId);
	}

	priceBySaleChannel(saleChannelId: string) {
		const saleChannel = this.saleChannels.find(
			(sc) => sc.saleChannelId === saleChannelId
		);
		if (saleChannel) {
			return saleChannel.salePrice || this.currentStorePrice;
		}
		return this.currentStorePrice;
	}

	@computed
	get currentStorePrice() {
		return this.getPriceForStore(stores.stores.currentStoreId);
	}

	@computed
	get minPricedVariant() {
		if (this.variants) {
			return minBy(this.variants, (variant) => variant.priceInRSD);
		}

		return this;
	}

	@computed
	get maxPricedVariant() {
		if (this.variants) {
			return maxBy(this.variants, (variant) => variant.priceInRSD);
		}

		return this;
	}

	@computed
	get priceInRSD() {
		const { exchangeRates } = stores;

		if (!this.currencyId || this.currencyId === 'RSD') {
			return this.currentStorePrice;
		}
		const lastRates = exchangeRates.lastRates;
		const exchangeRate = lastRates[this.currencyId].rate;
		return round(this.currentStorePrice * exchangeRate, 2);
	}

	@computed
	get priceWithoutTax() {
		const { taxRates } = stores;
		const vatRate = taxRates.byLabel(this.taxRateLabel).rate;
		return round(this.currentStorePrice / (1 + vatRate / 100), 2);
	}

	priceWithoutTaxInWarehouse(warehouseId: string) {
		const { taxRates } = stores;
		const vatRate = taxRates.byLabel(this.taxRateLabel).rate;
		return round(
			this.getPriceForWarehouse(warehouseId) / (1 + vatRate / 100),
			2
		);
	}

	priceWithoutTaxInCurrency(currencyId: string) {
		const currentCurrencyId = this.currencyId || 'RSD';
		if (currentCurrencyId === currencyId) {
			return this.priceWithoutTax;
		}

		const { exchangeRates } = stores;
		const lastRates = exchangeRates.lastRates;
		const exchangeRate =
			this.currencyId === 'RSD' ? 1 : lastRates[this.currencyId].rate;
		const priceInRSD = round(this.priceWithoutTax * exchangeRate, 2);

		if (currencyId === 'RSD') {
			return priceInRSD;
		}

		const exchangeRateToCurrency = lastRates[currencyId].rate;
		return round(priceInRSD / exchangeRateToCurrency, 2);
	}
}

export type UpdatedProduct = {
	productId: string;
	variantId?: string;
	sku?: number;
	name: string;
	variantName?: string;
	type: 'new' | 'updated' | 'deleted';
	oldPrice?: number;
	newPrice?: number;
	oldCurrencyId?: string;
	newCurrencyId?: string;
};

class Products extends Store<Product> {
	@observable updatedProducts: UpdatedProduct[] = [];
	@observable currentAvailabilityFilter = [];

	constructor() {
		super(Product);
	}

	@action.bound
	updateSaleChannel(data: any) {
		data.forEach((item) => {
			const product = this.byId[item.productId];
			if (!product) {
				return;
			}

			if (!product.saleChannels) {
				product.saleChannels = [];
			}

			const saleChannel = product.saleChannels.find(
				(sc) => sc.saleChannelId === item.saleChannelId
			);
			if (saleChannel) {
				saleChannel.replace(item);
			} else {
				product.saleChannels.push(new ProductSaleChannel(data, product));
			}
		});
	}

	@computed
	get sorted(): Product[] {
		return sortBy(this.available, 'name');
	}

	@computed get sortedVariants(): Product[] {
		const allVariants = this.available.reduce((prev, curr: Product) => {
			if (curr.hasVariants) {
				prev.push(...curr.variants);
			} else {
				prev.push(curr);
			}
			return prev;
		}, []);

		return sortBy(
			allVariants,
			(item) => item.name || `${item.parent?.name} ${item.variantName}`
		);
	}

	@computed
	get hasVariants() {
		return !!this.available.find((product) => product.variants.length > 0);
	}

	@action.bound
	byCategory(categoryId: string | null) {
		if (!categoryId) {
			return this.sorted.filter(
				(product) => !product.categories || product.categories.length === 0
			);
		}

		return this.sorted.filter((item) =>
			(item.categories || []).find((category) => category.id === categoryId)
		);
	}

	get byId() {
		return this.all.reduce((prev, curr: Product) => {
			if (curr.variants.length > 0) {
				curr.variants.forEach((variant) => {
					if (variant.id) {
						prev[variant.id] = variant;
					}
				});
			}
			prev[curr.id] = curr;
			return prev;
		}, {});
	}

	@action.bound
	setCurrentAvailabilityFilter(saleChannelIds: string[]) {
		this.currentAvailabilityFilter = saleChannelIds;
	}

	@computed
	get variantsWithSaleChannels() {
		const variants = this.available.reduce((prev, curr: Product) => {
			if (curr.hasVariants) {
				prev.push(
					...curr.variants
						.filter(
							(item) =>
								item.saleChannels.filter(
									(sc) => sc.storeId === stores.stores.currentStoreId
								).length > 0 &&
								item.saleChannels.some((sc) =>
									this.currentAvailabilityFilter.includes(sc.saleChannelId)
								)
						)
						.map((variant) => {
							return {
								...variant,
								name: curr.name,
								variantName: variant.variantName,
							};
						})
				);
			} else if (
				curr.saleChannels.filter(
					(sc) => sc.storeId === stores.stores.currentStoreId
				).length > 0 &&
				curr.saleChannels.some((sc) =>
					this.currentAvailabilityFilter.includes(sc.saleChannelId)
				)
			) {
				prev.push(curr);
			}
			return prev;
		}, []);

		return sortBy(variants, 'name');
	}

	@computed
	get bySku() {
		return this.available.reduce((prev, curr: Product) => {
			if (curr.variants.length > 0) {
				curr.variants.forEach((variant) => {
					if (variant.sku) {
						prev[variant.sku] = variant;
					}
				});
			} else if (curr.sku) {
				prev[curr.sku] = curr;
			}
			return prev;
		}, {});
	}

	@computed
	get byEan() {
		return this.available.reduce((prev, curr: Product) => {
			if (curr.variants.length > 0) {
				curr.variants.forEach((variant) => {
					if (variant.ean) {
						prev[variant.ean] = variant;
					}
				});
			} else if (curr.ean) {
				prev[curr.ean] = curr;
			}
			return prev;
		}, {});
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated) {
			await this.fetchAll();
		}
	}

	@action.bound
	addUpdatedProduct(product) {
		this.updatedProducts.push(product);
		StaticComponents.notification.info({
			message: t`Ажурирање артикала`,
			key: 'product-sync',
			style: { width: 800, maxWidth: 'calc(100vw - 48px)' },
			duration: 0,
			description: <UpdatedProductsComponent />,
			onClose: () => {
				this.clearUpdatedProducts();
			},
		});
	}

	@action.bound
	clearUpdatedProducts() {
		this.updatedProducts = [];
	}

	receiveFromSocket(products: any[]): void {
		const { warehouseId } = stores.stores.currentStore;
		products.forEach((product) => {
			const existingProduct = this.byId[product.id];
			if (existingProduct) {
				if (product.deletedAt) {
					this.addUpdatedProduct({
						productId: existingProduct.id,
						variantId: null,
						sku: existingProduct.sku,
						name: existingProduct.name,
						variantName: null,
						type: 'deleted',
						oldPrice: null,
						newPrice: null,
						oldCurrencyId: null,
						newCurrencyId: null,
					});
				} else if (existingProduct.hasVariants && product.hasVariants) {
					// compare variants
					product.variants.forEach((variant) => {
						const existingVariant = existingProduct.variants.find(
							(existingVariant) => existingVariant.id === variant.id
						);
						if (existingVariant) {
							if (
								existingVariant.currentStorePrice !==
								(warehouseId
									? variant.prices[warehouseId] || variant.price
									: variant.price)
							) {
								this.addUpdatedProduct({
									productId: product.id,
									variantId: variant.id,
									sku: variant.sku,
									name: product.name,
									variantName: variant.variantName,
									type: 'updated',
									oldPrice: existingVariant.currentStorePrice,
									newPrice: warehouseId
										? variant.prices?.[warehouseId] || variant.price
										: variant.price,
									oldCurrencyId: existingVariant.currencyId,
									newCurrencyId: variant.currencyId,
								});
							}
						} else {
							this.addUpdatedProduct({
								productId: product.id,
								variantId: variant.id,
								sku: variant.sku,
								name: product.name,
								variantName: variant.variantName,
								type: 'new',
								oldPrice: null,
								newPrice: warehouseId
									? variant?.prices?.[warehouseId] || variant.price
									: variant.price,
								oldCurrencyId: null,
								newCurrencyId: variant.currencyId,
							});
						}
					});
				} else if (existingProduct.hasVariants && !product.hasVariants) {
					// removed variants
					// added product
					existingProduct.variants.forEach((variant) => {
						this.addUpdatedProduct({
							productId: product.id,
							variantId: variant.id,
							sku: variant.sku,
							name: product.name,
							variantName: variant.variantName,
							type: 'deleted',
							oldPrice: null,
							newPrice: null,
							oldCurrencyId: null,
							newCurrencyId: null,
						});
					});
					this.addUpdatedProduct({
						productId: product.id,
						variantId: null,
						sku: product.sku,
						name: product.name,
						variantName: null,
						type: 'new',
						oldPrice: null,
						newPrice: warehouseId
							? product?.prices?.[warehouseId] || product.price
							: product.price,
						oldCurrencyId: null,
						newCurrencyId: product.currencyId,
					});
				} else if (!existingProduct.hasVariants && product.hasVariants) {
					// removed product
					// added variants
					this.addUpdatedProduct({
						productId: existingProduct.id,
						variantId: null,
						sku: existingProduct.sku,
						name: existingProduct.name,
						variantName: null,
						type: 'deleted',
						oldPrice: null,
						newPrice: null,
						oldCurrencyId: null,
						newCurrencyId: null,
					});
					product.variants.forEach((variant) => {
						this.addUpdatedProduct({
							productId: product.id,
							variantId: variant.id,
							sku: variant.sku,
							name: product.name,
							variantName: variant.variantName,
							type: 'new',
							oldPrice: null,
							newPrice: warehouseId
								? variant.prices?.[warehouseId] || variant.price
								: variant.price,
							oldCurrencyId: null,
							newCurrencyId: variant.currencyId,
						});
					});
				} else {
					if (
						existingProduct.currentStorePrice !==
						(warehouseId
							? product.prices?.[warehouseId] || product.price
							: product.price)
					) {
						this.addUpdatedProduct({
							productId: product.id,
							variantId: null,
							sku: product.sku,
							name: product.name,
							variantName: null,
							type: 'updated',
							oldPrice: existingProduct.currentStorePrice,
							newPrice: warehouseId
								? product.prices?.[warehouseId] || product.price
								: product.price,
							oldCurrencyId: existingProduct.currencyId,
							newCurrencyId: product.currencyId,
						});
					}
				}
			} else {
				if (product.hasVariants) {
					product.variants.forEach((variant) => {
						this.addUpdatedProduct({
							productId: product.id,
							variantId: variant.id,
							sku: variant.sku,
							name: product.name,
							variantName: variant.variantName,
							type: 'new',
							oldPrice: null,
							newPrice: warehouseId
								? variant.prices?.[warehouseId] || variant.price
								: variant.price,
							oldCurrencyId: null,
							newCurrencyId: variant.currencyId,
						});
					});
				} else {
					this.addUpdatedProduct({
						productId: product.id,
						variantId: null,
						sku: product.sku,
						name: product.name,
						variantName: null,
						type: 'new',
						oldPrice: null,
						newPrice: warehouseId
							? product.prices?.[warehouseId] || product.price
							: product.price,
						oldCurrencyId: null,
						newCurrencyId: product.currencyId,
					});
				}
			}
		});

		super.receiveFromSocket(products);
	}
}

export { Products, Product };
