import { t, Trans } from '@lingui/macro';
import {
	Alert,
	Badge,
	Checkbox,
	Form,
	Input,
	InputNumber,
	Select,
	Tooltip,
} from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { upperFirst } from 'lodash';
import { Observer, observer } from 'mobx-react-lite';
import { useCallback, useMemo, useRef } from 'react';
import AnanasCategorySelect from './Components/Ananas/CategorySelect';
import GlovoCategorySelect from './Components/Glovo/CategorySelect';
import MisterDCategorySelect from './Components/MisterD/CategorySelect';
import ShoppsterCategorySelect from './Components/Shoppster/CategorySelect';
import WoltCategorySelect from './Components/Wolt/CategorySelect';
import styles from './SaleChannelsInput.module.less';
import Editor from '../../../../../../components/Editor';
import { TableInput } from '../../../../../../components/TableInput';
import numberFormat from '../../../../../../lib/numberFormat';
import stores from '../../../../../../stores/index.mobx';
import globalStyles from '../../../../../../assets/styles/global.module.less';
import { PRODUCT_SALE_CHANNEL_STATUS } from '../../../../../../constants/saleChannel';
import { NoImage } from '../../../../../../components/NoImage';

type Props = {
	id?: string;
	isVariant?: boolean;
	productForm?: FormInstance;
	price?: number;
};

function SaleChannelsInput({
	id,
	isVariant,
	productForm,
	price,
	...props
}: Props) {
	const tableInputRef = useRef(null);
	const parentForm = Form.useFormInstance();

	const currencyIdWatch = Form.useWatch('currencyId', parentForm);
	const weightWatch = Form.useWatch('weight', parentForm);
	const imagesWatch = Form.useWatch('images', parentForm);
	const eanWatch = Form.useWatch('ean', parentForm);
	const priceWatch = Form.useWatch('price', parentForm);
	const dimensionsWatch = Form.useWatch('dimensions', parentForm);
	const [dw, dd, dh] = useMemo(() => dimensionsWatch || [], [dimensionsWatch]);

	const missingDimensions = useMemo(() => {
		return (
			!dw || !dd || !dh || Number(dw) <= 0 || Number(dd) <= 0 || Number(dh) <= 0
		);
	}, [dd, dh, dw]);

	const formFields = useCallback(
		(record, form, setFields) => {
			const packageId =
				stores.marketplaceApplications.installedByApplicationId?.[
					stores.saleChannels.byId[form.getFieldValue('saleChannelId')]
						?.applicationId
				]?.packageId;

			const EditorComponent = <Editor />;

			const descriptionField = 'description';

			let rules = [];

			if (packageId === 'rs.storebuddy.glovo') {
				rules = [
					{
						type: 'string',
						max: 250,
						message: t`Опис не може бити дужи од 250 карактера`,
					},
				];
			}
			if (packageId === 'rs.storebuddy.wolt') {
				rules = [
					{
						type: 'string',
						max: 5000,
						message: t`Опис не може бити дужи од 5000 карактера`,
					},
				];
			}
			if (packageId === 'rs.storebuddy.misterd') {
				rules = [
					{
						type: 'string',
						max: 1000,
						message: t`Опис не може бити дужи од 1000 карактера`,
					},
				];
			}

			return [
				{
					key: 'basicInfo',
					label: t`Основне информације`,
					fields: [
						{
							key: 'saleChannelId',
							label: t`Канал продаје`,
							component: (
								<Select
									options={stores.saleChannels.all.map((channel) => ({
										label: channel.name,
										value: channel.id,
									}))}
									disabled={form.getFieldValue('id')}
								/>
							),
							sm: 12,
							xs: 24,
							rules: [{ required: true, message: t`Изаберите канал продаје` }],
						},
						{
							key: 'storeId',
							label: t`Продајно место`,
							initialValue: stores.stores.currentStoreId,
							component: (
								<Select
									options={stores.stores.all.map((store) => ({
										label: store.name,
										value: store.id,
									}))}
									disabled={form.getFieldValue('id')}
								/>
							),
							sm: 12,
							xs: 24,
							rules: [{ required: true, message: t`Изаберите продајно место` }],
						},
						{
							key: 'name',
							label: t`Назив`,
							component: <Input />,
							span: 24,
							rules: [
								{ required: true, message: t`Унесите назив` },
								{
									type: 'string',
									max: 75,
									message: t`Назив не може бити дужи од 75 карактера`,
								},
							],
						},
						{
							key: descriptionField,
							label: t`Опис`,
							component: EditorComponent,
							extra: t`У зависности од платформе, форматирање ће се можда разликовати`,
							span: 24,
							rules,
						},
						{
							key: 'saleQuantity',
							label: t`Продајна количина`,
							component: <InputNumber style={{ width: '100%' }} />,
							md: 12,
							xs: 24,
							rules: [
								{ required: true, message: t`Унесите продајну количину` },
							],
						},
						{
							key: 'salePrice',
							label: t`Продајна цена`,
							component: <InputNumber style={{ width: '100%' }} />,
							md: 12,
							xs: 24,
							rules: currencyIdWatch &&
								currencyIdWatch !== 'RSD' && [
									{ required: true, message: t`Унесите продајну цену` },
								],
						},
					],
				},
				...(packageId === 'rs.storebuddy.wolt'
					? [
							{
								key: 'woltInfo',
								label: t`Wolt информације`,
								fields: [
									{
										key: ['metadata', 'category'],
										label: t`Категорија`,
										component: <WoltCategorySelect />,
										span: 24,
									},
								],
							},
					  ]
					: []),
				...(packageId === 'rs.storebuddy.glovo'
					? [
							{
								key: 'glovoInfo',
								label: t`Glovo информације`,
								fields: [
									{
										key: ['metadata', 'category'],
										label: t`Категорија`,
										component: <GlovoCategorySelect />,
										span: 24,
									},
								],
							},
					  ]
					: []),
				...(packageId === 'rs.storebuddy.misterd'
					? [
							{
								key: 'misterdInfo',
								label: t`Mister D информације`,
								fields: [
									{
										key: ['metadata', 'category'],
										label: t`Категорија`,
										component: <MisterDCategorySelect />,
										span: 24,
									},
								],
							},
					  ]
					: []),
				...(packageId === 'rs.badi.ananas'
					? [
							{
								key: 'ananasInfo',
								label: t`Ananas информације`,
								fields: [
									...((Number(priceWatch || 0) <= 0 &&
										form.getFieldValue('salePrice') <= 0) ||
									Number(weightWatch || 0) <= 0 ||
									imagesWatch?.length <= 0 ||
									!eanWatch
										? [
												{
													key: ['priceWarning'],
													component: (
														<Alert
															type="warning"
															message={
																<Trans>
																	Артикал нема унете следеће информације:
																	<br />
																	{(() => {
																		const missingFields = [];

																		if (
																			Number(priceWatch || 0) <= 0 &&
																			form.getFieldValue('salePrice') <= 0
																		) {
																			missingFields.push(t`продајна цена`);
																		}

																		if (Number(weightWatch || 0) <= 0) {
																			missingFields.push(t`маса`);
																		}

																		if (imagesWatch?.length <= 0) {
																			missingFields.push(t`фотографије`);
																		}

																		if (!eanWatch) {
																			missingFields.push(t`бар код`);
																		}

																		// replace last , with and
																		return upperFirst(
																			missingFields
																				.join(', ')
																				.replace(/,([^,]*)$/, ' и$1')
																		);
																	})()}
																	.
																	<br />
																	<br />
																	Артикал неће бити постављен на платформу
																	Ananas све док се не унесу све потребне
																	информације.
																</Trans>
															}
														/>
													),
													span: 24,
												},
										  ]
										: []),
									{
										key: ['metadata', 'brand'],
										label: t`Бренд`,
										component: <Input />,
										span: 24,
										rules: [{ required: true, message: t`Унесите бренд` }],
									},
									{
										key: ['metadata', 'category'],
										label: t`Категорија`,
										component: <AnanasCategorySelect />,
										span: 24,
										rules: [
											{ required: true, message: t`Изаберите категорију` },
										],
									},
								],
							},
					  ]
					: []),
				...(packageId === 'rs.badi.shoppster'
					? [
							{
								key: 'shoppsterInfo',
								label: t`Shoppster информације`,
								fields: [
									...((Number(priceWatch || 0) <= 0 &&
										form.getFieldValue('salePrice') <= 0) ||
									Number(weightWatch || 0) <= 0 ||
									imagesWatch?.length <= 0 ||
									!eanWatch ||
									missingDimensions
										? [
												{
													key: ['priceWarning'],
													component: (
														<Alert
															type="warning"
															message={
																<Trans>
																	Артикал нема унете следеће информације:
																	<br />
																	{(() => {
																		const missingFields = [];

																		if (
																			Number(priceWatch || 0) <= 0 &&
																			form.getFieldValue('salePrice') <= 0
																		) {
																			missingFields.push(t`продајна цена`);
																		}

																		if (Number(weightWatch || 0) <= 0) {
																			missingFields.push(t`маса`);
																		}

																		if (missingDimensions) {
																			missingFields.push(t`димензије`);
																		}

																		if (imagesWatch?.length <= 0) {
																			missingFields.push(t`фотографије`);
																		}

																		if (!eanWatch) {
																			missingFields.push(t`бар код`);
																		}

																		// replace last , with and
																		return upperFirst(
																			missingFields
																				.join(', ')
																				.replace(/,([^,]*)$/, ' и$1')
																		);
																	})()}
																	.
																	<br />
																	<br />
																	Артикал неће бити постављен на платформу
																	Shoppster све док се не унесу све потребне
																	информације.
																</Trans>
															}
														/>
													),
													span: 24,
												},
										  ]
										: []),
									{
										key: ['metadata', 'brand'],
										label: t`Бренд`,
										component: <Input />,
										span: 24,
										rules: [{ required: true, message: t`Унесите бренд` }],
									},
									{
										key: ['metadata', 'category'],
										label: t`Категорија`,
										component: <ShoppsterCategorySelect />,
										span: 24,
										rules: [
											{ required: true, message: t`Изаберите категорију` },
										],
									},
									{
										key: ['metadata', 'deliveryTime'],
										label: t`Очекивано време испоруке`,
										component: <Input addonAfter={t`дана`} min={1} />,
										span: 24,
										initialValue: 3,
										rules: [
											{
												required: true,
												message: t`Унесите очекивано време испоруке`,
											},
										],
									},
								],
							},
					  ]
					: []),
				...(stores.stores.byId[form.getFieldValue('storeId')]?.warehouseId
					? [
							{
								label: t`Залихе`,
								fields: [
									{
										key: 'trackStock',
										valuePropName: 'checked',
										className: globalStyles.condensedFormField,
										component: (
											<Checkbox>
												<Trans>Онемогући када артикла нема на залихама</Trans>
											</Checkbox>
										),
										rerenderOnChange: true,
									},
									{
										key: 'minimumStock',
										label: t`Количина за коју ће артикал бити онемогућен`,
										initialValue: 0,
										component: (
											<InputNumber
												disabled={!form.getFieldValue('trackStock')}
												style={{ width: '100%' }}
											/>
										),
									},
								],
							},
					  ]
					: []),
			];
		},
		[
			currencyIdWatch,
			eanWatch,
			imagesWatch?.length,
			missingDimensions,
			priceWatch,
			weightWatch,
		]
	);

	const columns = useMemo(
		() => [
			{
				title: '',
				key: 'logo',
				width: 50,
				render: (_, record) => {
					const saleChannel = stores.saleChannels.byId[record.saleChannelId];
					const sc =
						stores.marketplaceApplications.installedByApplicationId?.[
							saleChannel?.applicationId
						];
					const image = saleChannel?.logoFile || sc?.logoFile;

					if (image) {
						return (
							<img src={image?.urls['64x64']} alt="" className={styles.image} />
						);
					} else {
						return (
							<div className={styles.noImage}>
								<NoImage name={saleChannel?.name || ''} size="small" />
							</div>
						);
					}
				},
			},
			{
				title: t`Канал продаје`,
				key: 'saleChannelId',
				editable: false,
				render: (value, record) => (
					<>
						{stores.saleChannels.byId[record.saleChannelId]?.name ||
							t`Непознат канал продаје`}
						{!record.externalId &&
							stores.marketplaceApplications.installedByApplicationId?.[
								stores.saleChannels.byId[record.saleChannelId]?.applicationId
							]?.packageId === 'rs.storebuddy.misterd' && (
								<Tooltip
									title={
										<Trans>
											Артикал није повезан са платформом Mister D. Преузмите
											фајл са изменама из подешавања апликације и пошаљите га на
											адресу електронске поште{' '}
											<a href="mailto:kontakt@misterd.rs">kontakt@misterd.rs</a>
											. Када добијете потврду да су артикли убачени, покрените
											повезивање артикала из подешавања апликације.
										</Trans>
									}
									className={styles.warning}
								>
									<i className="fi fi-rr-triangle-warning"></i>
								</Tooltip>
							)}
					</>
				),
			},
			{
				title: t`Продајно место`,
				key: 'storeId',
				editable: false,
				render: (value, record) =>
					stores.stores.byId[record.storeId]?.name ||
					t`Непознато продајно место`,
			},
			{
				title: t`Статус`,
				key: 'status',
				dataIndex: 'status',
				editable: false,
				render: (value, record) => {
					return (
						<Observer>
							{() => {
								const item = stores.products.byId[
									record.productId
								]?.saleChannels?.find((sc) => sc.id === record.id);
								if (
									!item ||
									item?.status === PRODUCT_SALE_CHANNEL_STATUS.PENDING
								) {
									return <Badge color="lightgray" text={t`На чекању`} />;
								}
								if (item?.status === PRODUCT_SALE_CHANNEL_STATUS.PROCESSING) {
									return <Badge color="blue" text={t`У обради`} />;
								}
								if (item?.status === PRODUCT_SALE_CHANNEL_STATUS.FAILED) {
									return <Badge color="red" status="error" text={t`Грешка`} />;
								}
								return (
									<Badge color="green" status="success" text={t`Oбрађен`} />
								);
							}}
						</Observer>
					);
				},
			},
			{
				title: t`Цена`,
				key: 'salePrice',
				dataIndex: 'salePrice',
				editable: false,
				render: (value, record) =>
					record.salePrice ? (
						numberFormat(record.salePrice, true, 2, true, 'RSD')
					) : (
						<>
							{numberFormat(
								price,
								true,
								2,
								true,
								parentForm.getFieldValue('currencyId') || 'RSD'
							)}
							&nbsp;&nbsp;
							<Tooltip title={t`Прати цену артикла`}>
								<i className="fi fi-rr-link"></i>
							</Tooltip>
						</>
					),
			},
		],
		[]
	);

	const nameWatch = Form.useWatch('name', isVariant ? productForm : parentForm);
	const variantNameWatch = Form.useWatch('variantName', parentForm);
	const descriptionWatch = Form.useWatch(
		'description',
		isVariant ? productForm : parentForm
	);

	const newRowValue = useMemo(
		() => ({
			name: isVariant ? `${nameWatch} ${variantNameWatch}` : nameWatch,
			description: descriptionWatch,
			salePrice: null,
			saleQuantity: 1,
		}),
		[isVariant, nameWatch, variantNameWatch, descriptionWatch]
	);

	return (
		<TableInput
			ref={tableInputRef}
			isResponsive={false}
			addButtonText={t`Додај канал продаје`}
			emptyText={t`Нема додатних канала продаје`}
			iconPath="/images/icons/new/online-store.svg"
			newRowValue={newRowValue}
			columns={columns}
			editInDrawer
			editDrawerTitle={(item) =>
				item?.id ? t`Измена канала продаје` : t`Додавање канала продаје`
			}
			formFields={formFields}
			id={id}
			editDrawerWidth={500}
			{...props}
		/>
	);
}

export default observer(SaleChannelsInput);
