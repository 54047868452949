import { t, Trans } from '@lingui/macro';
import { Button, Form, Input, InputNumber, Modal } from 'antd';
import { memo, useCallback, useEffect, useRef } from 'react';

interface Props {
	visible: boolean;
	onClose: () => void;
	onSubmit: (item: any, quantity: number) => void;
	item: any;
}

function EnterQuantityModal({ visible, onClose, onSubmit, item }: Props) {
	const [form] = Form.useForm();
	const inputRef = useRef(null);

	const submit = useCallback(() => {
		form.validateFields().then((values) => {
			onSubmit(item, Number(values.quantity));
			onClose();
		});
	}, [form, item, onClose, onSubmit]);

	useEffect(() => {
		if (visible) {
			setTimeout(() => {
				if (inputRef.current) {
					inputRef.current?.select();
				}
			}, 300);
		}
	}, [visible]);

	const onPressEnter = useCallback(
		(event) => {
			submit();
			event.preventDefault();
		},
		[submit]
	);

	return (
		<Modal
			centered
			open={visible}
			title={t`Унесите количину`}
			onCancel={onClose}
			width={250}
			destroyOnClose
			footer={
				<Button type="primary" onClick={submit}>
					<Trans>Потврди</Trans>
				</Button>
			}
		>
			{visible && (
				<Form
					form={form}
					preserve={false}
					validateTrigger="onFinish"
					layout="vertical"
				>
					<Form.Item
						style={{ marginBottom: 0 }}
						name="quantity"
						label={t`Количина`}
						initialValue={1}
						rules={[
							{
								required: true,
								message: t`Унесите количину`,
							},
							{
								type: 'number',
								validator: async (rule, value) => {
									if (value < 0.001 || value > 99999999999999) {
										throw new Error(
											t`Количина мора бити између 0,001 и 99.999.999.999.999,00`
										);
									}
								},
								message: t`Количина мора бити између 0,001 и 99.999.999.999.999,00`,
							},
						]}
					>
						<InputNumber
							style={{ width: '100%' }}
							onPressEnter={onPressEnter}
							ref={inputRef}
						></InputNumber>
					</Form.Item>
				</Form>
			)}
		</Modal>
	);
}

export default memo(EnterQuantityModal);
