import { t } from '@lingui/macro';

export const APPLICATION_VERSION = `3.7.1-${process.env.REACT_APP_GIT_SHA}`;
export const IB_NO = 1289;
export const getApplicationName = () => t`Бади`;
export const POS_NO = `${IB_NO}/${APPLICATION_VERSION}`;
export const MINIMUM_CLIENT_VERSION = '2.0.0';
export const AVAILABLE_FEATURES: Record<string, string[]> = {
	lite: [],
	basic: [],
	pos: [
		'store_management',
		'esdc',
		'variants',
		'users',
		'integrations',
		'documents',
	],
	standard: [
		'store_management',
		'esdc',
		'variants',
		'users',
		'integrations',
		'documents',
	],
	posplus: [
		'store_management',
		'esdc',
		'variants',
		'users',
		'stock',
		'integrations',
		'documents',
	],
	professional: [
		'store_management',
		'esdc',
		'variants',
		'users',
		'stock',
		'integrations',
		'documents',
	],
	erp: [
		'store_management',
		'esdc',
		'variants',
		'users',
		'stock',
		'integrations',
		'documents',
		'reports',
	],
	premium: [
		'store_management',
		'esdc',
		'variants',
		'users',
		'stock',
		'integrations',
		'documents',
		'accounting',
		'reports',
	],
};

export const PACKAGE_NAME = {
	lite: 'Lite',
	pos: 'POS',
	posplus: 'POS Plus',
	erp: 'ERP',
};

export const getPackageName = () => ({
	lite: t`Lite`,
	pos: t`POS`,
	posplus: t`POS Plus`,
	erp: t`ERP`,
	basic: t`Основни`,
	standard: t`Стандард`,
	professional: t`Профи`,
	premium: t`Премијум`,
});

export const PACKAGE_PRICE = {
	basic: 400,
	standard: 1800,
	professional: 2400,
	premium: 3600,
};

export const DOCUMENTATION_BASE_URL = 'https://badi.rs/docs';

export const IS_OLD_APP =
	(window.location.hostname === 'localhost' &&
		window.location.port === '3003') ||
	window.location.hostname === 'sandbox.storebuddy.rs' ||
	window.location.hostname === 'app.storebuddy.rs';
