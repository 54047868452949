import { Button, Input, InputNumber, Space } from 'antd';
import { observer } from 'mobx-react-lite';

import { useDrawer } from '../../../../../../../../components/Page';
import {
	TAXCORE_INVOICE_TAX_CATEGORY_MAPPING,
	TAX_LABEL_INVOICE_MAPPING,
} from '../../../../../../../../constants/documentInvoice';
import stores from '../../../../../../../../stores/index.mobx';
interface Props {
	onChange?: (value: number | null) => void;
	currencyId: string;
	index?: number;
	handleEditObject: (index, value: unknown) => void;
}

function SkuInput({
	onChange,
	currencyId,
	handleEditObject,
	index,
	...props
}: Props) {
	const [idx, openSearchDrawer, , searchVisible] = useDrawer(
		'product-search',
		(event, item) => {
			if (event === 'select') {
				if (item && index === Number(idx)) {
					const product = (item as any.variant) || item.product;
					const newValue = {
						productId: product.id,
						type: product.productType,
						sellerItemIdentification: Number(product.sku),
						name: product.name,
						unitId: product.saleUnitId,
						// TODO: fix this to use warehouses
						unitPrice: product.priceWithoutTaxInCurrency(currencyId || 'RSD'),
						taxCode: TAXCORE_INVOICE_TAX_CATEGORY_MAPPING[product.taxRateLabel],
						taxPercentage: TAX_LABEL_INVOICE_MAPPING[product.taxRateLabel],
						allowances: [],
					};
					handleEditObject(index, newValue);
				}
			}
		}
	);
	return (
		<Space.Compact>
			<InputNumber
				onChange={onChange}
				onKeyDown={(event) => {
					if (
						((event.ctrlKey || event.metaKey) && event.key === 'f') ||
						event.key === 'F3'
					) {
						event.preventDefault();
						openSearchDrawer(`${index}`);
					}
				}}
				style={{ width: 'calc(100% - 32px)' }}
				onBlur={(event) => {
					const editValue = Number(event.target.value);
					const product = stores.products?.bySku[editValue];

					if (!product) {
						return;
					}

					handleEditObject(index, {
						productId: product.id,
						type: product.productType,
						sellerItemIdentification: Number(product.sku),
						name: product.name,
						unitId: product.saleUnitId,
						unitPrice: product.priceWithoutTaxInCurrency(currencyId),
						taxCode: TAXCORE_INVOICE_TAX_CATEGORY_MAPPING[product.taxRateLabel],
						taxPercentage: TAX_LABEL_INVOICE_MAPPING[product.taxRateLabel],
						discountPercentage: 0,
						discountAmount: 0,
					});
				}}
				onFocus={(event) => {
					event.currentTarget.select();
				}}
				{...props}
			/>
			<Button
				tabIndex={-1}
				icon={<i className="fi fi-rr-search"></i>}
				onClick={() => {
					openSearchDrawer(`${index}`);
				}}
			></Button>
		</Space.Compact>
	);
}

export default observer(SkuInput);
