import cyr2lat from 'cyrillic-to-latin';
import { computed, flow, makeObservable, observable } from 'mobx';

import { CreateStore } from './Crud.mobx';
import { BankAccount } from './shared/BankAccount';

const { Store, Entity } = CreateStore({
	name: 'partner',
	paginated: false,
	clientVersion: 'v2',
	persistFields: ['all'],
	searchFields: ['name', 'nameLatin'],
});

export type PartnerPhone = {
	name?: string;
	role?: string;
	phone?: string;
	email?: string;
};

export class PartnerLocation extends Entity {
	@observable name: string;
	@observable address: string;
	@observable city: string;
	@observable zip: string;
	@observable country: string;
	@observable internalCode: string;
	@observable internalRoutingNumber: string;
	@observable gln: string;

	@computed get nameLatin() {
		return cyr2lat(this.name);
	}

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}
}

class Partner extends Entity {
	@observable name?: string;
	@observable website?: string;
	@observable email?: string;
	@observable address?: string;
	@observable city?: string;
	@observable zip?: string;
	@observable companyRegistrationNumber?: string;
	@observable tin?: string;
	@observable vat?: boolean;
	@observable phones?: PartnerPhone[];
	@observable bankAccounts?: BankAccount[];
	@observable sellerIdentifier?: string;
	@observable country?: string;
	@observable currencyId?: string;
	@observable internalCode?: string;
	@observable code?: string;
	@observable internalRoutingNumber?: string;
	@observable gln?: string;
	@observable locations?: PartnerLocation[];
	@observable notes?: string;
	@observable budgetUserType?: number;

	@computed get jbkjs() {
		return (this.sellerIdentifier || '').startsWith('JBKJS')
			? this.sellerIdentifier.substring(6)
			: '';
	}

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	replace(data: any) {
		if (data.bankAccounts) {
			data.bankAccounts = data.bankAccounts.map(
				(item) => new BankAccount(item)
			);
		}

		if (data.locations) {
			data.locations = data.locations.map(
				(item) => new PartnerLocation(item, this)
			);
		}

		super.replace(data);
	}
}

class Partners extends Store<Partner> {
	@observable isCheckingInternalCode = false;

	constructor() {
		super(Partner);
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated) {
			await this.fetchAll();
		}
	}

	@flow.bound
	*checkInternalCode(internalCode: string, locationId?: string) {
		this.isCheckingInternalCode = true;

		try {
			yield this.getClient().post(`/partners/check-internal-code`, {
				internalCode,
				locationId,
			});
		} catch (e) {
			throw e;
		} finally {
			this.isCheckingInternalCode = false;
		}

		return true;
	}
}

export { Partners, Partner };
