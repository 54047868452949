import { t, Trans } from '@lingui/macro';
import {
	Button,
	Checkbox,
	Col,
	Form,
	Input,
	Row,
	Tooltip,
	Upload,
	Progress,
	Spin,
	Typography,
	Radio,
	Grid,
	InputNumber,
	Select,
} from 'antd';
import cyr2lat from 'cyrillic-to-latin';
import dayjs from 'dayjs';
import diacritics from 'diacritics';
import gs1 from 'gs1-key';
import { observer } from 'mobx-react-lite';
import {
	useCallback,
	useEffect,
	useImperativeHandle,
	useMemo,
	useState,
} from 'react';

import styles from './Company.module.less';
import FlatRateTaxChangeModal from './Modals/FlatRateTaxChangeModal';
import VATChangeModal from './Modals/VATChangeModal';
import globalStyles from '../../../../../assets/styles/global.module.less';
import BankAccountsInput from '../../../../../components/BankAccountsInput';
import CurrencySelect from '../../../../../components/CurrencySelect';
import { StaticComponents } from '../../../../../components/StaticComponents';
import TaxRateSelect from '../../../../../components/TaxRateSelect';
import { Title } from '../../../../../components/Title';
import {
	getApplicationName,
	getPackageName,
} from '../../../../../constants/application';
import { usePermissions } from '../../../../../hooks/permissions';
import { defaultLocale } from '../../../../../lib/i18n';
import { v2Client } from '../../../../../store/client';
import store from '../../../../../stores/index.mobx';
import { TaxExemptionInput } from '../../../Documents/OutgoingInvoices/Components/TaxExemptionInput';

function Company(undefined, ref) {
	const APPLICATION_NAME = useMemo(() => getApplicationName(), []);
	const PACKAGE_NAME = useMemo(() => getPackageName(), []);
	const hasSettingsCompanyEditPermission = usePermissions(
		'settings',
		'company',
		'edit'
	);

	const [form] = Form.useForm();

	const screens = Grid.useBreakpoint();

	const [isFormTouched, setIsFormTouched] = useState(false);
	const {
		company,
		kpos,
		application: { classifications, isFetchingClassifications },
		users: { authenticatedUser },
	} = store;
	const { isFetching, update, isUpdating } = company;

	const [logoUploadState, setLogoUploadState] = useState({
		status: 'empty',
		percentage: 0,
		url: '',
	});

	const [vatChangeModal, setVatChangeModal] = useState({
		visible: false,
		newStatus: false,
		previousDefaultTaxLabel: '',
	});

	const [isFlatRateTaxModalVisible, setIsFlatRateTaxModalVisible] =
		useState(false);

	useImperativeHandle(ref, () => ({
		getDocsLink: () => 'koriscenje/moduli-aplikacije/podesavanja/preduzece/',
	}));

	useEffect(() => {
		if (company) {
			// form.setFieldsValue(company);
			// setIsFormTouched(false);
			if (company.logoFile) {
				setLogoUploadState({
					status: 'done',
					url: company.logoFile.urls['256x256'],
					percentage: 100,
				});
			}
		}
	}, [company, company?.name, isFetching]);
	async function onSubmit() {
		try {
			const values = await form.validateFields();

			values.logoFileId =
				values.logo?.file?.response?.id || form.getFieldValue('logoFileId');

			values.taxPeriod ? Number(values.taxPeriod) : undefined;

			if (values.vat) {
				values.flatRateTax = false;
			}

			delete values.logo;
			delete values.tin;
			delete values.companyRegistrationNumber;
			values.bankAccounts?.forEach((account: any) => {
				delete account.part1;
				delete account.part2;
				delete account.part3;
				delete account.valid;
			});

			// if (!values.vat) {
			// 	values.defaultTaxRateLabel = null;
			// }

			const oldFlatRateTax = company.flatRateTax;

			await update(values);
			StaticComponents.notification.success({
				message: t`Подешавања су успешно сачувана`,
			});

			if (oldFlatRateTax !== values.flatRateTax && values.flatRateTax) {
				setIsFlatRateTaxModalVisible(true);
			}
			setIsFormTouched(false);
		} catch (error) {
			console.error(error);
			StaticComponents.notification.error({
				message: t`Дошло је до грешке приликом чувања подешавања`,
			});
		}
	}

	const onValuesChange = useCallback((changedValues, allValues) => {
		setIsFormTouched(true);
		// setCurrentValues(allValues);
		if (typeof changedValues.vat === 'boolean') {
			setVatChangeModal({
				visible: true,
				newStatus: changedValues.vat,
				previousDefaultTaxLabel: allValues.defaultTaxRateLabel,
			});
		}
	}, []);
	return (
		<Spin spinning={isFetching || isUpdating}>
			<div className={styles.wrapper}>
				<Form
					layout="vertical"
					className={styles.form}
					initialValues={company}
					onFinish={onSubmit}
					form={form}
					onValuesChange={onValuesChange}
					disabled={!hasSettingsCompanyEditPermission}
				>
					<Title>
						<Trans>Основне информације</Trans>
					</Title>
					<Row gutter={8}>
						<Col flex="auto">
							<Form.Item label={t`Назив`} name="name">
								<Input type="text" />
							</Form.Item>
							<Row gutter={8}>
								<Col span={12}>
									<Form.Item
										label={
											!screens.xxl ? t`ПИБ` : t`Порески идентификациони број`
										}
										name="tin"
									>
										<Input type="text" disabled />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										label={t`Матични број`}
										name="companyRegistrationNumber"
									>
										<Input type="text" disabled />
									</Form.Item>
								</Col>
							</Row>
						</Col>
						<Col flex="none">
							<Form.Item name="logoFileId" hidden>
								<Input />
							</Form.Item>
							<Form.Item name="logo" label={t`Лого`} valuePropName="file">
								<Upload.Dragger
									showUploadList={false}
									headers={{
										'x-access-token': String(
											v2Client.defaults.headers.common['x-access-token']
										),
									}}
									action={v2Client.defaults.baseURL + '/files/companyLogo'}
									className={styles.upload}
									onChange={(info) => {
										// TODO: use deferred to wait for upload
										const { status } = info.file;
										if (status === 'uploading') {
											setLogoUploadState({
												status,
												percentage: info.file.percent,
												url: '',
											});
										}
										if (status === 'done') {
											setIsFormTouched(true);
											setLogoUploadState({
												status,
												percentage: 100,
												url: info.file.response.urls['256x256'],
											});
											form.setFieldValue('logo', info);
										} else if (status === 'error') {
											StaticComponents.notification.error({
												message: t`Грешка`,
												description: t`Грешка приликом отпремања фајла ${info.file.name}`,
											});
										}
									}}
									onDrop={(e) => {
										// console.log('Dropped files', e.dataTransfer.files);
									}}
								>
									{logoUploadState.status === 'empty' && (
										<>
											<p
												className={`ant-upload-drag-icon ${styles.uploadIcon}`}
											>
												<img
													src="/images/icons/new/cloud-computing.svg"
													alt=""
												/>
											</p>
											<p className="ant-upload-hint">{t`Отпреми лого`}</p>
										</>
									)}
									{logoUploadState.status === 'uploading' && (
										<Progress
											percent={logoUploadState.percentage}
											size="small"
											status="active"
											strokeColor="#7E89C8"
										/>
									)}
									{logoUploadState.status === 'done' && (
										<img
											src={logoUploadState.url}
											alt="Logo"
											className={styles.logoPreview}
										/>
									)}
								</Upload.Dragger>
								{logoUploadState.status === 'done' && (
									<Button
										className={styles.removeLogo}
										onClick={() => {
											form.setFieldValue('logo', null);
											form.setFieldValue('logoFileId', null);
											setIsFormTouched(true);
											setLogoUploadState({
												status: 'empty',
												percentage: 0,
												url: '',
											});
										}}
										icon={<i className="fi fi-rr-trash"></i>}
									/>
								)}
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={8}>
						<Col span={24}>
							<Form.Item label={t`Адреса`} name="address">
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={8}>
						<Col sm={8} xs={24}>
							<Form.Item label={t`Поштански број`} name="zip">
								<Input />
							</Form.Item>
						</Col>
						<Col sm={8} xs={24}>
							<Form.Item label={t`Град`} name="city">
								<Input />
							</Form.Item>
						</Col>
						<Col sm={8} xs={24}>
							<Form.Item
								label={t`ГЛН седишта`}
								name="gln"
								rules={[
									{
										validator: (_, value) => {
											if (!value || value.length === 0) {
												return Promise.resolve();
											}

											if (!gs1.validate(value)) {
												return Promise.reject();
											}
											return Promise.resolve();
										},
										message: t`ГЛН није исправан`,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={8}>
						<Col span={8}>
							<Form.Item label={t`Број телефона`} name="phone">
								<Input type="tel" />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								label={
									!screens.xl
										? t`Адреса ел. поште`
										: t`Адреса електронске поште`
								}
								name="email"
							>
								<Input type="email" />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label={t`Веб сајт`} name="website">
								<Input type="url" />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={8}>
						<Col span={24}>
							<Form.Item
								label={t`Шифра делатности`}
								name="classification"
								extra={
									!['sr-latn', 'sr-cyrl'].includes(
										authenticatedUser?.locale || defaultLocale
									)
										? t`Шифре делатности су приказане на српском језику`
										: ''
								}
							>
								<Select
									loading={isFetchingClassifications}
									showSearch
									filterOption={(input, option) =>
										option.props.searchValue.indexOf(input.toLowerCase()) !==
											-1 ||
										diacritics
											.remove(option.props.searchValue.toLowerCase())
											.indexOf(input.toLowerCase()) !== -1
									}
								>
									{classifications.map((classification) => (
										<Select.Option
											key={classification.key}
											value={classification.key}
											searchValue={`${classification.key} -${cyr2lat(
												classification.value
											)}`}
										>
											<strong>{classification.key}</strong> -{' '}
											{['sr-latn', 'en'].includes(
												authenticatedUser?.locale || defaultLocale
											)
												? cyr2lat(classification.value)
												: classification.value}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					</Row>

					<Title>
						<Trans>Порез</Trans>
					</Title>

					<Row gutter={8}>
						<Col xs={24}>
							<Form.Item
								name="vat"
								valuePropName="checked"
								className={
									!form.getFieldValue('vat')
										? globalStyles.condensedFormField
										: ''
								}
							>
								<Checkbox>
									<Trans>У систему ПДВ-а</Trans>
								</Checkbox>
							</Form.Item>
						</Col>
					</Row>
					{!form.getFieldValue('vat') && (
						<Row gutter={8}>
							<Col xs={12}>
								<Form.Item
									name="flatRateTax"
									valuePropName="checked"
									initialValue={false}
								>
									<Checkbox>
										<Trans>Паушално опорезиван обвезник</Trans>
									</Checkbox>
								</Form.Item>
							</Col>
						</Row>
					)}
					{form.getFieldValue('vat') && (
						<Row gutter={8}>
							<Col xs={12}>
								<Form.Item name="taxPeriod" label={t`Период подношења ПДВ-а`}>
									<Select>
										<Select.Option value={1}>
											<Trans>Месечно</Trans>
										</Select.Option>
										<Select.Option value={3}>
											<Trans>Квартално</Trans>
										</Select.Option>
									</Select>
								</Form.Item>
							</Col>
						</Row>
					)}

					<Row gutter={8}>
						<Col span={12}>
							<Form.Item
								name="defaultTaxRateLabel"
								label={
									<>
										<Trans>Подразумевана пореска ознака</Trans>&nbsp;
										<Tooltip
											title={
												<span>
													<Trans>
														Ова пореска ознака ће подразумевано бити коришћена
														приликом додавања нових артикала. И даље ћете бити у
														могућности да промените пореску ознаку. Водите
														рачуна да ли имате права да користите одабрану
														пореску ознаку.
													</Trans>
												</span>
											}
										>
											<i className="fi fi-rr-info"></i>
										</Tooltip>
									</>
								}
							>
								<TaxRateSelect vat={form.getFieldValue('vat')} />
							</Form.Item>
						</Col>
						{form.getFieldValue('vat') && (
							<Col span={24}>
								<Form.Item
									name="taxFreeMode"
									label={<Trans>ПДВ третман за ЛНПДВ и СНПДВ обрасце</Trans>}
									extra={
										<Trans>
											Начин на који ће се вршити продаја без ПДВ-а уз обрасце
											ЛНПДВ и СНПДВ. Уколико је одабрана опција{' '}
											<em>Накнадна рефундација ПДВ-а</em>, ПДВ ће бити исказан
											на фискалном рачуну, а уколико је одабрана опција{' '}
											<em>Ослобађање од ПДВ-а</em>, купац ће бити ослобођен
											ПДВ-а
										</Trans>
									}
								>
									<Radio.Group>
										<Radio value={'refund'}>
											<Trans>Накнадна рефундација ПДВ-а</Trans>
										</Radio>
										<Radio value={'exempt'}>
											<Trans>Ослобађање од ПДВ-а</Trans>
										</Radio>
									</Radio.Group>
								</Form.Item>
							</Col>
						)}
						<Col span={24}>
							<Form.Item
								name="defaultTaxExemption"
								label={t`Подразумевани основ за ослобађање/изузеће од ПДВ-а`}
								extra={
									!['sr-latn', 'sr-cyrl'].includes(
										authenticatedUser?.locale || defaultLocale
									)
										? t`ПДВ категорије су приказане на српском језику`
										: ''
								}
							>
								<TaxExemptionInput defaultPicker />
							</Form.Item>
						</Col>
					</Row>
					<Title>
						<Trans>Документи</Trans>
					</Title>
					<Row gutter={8}>
						<Col xl={12} xs={24}>
							<Form.Item
								name="invoiceNumberingPrefix"
								label={t`Префикс фактура`}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col xl={12} xs={24}>
							<Form.Item
								name="advanceInvoiceNumberingPrefix"
								label={t`Префикс авансних рачуна`}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col xl={12} xs={24}>
							<Form.Item
								name="creditNoteNumberingPrefix"
								label={t`Префикс књижних одобрења`}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col xl={12} xs={24}>
							<Form.Item
								name="debitNoteNumberingPrefix"
								label={t`Префикс књижних задужења`}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col md={12} xs={24}>
							<Form.Item
								name="invoiceNumbering"
								label={t`Нумерација`}
								initialValue="#/YYYY"
							>
								<Select>
									<Select.Option value="#/YYYY">
										<Trans>Редни број/Година</Trans>{' '}
										<Typography.Text type="secondary">
											(1/{dayjs().format('YYYY')})
										</Typography.Text>
									</Select.Option>
									<Select.Option value="#-YYYY">
										<Trans>Редни број-Година</Trans>{' '}
										<Typography.Text type="secondary">
											(1-{dayjs().format('YYYY')})
										</Typography.Text>
									</Select.Option>
									<Select.Option value="YYYY/#">
										<Trans>Година/Редни број</Trans>{' '}
										<Typography.Text type="secondary">
											({dayjs().format('YYYY')}/1)
										</Typography.Text>
									</Select.Option>
									<Select.Option value="YYYY-#">
										<Trans>Година-Редни број</Trans>{' '}
										<Typography.Text type="secondary">
											({dayjs().format('YYYY')}-1)
										</Typography.Text>
									</Select.Option>
									<Select.Option value="#/PID/YYYY">
										<Trans>Редни број/Шифра партнера/Година</Trans>{' '}
										<Typography.Text type="secondary">
											(1/00001/{dayjs().format('YYYY')})
										</Typography.Text>
									</Select.Option>
									<Select.Option value="#-PID-YYYY">
										<Trans>Редни број-Шифра партнера-Година</Trans>{' '}
										<Typography.Text type="secondary">
											(1-00001-{dayjs().format('YYYY')})
										</Typography.Text>
									</Select.Option>
									<Select.Option value="YYYY/PID/#">
										<Trans>Година/Шифра партнера/Редни број</Trans>{' '}
										<Typography.Text type="secondary">
											({dayjs().format('YYYY')}/00001/1)
										</Typography.Text>
									</Select.Option>
									<Select.Option value="YYYY-#-PID">
										<Trans>Година/Шифра партнера/Редни број</Trans>{' '}
										<Typography.Text type="secondary">
											({dayjs().format('YYYY')}-00001-1)
										</Typography.Text>
									</Select.Option>
									<Select.Option value="manual">
										<Trans>Ручно</Trans>
									</Select.Option>
								</Select>
							</Form.Item>
						</Col>
						<Col md={12} xs={24}>
							<Form.Item
								name="invoiceNumberingStart"
								label={t`Почетни број документа`}
								initialValue={1}
							>
								<InputNumber min={1} style={{ width: '100%' }} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="defaultDueDate"
								label={t`Подразумевана валута плаћања`}
							>
								<InputNumber
									min={0}
									style={{ width: '100%' }}
									addonAfter={t`дана`}
								/>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								name="invoicePrintSignature"
								initialValue={false}
								valuePropName="checked"
							>
								<Checkbox>
									<Trans>Штампај поља за потпис на документу</Trans>
								</Checkbox>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								name="identityId"
								label={t`Идентификациона ознака`}
								extra={t`Према члану 9 Закона о рачуноводству, рачуноводственом исправом сматра се рачуноводствена исправа која је потпуна, истинита, рачунски тачна и приказује пословну промену, и чију веродостојност одговорно лице потврђује потписом, односно другом идентификационом ознаком. Појам "идентификациона ознака" представља сваку ознаку која једнозначно одређује, односно упућује на одговорно лице, односно лице које је овлашћено за издавање рачуноводствене исправе. `}
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Title>
						<Trans>Валуте</Trans>
					</Title>
					<Row gutter={8}>
						<Col span={24}>
							<Form.Item
								initialValue={false}
								name="allowCurrencyConversion"
								valuePropName="checked"
								extra={t`Уколико послујете са иностраним клијентима, можете унети цене артикала у страној валути, а оне ће приликом продаје бити конвертоване по средњем курсу Народне банке Србије`}
							>
								<Checkbox>
									<Trans>Дозволи унос цене артикала у страној валути</Trans>
								</Checkbox>
							</Form.Item>
						</Col>
						{form.getFieldValue('allowCurrencyConversion') && (
							<Col span={24}>
								<Form.Item
									name="preferredCurrencies"
									label={t`Доступне валуте приликом креирања артикала`}
								>
									<CurrencySelect multiple placeholder="Odaberite valute" />
								</Form.Item>
							</Col>
						)}
					</Row>
					<Title>
						<Trans>Текући рачуни</Trans>
					</Title>
					<Form.Item name="bankAccounts">
						<BankAccountsInput showInvoiceSwitch />
					</Form.Item>

					<Title>
						<Trans>Подешавања</Trans>
					</Title>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item
								name="searchProductsByDescription"
								valuePropName="checked"
								className={globalStyles.condensedFormField}
							>
								<Checkbox>
									<Trans>
										Претрага по опису артикала (може утицати на перформансе)
									</Trans>
								</Checkbox>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item name="printAd" valuePropName="checked">
								<Checkbox>
									<Trans>
										Штампај рекламну поруку на фискалним рачунима и документима
									</Trans>
								</Checkbox>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								name="productListType"
								label={t`Начин приказа артикала на листи артикала`}
							>
								<Select>
									<Select.Option value={'parents'}>
										<Trans>Само главни артикли</Trans>
									</Select.Option>
									<Select.Option value={'variants'}>
										<Trans>Све варијанте као посебни артикли</Trans>
									</Select.Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<>
						<Title>
							<Trans>{APPLICATION_NAME} налог</Trans>
						</Title>
						<Form.Item label={t`Пакет`}>
							{PACKAGE_NAME[company?.package]}{' '}
							<Typography.Text type="secondary">
								<Trans>(Контактирајте нас за промену пакета)</Trans>
							</Typography.Text>
							{/* <Button type="link">Promenite paket</Button> */}
						</Form.Item>
					</>
					<Form.Item>
						<Button
							size="large"
							type="primary"
							htmlType="submit"
							disabled={isFetching || isUpdating || !isFormTouched}
							loading={isFetching || isUpdating}
						>
							<Trans>Сачувај</Trans>
						</Button>
					</Form.Item>
					<Form.Item name="changeTaxRateLabel" style={{ display: 'none' }}>
						<Input type="text" />
					</Form.Item>
				</Form>
				<VATChangeModal
					visible={vatChangeModal.visible}
					newStatus={vatChangeModal.newStatus}
					onCancel={() => {
						setVatChangeModal({ ...vatChangeModal, visible: false });
						const newValues = {
							vat: !vatChangeModal.newStatus,
							defaultTaxRateLabel: vatChangeModal.previousDefaultTaxLabel,
						};
						form.setFieldsValue(newValues);
					}}
					onOk={(newLabel) => {
						setVatChangeModal({ ...vatChangeModal, visible: false });
						const newValues = {
							vat: vatChangeModal.newStatus,
							defaultTaxRateLabel: newLabel,
							changeTaxRateLabel: newLabel,
						};
						form.setFieldsValue(newValues);
					}}
				/>
				<FlatRateTaxChangeModal
					visible={isFlatRateTaxModalVisible}
					onCancel={() => {
						setIsFlatRateTaxModalVisible(false);
					}}
					onOk={async (startDate) => {
						try {
							await kpos.backfill(startDate);
							setIsFlatRateTaxModalVisible(false);
						} catch (err) {
							StaticComponents.notification.error({
								message: t`Грешка`,
								description: t`Дошло је до грешке приликом израде КПО књиге`,
							});
						}
					}}
				/>
			</div>
		</Spin>
	);
}

export default observer(Company, { forwardRef: true });
