import { Table, Input } from 'antd';

export default function QuantitiesEdit() {
	return (
		<Table
			pagination={false}
			bordered
			size="small"
			dataSource={[{ warehouse: 'Prodavnica Lion', quantity: 5 }]}
		>
			<Table.Column title="Magacin" dataIndex="warehouse" key="warehouse" />
			<Table.Column
				title="Količina"
				dataIndex="quantity"
				key="quantity"
				width={150}
				render={() => <Input type="number" />}
			/>
		</Table>
	);
}
