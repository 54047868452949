import { t } from '@lingui/macro';
import { InputNumber, DatePicker } from 'antd';

import { TableInput } from '../../../../../../components/TableInput';

type CassaSconto = {
	dueDate?: string;
	amount?: string;
	id?: string;
};

type Props = {
	value?: Array<CassaSconto>;
	onChange?: (any) => void;
};

function CassaScontoInput(props: Props) {
	return (
		<TableInput
			addButtonText={t`Додај износ за плаћање у року`}
			emptyText={t`Нема износа за плаћање у року`}
			iconPath="/images/icons/new/flash-sale.svg"
			columns={[
				{
					title: t`Валута плаћања`,
					key: 'dueDate',
					dataIndex: 'dueDate',
					width: 200,
					editable: true,
					component: <DatePicker format="L" style={{ width: '100%' }} />,
					rules: [
						{
							required: true,
							message: t`Валута плаћања је обавезна`,
						},
					],
				},
				{
					title: t`Износ`,
					key: 'amount',
					dataIndex: 'amount',
					editable: true,
					component: <InputNumber style={{ width: '100%' }} />,
					rules: [
						{
							required: true,
							message: t`Износ је обавезан`,
						},
					],
				},
			]}
			{...props}
		/>
	);
}

export default CassaScontoInput;
